import React, { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { throttled } from "../../util/functions"
import { graphql } from "gatsby"
import color from "../../style/color"
import { headingFontLight } from "../../style/typography"
import Cta from "../../shared/Button/Button"
import { isInViewport } from "../../util/functions"

const smile = keyframes`
from { opacity: 1; stroke-dashoffset: 778; } to { opacity: 1; stroke-dashoffset: 0; }
`

const StyledLogo = styled.svg`
  width: 100%;
  max-width: 441px;
  margin: 0 auto 40px;
  backface-visibility: hidden;

  @media (max-width: 1023px) {
    margin-bottom: 35px;
  }
    & path {
      opacity: 0;
    }
   .line {
    opacity: 1;
    transform-origin:	50% 0;
    transform:scale(-1, 1);
    stroke-dasharray: 778;
    stroke-dashoffset: 778;
    animation: ${smile} 1.6s ease-out forwards;
    }
    @media (max-width: 1023px) {
      margin-bottom: 35px;
    }
  }
`
const line = (
  <StyledLogo viewBox="0 0 495 249" preserveAspectRatio="xMinYMin meet">
    <path
      id="line"
      fill="none"
      stroke="#FFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M493.4 0 L493.4 1.5 Q493.4 103.45 421.3 175.4 349.35 247.45 247.4 247.45 145.45 247.45 73.4 175.4 1.4 103.45 1.4 1.5 L1.4 0"
    />
  </StyledLogo>
)

interface IFinanceBanner {
  sectionHeading?: string
  sectionBody?: string
  smallText?: string
  ctaText?: string
  ctaUrl: string
}

const FinanceBanner = ({
  sectionHeading,
  sectionBody,
  smallText,
  ctaText,
  ctaUrl,
}: IFinanceBanner) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const line: HTMLElement | null = document.getElementById("line")

      const animateSmile = (elem: HTMLElement | null) => {
        if (elem) {
          if (isInViewport(elem)) {
            elem.classList.add("line")
          } else {
            elem.classList.remove("line")
          }
        }
      }

      const eventListener = throttled(100, () => animateSmile(line))

      window.addEventListener("scroll", eventListener)

      return () => {
        window.removeEventListener("scroll", eventListener)
      }
    }
  }, [])
  return (
    <MainContainer data-testid="finance-banner">
      <TextContainer data-testid="text-container">
        {sectionHeading && (
          <SectionHeading data-testid="finance-heading">
            {sectionHeading}
          </SectionHeading>
        )}
        {line}
        {sectionBody && (
          <Body
            marginBottom={smallText ? false : true}
            data-testid="finance-body"
          >
            {sectionBody}
          </Body>
        )}
        {smallText && <SmallText>{smallText}</SmallText>}
      </TextContainer>
      {ctaText && ctaUrl && (
        <BtnContainer>
          <Cta href={ctaUrl} text={ctaText} quaternary />
        </BtnContainer>
      )}
    </MainContainer>
  )
}

export default FinanceBanner

const BtnContainer = styled.div`
  width: 100%;
  max-width: 210px;
`

const MainContainer = styled.div<IFinanceBanner>`
  margin: 0 auto;
  width: 100%;
  padding: 9rem 0 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${color.brand.blue};
  @media (max-width: 1200px) {
    padding: 50px 25px 90px;
  }
`
const TextContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  text-align: center;
  color: white;

  @media (max-width: 1023px) {
    text-align: center;
    width: 100%;
  }
`

const SectionHeading = styled.h2`
  font-family: headingFontLight;
  font-weight: 300;
  position: relative;
  bottom: -9rem;
`
interface IBody {
  marginBottom?: boolean
}

const Body = styled.p<IBody>`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  font-weight: 300;
  margin-bottom: 3rem;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: ${props => (props.marginBottom ? "5rem" : "0")};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: ${props => (props.marginBottom ? "15px" : "0")};
  }
`
const SmallText = styled.p`
  margin-bottom: 5rem;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 22px;
  }
`

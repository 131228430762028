import React from "react"
import SmileHeader from "./SmileHeader"
import { graphql } from "gatsby"

const TreatmentHeaderDrupal = ({ node }: any) => {
  const {
    sectionBody,
    sectionHeading,
    breadcrumb,
    relationships,
    heading,
    field_grey_text,
    cta,
    introCta,
  } = node || {}
  const { dImg, mImg } = relationships
  return (
    <SmileHeader
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      imageMobile={mImg.localFile.publicURL}
      imageDesktop={dImg.localFile.publicURL}
      breadcrumb={breadcrumb}
      heading={heading}
      text={field_grey_text.value}
      cta={cta}
      introCta={introCta}
    />
  )
}

export default TreatmentHeaderDrupal

export const fragment = graphql`
  fragment SmileHeader on paragraph__smile_header {
    id
    sectionHeading: field_section_heading
    sectionBody: field_section_body
    heading: field_grey_heading
    field_grey_text {
      value
    }
    breadcrumb: field_breadcrumb {
      title
      uri
    }
    introCta: field_intro_cta {
      title
      uri
    }
    cta: field_cta {
      title
      uri
    }
    relationships {
      dImg: field_image_desktop {
        localFile {
          publicURL
        }
      }
      mImg: field_image_mobile {
        localFile {
          publicURL
        }
      }
    }
  }
`

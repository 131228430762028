import React, { useState } from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import { headingFontLight } from "../../style/typography"
import arrowGrey from "../../white-co-images/largeArrowGrey.svg"
import arrowGreen from "../../white-co-images/largeArrowGreen.svg"
import VerticalPadding from "../../helper/VerticalPadding"
import Img from "gatsby-image"

interface Slide {
  type: string
  subTitle: string
  body: string
  author: string
  imageDesktop: string
  imageMobile: string
  altDesktop: string
  altMobile: string
  ctaText: string
  ctaUrl: string
  slideDirection: number
}
interface CarouselProps {
  slides: Array<Slide>
  id: string
  heading: string
}
const Carousel = ({ slides, heading, id }: CarouselProps) => {
  const [clientScroll, setClientScroll] = useState(0)

  const [activeSlide, changeSlide] = useState(0)

  const nextSlide = () => {
    if (activeSlide < slides.length - 1) {
      changeSlide(activeSlide + 1)
    }
  }

  const prevSlide = () => {
    if (activeSlide > 0) {
      changeSlide(activeSlide - 1)
    }
  }

  const setSlide = i => {
    changeSlide(i)
  }

  const setClientX = e => {
    const clientX = e.touches[0].clientX
    setClientScroll(clientX)
  }

  const handleTouch = e => {
    if (e) {
      const touchStart = e.changedTouches[0].clientX
      const touchEnd = clientScroll

      //get end touch coordinates
      if (touchStart > touchEnd) {
        prevSlide(e)
      } else {
        nextSlide(e)
      }
    }
  }
  return (
    <StandardSection noPaddingTablet>
      <CarouselContainer data-testid="carousel-hp">
        <VerticalPadding>
          <SlideContainer
            id={id}
            onTouchStart={setClientX}
            onTouchEnd={handleTouch}
          >
            <SlideAnimator
              activeSlide={activeSlide}
              data-testid="carousel-hp-slide"
            >
              {slides.map((slide, i) => {
                return (
                  <SlideMask key={i}>
                    <Slide
                      activeSlide={activeSlide === i}
                      data-testid="hp-slides"
                      slideDirection={i > activeSlide ? -50 : -50}
                    >
                      <Text>
                        {heading && (
                          <div data-testid="carousel-hp-heading">
                            <Heading>{heading}</Heading>
                          </div>
                        )}
                        {slide.type && <Type>{slide.type}</Type>}
                        <Indent>
                          <SlideTitle data-testid="carousel-hp-subheading">
                            {slide.subTitle}
                          </SlideTitle>
                          <BodyText>{slide.body}</BodyText>
                          {slide.author && <Author>{slide.author}</Author>}
                          {slide.ctaText && (
                            <LinkWrapper>
                              <a
                                href={slide.ctaUrl}
                                data-testid="carousel-hp-link"
                              >
                                {slide.ctaText}
                              </a>
                            </LinkWrapper>
                          )}
                        </Indent>
                      </Text>
                      <Mask>
                        {slide.sources && (
                          <Image
                            fluid={slide.sources}
                            alt={slide.altDesktop}
                            data-testid="carousel-hp-image"
                            activeSlide={i === activeSlide}
                            placeholderStyle={{ opacity: 0 }}
                          />
                        )}
                      </Mask>
                    </Slide>
                  </SlideMask>
                )
              })}
            </SlideAnimator>
          </SlideContainer>
          <ProgressContainer>
            <DotContainer>
              {slides.map((dot, i) => {
                return (
                  <Dot
                    key={i}
                    isActive={i === activeSlide}
                    onClick={() => setSlide(i)}
                  />
                )
              })}
            </DotContainer>

            <ArrowsContainer>
              <ChevronLeft
                src={activeSlide !== 0 ? arrowGreen : arrowGrey}
                first={activeSlide === 0}
                onClick={prevSlide}
                alt="chevron"
              />
              <ChevronRight
                src={activeSlide !== slides.length - 1 ? arrowGreen : arrowGrey}
                last={activeSlide === slides.length - 1}
                onClick={nextSlide}
                alt="chevron"
              />
            </ArrowsContainer>
          </ProgressContainer>
          <MobileArrows>
            <ChevronLeft
              src={activeSlide !== 0 ? arrowGreen : arrowGrey}
              first={activeSlide === 0}
              onClick={prevSlide}
              alt="chevron"
            />
            <ChevronRight
              src={activeSlide !== slides.length - 1 ? arrowGreen : arrowGrey}
              last={activeSlide === slides.length - 1}
              onClick={nextSlide}
              alt="chevron"
            />
          </MobileArrows>
        </VerticalPadding>
      </CarouselContainer>
    </StandardSection>
  )
}

interface ISlideAnimator {
  carouselWidth: number
  activeSlide: number
  tabletCarouselWidth: number
}

const SlideAnimator = styled.div<ISlideAnimator>`
  display: flex;
  transition: all 0.5s ease;
  width: 100%;
  backface-visibility: hidden;
  transform: translateX(-${props => 100 * props.activeSlide}%)
    matrix(1, 0, 0, 1, 0, 0);
  transition: transform 2.3s cubic-bezier(0.25, 1, 0.5, 1);
  @media (max-width: 767px) {
    transition: all 1.5s cubic-bezier(0.25, 1, 0.5, 1);
  }
`
interface ISlideContainer {
  activeSlide: number
}
const SlideContainer = styled.div<ISlideContainer>`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  backface-visibility: hidden;
  scroll-snap-type: x mandatory;
  transform-style: preserve-3d;
  perspective: 1000px;
  will-change: transform;
  @media (max-width: 1023px) {
    overflow-x: none;
  }
`
interface SlideProps {
  activeSlide?: boolean
  slideDirection: number
}
const Slide = styled.div<SlideProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  transform: perspective(1000px);
  background: white;
  position: relative;
  perspective: 1000px;
  backface-visibility: hidden;
  will-change: transform;
  transition: transform 2.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s;
  ${props =>
    props.activeSlide
      ? "transform: matrix(1, 0, 0, 1, 0, 0);"
      : "transform: matrix(1.1, 0, 0, 1.1, 0, 0)"};
  z-index: ${props => (props.activeSlide ? "2" : "1")};
  @media (max-width: 1199px) {
    ${props =>
      props.activeSlide
        ? "transform: matrix(1, 0, 0, 1, 0, 0);"
        : "transform: matrix(1.05, 0, 0, 1.05, 0, 0)"};
  }
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: flex-end;
    transition: transform 2.5s cubic-bezier(0.25, 1, 0.5, 1) 0.5s;
    ${props =>
      props.activeSlide
        ? "transform: matrix(1, 0, 0, 1, 0, 0);"
        : "transform: matrix(1.02, 0, 0, 1.02, 0, 0)"};
  }
`
const SlideMask = styled.div`
  width: 99.9%;
  flex-shrink: 0;
  overflow: hidden;
  background: white;
  @media (max-width: 1199px) {
    width: 100%;
  }
`

const SlideTitle = styled.h4`
  font-weight: 500;
  margin: 8rem 0 1.7rem;
  :after {
    position: absolute;
    content: "";
    bottom: -15px;
    left: 0;
    width: 80px;
    height: 3px;
    background-color: #e5edf7;
  }
  @media (max-width: 1199px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 767px) {
    margin-bottom: 11px;
    margin-top: 0;
  }
`

const BodyText = styled.p`
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;
  margin-top: 1.7rem;
  color: ${color.brand.blue};
  display: inline;
  @media (max-width: 1199px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 1023px) {
    margin-bottom: 7px;
  }
  @media (max-width: 600px) {
    line-height: 22px;
    margin-bottom: 12px;
  }
`
const Author = styled.span`
  display: inline;
  font-style: italic;
  margin-left: 5px;
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;
  white-space: nowrap;
`
const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  @media (max-width: 767px) {
  }
`
const Text = styled.div`
  width: 50%;
  padding: 10rem 9rem 12rem;
  position: relative;
  backface-visibility: hidden;
  & a {
    display: block;
    margin: 1.7rem 0 3.3rem;
  }
  @media (max-width: 1199px) {
    padding: 7rem 5rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 5rem 0;
    flex: 1;
  }
`
const Heading = styled.h3`
  font-family: ${headingFontLight};
  font-weight: 300;
  @media (max-width: 767px) {
    text-align: center;
  }
`

const Type = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2rem;
  font-weight: 400;
  @media (max-width: 767px) {
    margin: 24px 0 20px;
  }
`
const Mask = styled.div`
  overflow: hidden;
  width: 49.9%;
  perspective: 1000px;
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Image = styled(Img)<SlideProps>`
  width: 49.9%;
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  height: 100%;
  margin: auto 0;
  display: block;
  transform-origin: top center;
  will-change: transform;
  @media (max-width: 767px) {
    max-height: 700px;
    min-height: 300px;
  }
  @media (max-width: 450px) {
    max-height: 300px;
    min-height: 300px;
  }
`

const ProgressContainer = styled.div`
  width: 33%;
  position: absolute;
  bottom: 15rem;
  left: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1199px) {
    left: 9rem;
    width: 36%;
    bottom: 11rem;
  }
  @media (max-width: 1023px) {
    bottom: 7rem;
  }
  @media (max-width: 767px) {
    width: 85%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`
const Indent = styled.div`
  padding-left: 4rem;
  @media (max-width: 767px) {
    padding-left: 0;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const DotContainer = styled.div`
  display: flex;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`
interface IDot {
  isActive?: boolean
}
const Dot = styled.div<IDot>`
  height: 11px;
  width: 11px;
  background-color: ${props =>
    props.isActive ? color.brand.green : "transparent"};
  border: 1px solid
    ${props => (props.isActive ? color.brand.green : color.brand.blue)};
  border-radius: 50%;
  margin-right: 7px;
  transition: all 0.5s ease;
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    height: 7px;
    width: 7px;
    margin-right: 5px;
  }
`
const ArrowsContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px) {
    display: none;
  }
`

interface IChevron {
  first?: boolean
  last?: boolean
}
const ChevronRight = styled.img<IChevron>`
  height: 40px;
  width: 40px;
  top: 46%;
  right: 123px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 1199px) {
    right: 230px;
    top: 47%;
  }
  @media (max-width: 1023px) {
    height: 20px;
    top: 30px;
    right: 40px;
  }
  @media (max-width: 767px) {
    right: -25px;
    position: relative;
  }
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`

const ChevronLeft = styled(ChevronRight)`
  right: auto;
  left: 55px;
  transform: rotate(180deg);
  margin-right: 25px;
  @media (max-width: 1199px) {
    left: 65px;
    top: 47%;
  }
  @media (max-width: 1023px) {
    left: auto;
    right: 72px;
    top: 30px;
  }
  @media (max-width: 767px) {
    left: -25px;
    position: relative;
  }
`
const MobileArrows = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 60%;
    left: 0px;
    right: 0px;
    transform: translateY(-50%);
  }
  @media (max-width: 480px) {
    top: 48%;
  }
  @media (max-width: 340px) {
    top: 45%;
  }
`

export default Carousel

import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import MeetTheTeam from "../../helper/UserContext"
import Dropdown from "../Form/CustomDropdown"
import StandardSection from "../../helper/StandardSection"
import styled from "styled-components"
import RadioBtn from "../Form/RadioButton"
const MeetTheTeamFilter = ({ practices }) => {
  const { filter, setFilter } = useContext(MeetTheTeam)

  const data: any = useStaticQuery(graphql`
    query MeetTheTeamFilterPractices {
      nodeWhiteCoPractices {
        field_practice
      }
    }
  `)

  const handleChange = val => {
    setFilter(val)
  }

  return (
    <StandardSection>
      <Container>
        <RadioContainer>
          {practices &&
            practices.map((item, key) => {
              return (
                <RadioBtn
                  key={key}
                  onClick={() => {
                    handleChange(item)
                  }}
                  isActive={item === filter}
                >
                  {item}
                </RadioBtn>
              )
            })}
        </RadioContainer>
      </Container>
    </StandardSection>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  margin-top: 100px;
  @media (max-width: 767px) {
    margin-top: 50px;
  }
`
const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
`

export default MeetTheTeamFilter

import React from "react"
import styled from "styled-components"
import color from "../../style/color"

const Pill = styled.div`
  background-color: ${color.brand.green};
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  padding: 1px 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`

export default Pill

import React, { useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import { isInViewportFast, throttled } from "../../util/functions"
import Detail from "./Detail"
import leftTooth from "../../white-co-images/left-t.svg"
import rightTooth from "../../white-co-images/right-t.svg"
import gum from "../../white-co-images/gum-bg.png"
import gumMob from "../../white-co-images/gum-bg-mob.png"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import { mainGridDesktop } from "../../style/typography"

interface ToothSplitProp {
  detailsLeftSide: Array<IDetail>
  detailsRightSide: Array<IDetail>
  leftHeading: string
  rightHeading: string
}

interface IDetail {
  heading: string
  text?: string
  delay: number
}

const ToothSplit = ({
  leftHeading,
  rightHeading,
  detailsLeftSide,
  detailsRightSide,
}: ToothSplitProp) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const leftTooth: HTMLElement | null = document.getElementById(
        `left-tooth`
      )
      const rightTooth: HTMLElement | null = document.getElementById(
        `right-tooth`
      )
      const toothDivideP: HTMLElement | null = document.getElementById(
        `tooth-divide`
      )

      const animateTooth = (
        elem: HTMLElement | null,
        animation: string,
        duration: string,
        delay: string
      ) => {
        if (elem) {
          if (isInViewportFast(elem)) {
            elem.style.animation = `${animation} ${duration} ease-out forwards ${delay}`
          } else {
            elem.style.animationName = ""
          }
        }
      }

      const eventListener = throttled(100, () => {
        animateTooth(leftTooth, "animateLeftTooth", "0.3s", "0.6s")
        animateTooth(rightTooth, "animateRightTooth", "0.3s", "0.6s")
        animateTooth(toothDivideP, "toothDivide", "0.8s", "0s")
      })

      window.addEventListener("scroll", eventListener)

      return () => {
        window.removeEventListener("scroll", eventListener)
      }
    }
  }, [])

  return (
    <StandardSection>
      <VerticalPadding>
        <Container data-testid="tooth-split-container">
          <ToothDiagramCol>
            <img src={leftTooth} alt={"Natural tooth"} id="left-tooth" />

            <TextContainer>
              {leftHeading && <Subheading>{leftHeading}</Subheading>}
              <ToothDetailsCol>
                {detailsLeftSide.map((detail: IDetail, i: number) => {
                  return (
                    <Detail
                      id={`detail${i}`}
                      heading={detail.heading}
                      text={detail.text}
                      key={i}
                      gumLine={i === 1}
                      lineIndex={i}
                      delay={i}
                    />
                  )
                })}
              </ToothDetailsCol>
            </TextContainer>
          </ToothDiagramCol>
          <ToothDiagramColRight>
            <img src={rightTooth} alt={"Natural tooth"} id="right-tooth" />

            <TextContainerRight>
              {rightHeading && <Subheading>{rightHeading}</Subheading>}
              <ToothDetailsCol>
                {detailsRightSide.map((detail: IDetail, i: number) => {
                  return (
                    <Detail
                      id={`detail${i}right`}
                      rightAlign={true}
                      heading={detail.heading}
                      text={detail.text}
                      key={i}
                      lineIndex={i}
                      delay={i}
                    />
                  )
                })}
              </ToothDetailsCol>
            </TextContainerRight>
          </ToothDiagramColRight>
          <Gum src={gum} alt={"Gum"} />
          <GumMob src={gumMob} alt={"Gum"} />

          <ToothDivide id="tooth-divide" />
        </Container>
      </VerticalPadding>
    </StandardSection>
  )
}

export default ToothSplit

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${mainGridDesktop};
  display: flex;
  justify-content: center;
  position: relative;
  min-height: min-content;
  min-height: -webkit-min-content;

  @media (max-width: 1023px) {
    width: 92%;
    margin: 0 auto;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`
const ToothDiagramCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  position: relative;
  overflow: hidden;

  & img {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-top: 8.3rem;
    z-index: -1;
    transform: translateX(100%);
    transition: all ease 0.5s;
    // animation-delay: 2s;
    transform-style: preserve-3d;
    @media (max-width: 1199px) {
      margin-top: 6.3rem;
    }
    @media (max-width: 1023px) {
      margin-top: 0;
      height: 100%;
    }

    @media (max-width: 340px) {
      height: 80%;
      margin-top: 50px;
    }
    @media (max-height: 380px) {
      margin-top: 3rem;
      height: 80%;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`
const ToothDiagramColRight = styled(ToothDiagramCol)`
  align-items: flex-end;
  text-align: right;
  min-height: min-content;
  min-height: -webkit-min-content;
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
  }

  & img {
    right: auto;
    left: 0%;
    transform: translateX(-100%);

    @media (max-width: 767px) {
      right: auto;
      left: 0%;
      transform: translateX(0%);
      opacity: 0;
    }

    @media (max-width: 600px) {
      // height: auto;
      // max-height: 340px;
    }
  }
`
const TextContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 10%;
  padding-bottom: 6rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const TextContainerRight = styled(TextContainer)`
  padding-left: 10%;
  padding-right: 0;
  align-items: flex-end;
  min-height: min-content;
  min-height: -webkit-min-content;
`

const Subheading = styled.h2`
  margin: 10px 0 20px;
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 500;
  color: ${color.brand.blue};
  @media (max-width: 767px) {
    margin: 10px 0 5px;
    font-size: 16px;
    line-height: 26px;
  }
`

const ToothDetailsCol = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: min-content;
  min-height: -webkit-min-content;
`
const Gum = styled.img`
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -5;
  margin-top: 27rem;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1023px) {
    height: 50%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`

const GumMob = styled(Gum)`
  display: none;
  @media (max-width: 767px) {
    display: block;
    right: 70vw;
    height: 60%;
    max-width: 37vw;
    margin-top: 28rem;
  }
`

const ToothDivide = styled.div`
  position: absolute;
  left: 0%;
  top: 1rem;
  right: 0%;
  bottom: 0%;
  margin-right: auto;
  margin-left: auto;
  border-left: 2px dashed ${color.keyline.lineGreen};
  width: 2px;
  height: 0;
  @media (max-width: 1023px) {
    top: 0rem;
  }
  @media (max-width: 767px) {
    left: 0;
    right: auto;
    z-index: 8;
    top: 0rem;
  }
`

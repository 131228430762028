import React from "react"
import styled from "styled-components"
import color from "../../../style/color"

interface FirstSectionProps {
  children: any
  background?: string
  paddingTop?: string
}

const FirstSection = ({
  children,
  background,
  paddingTop,
}: FirstSectionProps) => {
  return (
    <Container paddingTop={paddingTop} background={background}>
      {children}
    </Container>
  )
}

export default FirstSection

const Container = styled.div<FirstSectionProps>`
  padding: 7.7rem 0 0;
  padding-top: ${props => props.paddingTop && props.paddingTop};
  margin: 0 auto;
  background: ${props => (props.background ? props.background : "white")};
  @media (max-width: 767px) {
    width: 100%;
    padding: 5rem 0 0;
  }
`

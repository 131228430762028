import React from "react"
import styled from "styled-components"
import color from "../../style/color"

interface ISectionHeading {
  heading: string
  leftAlign?: boolean
  marginBottom?: boolean
}
const SectionHeading = ({
  heading,
  leftAlign,
  marginBottom,
}: ISectionHeading) => {
  return (
    <Container
      data-testid="section-heading-test"
      marginBottom={marginBottom}
      leftAlign={leftAlign}
    >
      <Heading>{heading}</Heading>
    </Container>
  )
}

export default SectionHeading

const Container = styled.div<ISectionHeading>`
  text-align: ${props => (props.leftAlign ? "left" : "center")};
  width: 100%;
  max-width: 800px;
  margin-left: ${props => (props.leftAlign ? "0" : "auto")};
  margin-right: auto;
  margin-bottom: ${props => (props.marginBottom ? "10px" : "0")};
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`

const Heading = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
`

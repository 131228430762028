import React from "react"
import HomepageHeader from "./HomepageHeader"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const HomepageHeaderDrupal = ({ node }) => {
  const {
    deskAlt = {},
    sectionHeading,
    sectionBody,
    relationships,
    dropdown,
    dropdownLabel,
  } = node || {}
  const { dImg, mImg } = relationships || {}

  const imageDesktop = get(["localFile", "publicURL"], dImg, "")
  const imageMobile = get(["localFile", "publicURL"], mImg, "")

  if (!dImg) {
    return <></>
  }

  return (
    <HomepageHeader
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      imageDesktop={imageDesktop}
      imageMobile={imageMobile}
      altDesktop={deskAlt.alt}
      dropdown={dropdown}
      dropdownLabel={dropdownLabel}
      sources={[
        {
          ...dImg.localFile.childImageSharp.fluid,
          media: `(min-width: 767px)`,
        },
        {
          ...mImg.localFile.childImageSharp.fluid,
          media: `(max-width: 767px)`,
        },
      ]}
    />
  )
}

export default HomepageHeaderDrupal

export const fragment = graphql`
  fragment HomepageHeader on paragraph__homepage_header {
    id
    sectionHeading: field_section_heading
    sectionBody: field_section_body
    dropdownLabel: field_dropdown_label
    mobAlt: field_image_mobile {
      alt
    }
    deskAlt: field_image_desktop {
      alt
    }
    dropdown: field_dropdown_links {
      title
      uri
    }
    relationships {
      dImg: field_image_desktop {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      mImg: field_image_mobile {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 767, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import Cta from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import getOpeningTimes from "./OpeningTimesFunction"
import moment from "moment"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import Pill from "../../shared/Pill/Pill"
import { trackButtonClick } from "../../util/functions"

interface Practice {
  heading: string
  title: string
  phone?: string
  comingSoon: boolean
  body: string
  ctaUrl: string
  ctaText: string
}
interface OurPracticesProps {
  practices: Array<Practice>
  heading?: string
}

const OurPractices = ({ heading, practices }: OurPracticesProps) => {
  const renderOpeningTimes = () => {
    const { isOpen, text } = getOpeningTimes(moment())

    return (
      <OpeningTimes>
        <Pill>{isOpen ? "OPEN NOW" : "CLOSED"}</Pill> <p>{text}</p>
      </OpeningTimes>
    )
  }

  return (
    <MainContainer>
      <StandardSection>
        <VerticalPadding>
          <Container>
            {heading && (
              <SmallHeading data-testid="location-heading" heading={heading} />
            )}

            <ColContainer>
              {practices.map((practice, key) => {
                if (key < 3) {
                  return (
                    <Col key={key} marginRight={(key + 1) % 3 !== 0}>
                      <Title data-testid="practice-heading">
                        {practice.heading}
                      </Title>
                      <Name data-testid="practice-title">{practice.title}</Name>
                      {practice.phone && (
                        <Phone
                          href={`tel:${practice.phone}`}
                          data-testid="practice-phone"
                          onClick={() =>
                            trackButtonClick(
                              "Phone Number Click: Our Locations"
                            )
                          }
                        >
                          {practice.phone}
                        </Phone>
                      )}
                      {practice.comingSoon ? (
                        <OpeningTimes data-testid="pill-text-coming-soon">
                          <Pill>COMING SOON</Pill>
                        </OpeningTimes>
                      ) : (
                        renderOpeningTimes()
                      )}
                      <Value
                        data-testid="practice-body"
                        dangerouslySetInnerHTML={{
                          __html: `${practice.body}`,
                        }}
                      />
                      <BtnContainer>
                        <Cta
                          size="small"
                          href={practice.ctaUrl}
                          text={practice.ctaText}
                        />
                      </BtnContainer>
                    </Col>
                  )
                }
              })}
            </ColContainer>
            {practices.length > 3 && (
              <SeePracticesBtnContainer>
                <Cta
                  text="See all practices"
                  href={
                    (process.env.BASE === "/" ? "" : process.env.BASE) +
                    "/practices"
                  }
                />
              </SeePracticesBtnContainer>
            )}
          </Container>
        </VerticalPadding>
      </StandardSection>
    </MainContainer>
  )
}

const MainContainer = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: ${color.background.lightGreen};
  // box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
`

const Phone = styled.a`
  &,
  &:visited,
  &:link {
    font-weight: 300;
    font-size: 20px;
    line-height: 3rem;
    cursor: pointer;
    margin-bottom: 30px;
    display: inline-block;
  }
`

const OpeningTimes = styled.div`
  display: flex;
  align-items: flex-end;

  & p {
    margin-left: 7px;
    font-weight: 300;
    font-size: 14px;
  }
`

export default OurPractices

const Container = styled.div`
  width: 100%;
`

const ColContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`
interface ICol {
  marginRight?: boolean
}
const Col = styled.div<ICol>`
  width: 30%;
  margin: 6.6rem 5% 0 0;
  margin-right: ${props => (props.marginRight ? "5%" : "0px")};
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type):after {
      content: "";
      width: 150px;
      height: 1px;
      margin: 0;
      background-color: ${color.brand.green};
      margin-top: 51px;
    }

    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }
`

const Title = styled.h3`
  font-family: headingFontLight;
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-weight: 300;

  @media (max-width: 1023px) {
    font-size: 26px;
    line-height: 36px;
  }
`

const Name = styled(Title)`
  font-family: Futura;
  font-weight: 400;
  margin-bottom: 15px;
`

const Value = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;

  *,
  & * {
    font-weight: 300;

    @media (max-width: 767px) {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
  }
  & a {
    font-size: inherit;
  }
`
const BtnContainer = styled.div`
  width: 100%;
  max-width: 147px;
`
const SeePracticesBtnContainer = styled.div`
  margin-top: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
`

import React, { useEffect } from "react"
import styled from "styled-components"
import { isInViewport, throttled } from "../../util/functions"
import gumLine from "../../white-co-images/gum-line.svg"
import gum from "../../white-co-images/gum.svg"
import screw from "../../white-co-images/screw.svg"
import cap from "../../white-co-images/cap.svg"
import green from "../../white-co-images/green.svg"
import crown from "../../white-co-images/crown.svg"
import StepsContainer from "./Steps/StepsContainer"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import color from "../../style/color"
import { mainGridDesktop } from "../../style/typography"
import LastStep from "./Steps/LastStep"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"

interface IToothAnimation {
  sectionHeading?: string
  subHeading?: string
  imageDesktop?: string
  imageMobile?: string
  stepNumber?: number
  heading?: string
  text?: string
  ctaUrl1?: string
  ctaText1?: string
}

const ToothAnimation = ({
  sectionHeading,
  subHeading,
  imageDesktop,
  imageMobile,
  stepNumber,
  heading,
  text,
  ctaUrl1,
  ctaUrl2,
  ctaText1,
  ctaText2,
  steps,
}: IToothAnimation) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const tooth: HTMLElement | null = document.getElementById(`tooth`)
      const screw: HTMLElement | null = document.getElementById(`screw`)
      const gum: HTMLElement | null = document.getElementById(`gum`)
      const cap: HTMLElement | null = document.getElementById(`cap`)
      const green: HTMLElement | null = document.getElementById(`green`)
      const crown: HTMLElement | null = document.getElementById(`crown`)
      const container: HTMLElement | null = document.getElementById(
        `anim-container`
      )
      let distanceFromTopPage: number = 0
      if (container) {
        distanceFromTopPage = container.getBoundingClientRect().top
      }
      const animateElement = (
        elem: HTMLElement | null,
        percent: number,
        end: number,
        start: number
      ) => {
        let currentScroll =
          (document.documentElement.scrollTop || document.body.scrollTop) -
          distanceFromTopPage
        if (tooth) {
          if (isInViewport(tooth)) {
            let containerHeight = 0
            if (container) {
              containerHeight = container.getBoundingClientRect().height
            }
            if (elem && tooth) {
              if (container) {
                // animate the opacity
                if (elem === green || elem === cap || elem === crown) {
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.opacity = "1"
                  } else {
                    elem.style.opacity = "0"
                  }
                }

                if (elem === screw) {
                  if (currentScroll - containerHeight * percent > 50) {
                    elem.style.opacity = "1"
                  } else {
                    elem.style.opacity = "0"
                  }
                }

                if (elem !== gum) {
                  let distance: number = Math.max(
                    Math.min(currentScroll - containerHeight * percent, end),
                    start
                  )
                  // move element
                  elem.style.top = `${distance}px`

                  // cap
                  if (elem === cap) {
                    const capGoingUp: number = containerHeight * 0.38

                    // move cap up
                    if (currentScroll > capGoingUp) {
                      elem.style.opacity = "0.8"

                      const capDistance: number = Math.min(
                        350,
                        currentScroll - capGoingUp
                      )
                      elem.style.transform = `translateY(-${capDistance}px)`

                      const animateCapOpacity: boolean =
                        elem.style.transform === `translateY(-${capDistance}px)`
                      if (animateCapOpacity) {
                        cap.style.opacity = "0"
                      }
                    }
                    // if scrolling up, move the cap down
                    else {
                      elem.style.transform = `translateY(0)`
                    }
                  }
                }

                if (elem === gum) {
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.opacity = "0"
                  } else {
                    elem.style.opacity = "1"
                  }
                }
              }
            }
          }
        }
      }

      const eventListener = throttled(100, () => {
        animateElement(screw, 0.03, 100, -120)
        animateElement(cap, 0.17, 98, -220)
        animateElement(green, 0.62, 100, -220)
        animateElement(crown, 0.77, 100, -220)
        animateElement(gum, 0.28, 100, 0)
      })

      window.addEventListener("scroll", eventListener)

      return () => {
        window.removeEventListener("scroll", eventListener)
      }
    }
  }, [])
  return (
    <Outer>
      {subHeading && (
        <SubHeadingContainer>
          <SmallHeading heading={subHeading} marginBottom />
        </SubHeadingContainer>
      )}
      {sectionHeading && <SectionHeading heading={sectionHeading} />}

      <Container id="anim-container">
        <Animation>
          <StickyContainer>
            <Screw id="screw" src={screw} data-testid="screw" />
            <Gum id="gum" src={gum} data-testid="gum" />
            <Cap id="cap" src={cap} data-testid="cap" />
            <Green id="green" src={green} data-testid="green" />
            <Crown id="crown" src={crown} data-testid="crown" />
            <GumLine id="tooth" src={gumLine} data-testid="tooth" />
          </StickyContainer>
        </Animation>
        <StepsContainer id="stepsDesktop" steps={steps} />
      </Container>
      <LastStep
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        stepNumber={stepNumber}
        heading={heading}
        text={text}
        ctaUrl1={ctaUrl1}
        ctaText1={ctaText1}
        id={"lastStepDesktop"}
      />
    </Outer>
  )
}

export default ToothAnimation
const Outer = styled.div`
  background: ${color.background.blueBackground};
  padding: 10rem 0 0;
  @media (max-width: 1199px) {
    display: none;
  }
`
const SubHeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const Container = styled.div`
  display: flex;
  width: 92%;
  max-width: ${mainGridDesktop};
  margin: 53px auto 0;
  height: 100%;
  @media (max-width: 1199px) {
    display: none;
  }
`

const Animation = styled.div`
  width: 50%;
  margin-bottom: 100px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`
const StickyContainer = styled.div`
  position: sticky;
  top: 40vh;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 300px;
  width: 488px;
  margin-top: -100px;
`
const GumLine = styled.img`
  height: 200px;
  width: 100%;
  position: relative;
`

const Screw = styled.img`
  opacity: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  position: absolute;
  left: 0%;
  top: -120px;
  right: 0%;
  bottom: 0%;
  margin: 0 auto;
  transition: all ease 0.5s;
  z-index: 5;
  transform-style: preserve-3d;
`
const Cap = styled(Screw)`
  z-index: 4;
  transition: opacity 0.2s ease;
  opacity: 0;
  top: -150%;
`
const Green = styled(Screw)`
  top: -220px;
  opacity: 0;
`

const Crown = styled(Green)``

const Gum = styled.img`
  position: absolute;
  top: 100px;
  left: 0;
  transition: opacity ease 1s;
  z-index: 2;
  width: 100%;
  opacity: 1;
`

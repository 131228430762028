import React from "react"
import styled from "styled-components"
import SmallSection from "../../helper/SmallSection"
import arrow from "../../white-co-images/largeArrowGreen.svg"

interface TextSection {
  heading: string
  body: string
}

interface SectionProps {
  name: string
  position: string
  branch: string
  gdc: string
  textSection: Array<TextSection>
}

const AboutUsSection = ({
  name,
  position,
  branch,
  gdc,
  textSection,
}: SectionProps) => {
  return (
    <TinySection>
      <SmallSection>
        <Container>
          <TopRow>
            <h2>{name}</h2>
            <Details>
              <p>Position: {position}</p>
              <p>Branch: {branch}</p>
              {gdc && <p>GDC: {gdc}</p>}
            </Details>
          </TopRow>
          {textSection.map((section: TextSection, i: number) => {
            return (
              <Section key={i}>
                <h5>{section.heading}</h5>
                <p>{section.body}</p>
              </Section>
            )
          })}
          <LinkContainer>
            <img src={arrow} />
            <a
              href={
                process.env.BASE === "/"
                  ? "/practices/meet-the-team"
                  : process.env.BASE + "/practices/meet-the-team"
              }
            >
              Back to meet the team
            </a>
          </LinkContainer>
        </Container>
      </SmallSection>
    </TinySection>
  )
}

const TinySection = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

const LinkContainer = styled.div`
  display: flex;
  margin-top: 6.8rem;

  & img {
    width: 23px;
    margin-right: 10px;
    transform: rotate(180deg);
  }

  & a {
    font-size: 16px;
  }
`

const Container = styled.div`
  margin-top: 7.6rem;
  margin-bottom: 10.6rem;

  @media (max-width: 1023px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const Section = styled.div`
  display: flex;

  & h5 {
    font-family: headingFontLight;
    font-weight: 300;
    flex: 0.5;

    @media (max-width: 1023px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  & p {
    flex: 0.6;
    margin-bottom: 3.6rem;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const TopRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  & h2 {
    font-family: headingFontLight;
    font-weight: 300;
    flex: 0.5;
    @media (max-width: 1023px) {
      font-size: 32px;
      margin-bottom: 5px;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const Details = styled.div`
  flex: 0.6;
  & p {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
  }
`

export default AboutUsSection

import React from "react"
import styled from "styled-components"

interface Section {
  children: any
  noPaddingMob?: boolean
  noPaddingTablet?: boolean
  noPaddingSmallTablet?: boolean
}
const StandardSection = ({
  children,
  noPaddingMob,
  noPaddingTablet,
  noPaddingSmallTablet,
}: Section) => {
  return (
    <Section
      noPaddingMob={noPaddingMob}
      noPaddingSmallTablet={noPaddingSmallTablet}
      noPaddingTablet={noPaddingTablet}
    >
      {children}
    </Section>
  )
}

interface ISection {
  noPaddingMob?: boolean
  noPaddingTablet?: boolean
  noPaddingSmallTablet?: boolean
}
const Section = styled.div<ISection>`
  display: flex;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  @media (max-width: 1199px) {
    width: 100%;
    padding: ${props => (props.noPaddingTablet ? "0px" : "0 3%")};
  }
  @media (max-width: 1023px) {
    padding: ${props => (props.noPaddingSmallTablet ? "0px" : "0 3%")};
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px;
    padding: ${props => (props.noPaddingMob ? "0px" : "0 25px")};
  }
`

export default StandardSection

import React, { useState, useEffect } from "react"
import BtnWithImage from "../BtnWithImage"
import color from "../../../style/color"
import Btn from "../../../shared/Button/Button"
import styled from "styled-components"
import Label from "../Label"
import overbite from "../../../white-co-images/overbite.svg"
import underbite from "../../../white-co-images/underbite.svg"
import crossbite from "../../../white-co-images/crossbite.svg"
import gapped from "../../../white-co-images/gapped-teeth.svg"
import openbite from "../../../white-co-images/openbite.svg"
import crowded from "../../../white-co-images/crowded.svg"
import overbiteWhite from "../../../white-co-images/overbite-white.svg"
import underbiteWhite from "../../../white-co-images/underbite-white.svg"
import crossbiteWhite from "../../../white-co-images/crossbite-white.svg"
import gappedWhite from "../../../white-co-images/gapped-teeth-white.svg"
import openbiteWhite from "../../../white-co-images/openbite-white.svg"
import crowdedWhite from "../../../white-co-images/crowded-white.svg"

interface StepProps {
  changeStep: any
  teeth: string
  setTeeth: any
  teethTypeError: boolean
  toggleTeethTypeError: any
}

interface BtnValue {
  title: string
  subTitle: string
  image: string
  hoveredImage: string
}

const InvisalignStep2 = ({
  changeStep,
  teeth,
  setTeeth,
  teethTypeError,
  toggleTeethTypeError,
}: StepProps) => {
  const teethList: Array<BtnValue> = [
    {
      title: "Overbite",
      subTitle: "Front teeth sticking out",
      image: overbite,
      hoveredImage: overbiteWhite,
    },
    {
      title: "Underbite",
      subTitle: "Lower jaw sticks out",
      image: underbite,
      hoveredImage: underbiteWhite,
    },
    {
      title: "Crossbite",
      subTitle: "Upper teeth fit inside lower teeth",
      image: crossbite,
      hoveredImage: crossbiteWhite,
    },
    {
      title: "Gap teeth",
      subTitle: "Extra spaces between teeth",
      image: gapped,
      hoveredImage: gappedWhite,
    },
    {
      title: "Openbite",
      subTitle: "Top and bottom teeth don’t meet",
      image: openbite,
      hoveredImage: openbiteWhite,
    },
    {
      title: "Crowded teeth",
      subTitle: "To many teeth to fit normally",
      image: crowded,
      hoveredImage: crowdedWhite,
    },
  ]

  return (
    <Form>
      <Inner>
        <Label label="Which do you think most closely resembles your current teeth and smile?*" />
        <BtnsContainer hasError={teethTypeError} id="teeth-type-container">
          {teethList.map((r: BtnValue, key) => {
            return (
              <BtnWithImage
                key={r.title}
                title={r.title}
                subTitle={r.subTitle}
                image={r.image}
                hoveredImage={r.hoveredImage}
                onClick={() => {
                  setTeeth(r.title)
                  toggleTeethTypeError(false)
                }}
                isActive={r.title === teeth}
                noMarginRight={key === 2 || key === 5}
                id={`btn-with-image${key}`}
              />
            )
          })}
        </BtnsContainer>
      </Inner>
    </Form>
  )
}
interface BtnsContainerProps {
  hasError?: boolean
}
const BtnsContainer = styled.div<BtnsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  & div {
    ${props =>
      props.hasError &&
      `
        border-color: ${color.support.red};
        color: ${color.support.red};
        `}
  }
  @media (max-width: 767px) {
    justify-content: space-between;
  }
`

export default InvisalignStep2

const Form = styled.div`
  @media (max-width: 767px) {
  }
`

interface InnerProps {
  paddingBottom?: boolean
}
const Inner = styled.div<InnerProps>`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding-bottom: ${props => (props.paddingBottom ? "6rem" : "2rem")};
  @media (max-width: 767px) {
    width: 100%;
    padding: ${props => (props.paddingBottom ? "0 25px 6rem" : "0 25px 2rem")};
  }
`

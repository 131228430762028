import React from "react"
import styled from "styled-components"
import color from "../../../style/color"

interface InnerProps {
  paddingBottom?: boolean
  invisalignLastStep?: boolean
  children: any
}

const Inner = ({ children, paddingBottom, invisalignLastStep }: InnerProps) => {
  return (
    <Container
      paddingBottom={paddingBottom}
      invisalignLastStep={invisalignLastStep}
    >
      {children}
    </Container>
  )
}

export default Inner

const Container = styled.div<InnerProps>`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  position: relative;
  padding-bottom: ${props => (props.paddingBottom ? "6rem" : "1rem")};
  padding-bottom: ${props => props.invisalignLastStep && "8rem"};
  @media (max-width: 767px) {
    width: 100%;
    padding: ${props => (props.paddingBottom ? "0 25px 6rem" : "0 25px 1rem")};
    padding: ${props => props.invisalignLastStep && "0 25px 8rem"};
  }
`

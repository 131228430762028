import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import pic from "../../white-co-images/smileAssessment_D.jpg"
import picMob from "../../white-co-images/smileAssesment_M.jpg"

interface FormHeadingGreyProps {
  heading?: string
  text?: string
}

interface Step {
  heading?: string
  text?: string
}

const steps: Array<Step> = [
  {
    heading: "01. Smile and put your teeth together",
    text: "Now please take a picture.",
  },
  {
    heading: "02. Lean your head back",
    text: "Please take a picture of your top teeth.",
  },
  {
    heading: "03. Pop your head down",
    text: "Please take a picture of your bottom teeth.",
  },
  {
    heading: "04. Submit your pictures",
    text:
      "Please fill out the form below and follow the instructions to submit your pictures. Our dentists will email back with a written consultation. We will tell you which treatments could help you to improve your smile and your confidence.",
  },
]

const SmileSteps = () => {
  return (
    <ImageAndText>
      <Image src={pic} alt="Smile form. How it works" />
      <ImageMob src={picMob} alt="Smile form. How it works" />
      <TextContainer>
        <h2>How it works</h2>
        <Light>
          Please follow these four steps to help our dentists with your
          complimentary online consultation. They will use their experience and
          expertise to advise you on the perfect treatment for you. This service
          is perfect for busy people and nervous patients.
        </Light>
        {steps.map((step, key) => {
          return (
            <Step key={key}>
              <StepHeading>{step.heading}</StepHeading>
              <StepText>{step.text}</StepText>
            </Step>
          )
        })}
      </TextContainer>
    </ImageAndText>
  )
}

export default SmileSteps

const ImageAndText = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  background: ${color.background.blueLight};
  & h2 {
    font-weight: 500;
    margin-bottom: 2rem;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Light = styled.p`
  font-weight: 300;
  margin-bottom: 1.5rem;
`

const Image = styled.img`
  width: 50%;
  object-fit: cover;
  @media (max-width: 767px) {
    display: none;
  }
`
const ImageMob = styled.img`
  display: none;
  @media (max-width: 767px) {
    display: block;
    object-fit: cover;
    width: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7.3rem 4.4rem 9rem 6rem;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    padding: 50px 25px;
  }

  & h2 {
    font-family: headingFontLight;
    font-weight: 300;
  }
`
const Step = styled.div`
  padding-top: 2rem;
`

const StepHeading = styled.p`
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 400;
  margin-bottom: 4px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const StepText = styled.p`
  padding-left: 30px;
  font-weight: 300;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
`

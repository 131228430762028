import React, { useState } from "react"
import styled from "styled-components"
import FaqQuestion from "./FaqQuestion"
import color from "../../style/color"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import VerticalPadding from "../../helper/VerticalPadding"

interface IQuestions {
  question: string
  answer: React.ReactNode
}

interface FaqProps {
  questions: Array<IQuestions>
  sectionHeading: string
}

const Faq = ({ sectionHeading, questions }: FaqProps) => {
  const [activeQuestion, toggleQuestion] = useState(null)

  const setActiveQuestion = i => {
    if (activeQuestion === i) {
      toggleQuestion(null)
    } else {
      toggleQuestion(i)
    }
  }

  return (
    <Container>
      <VerticalPadding noPaddingBottomTablet>
        <InnerContainer>
          <HeadingContainer>
            <SectionHeading heading={sectionHeading} />
          </HeadingContainer>
          {questions.map((question, i) => {
            return (
              <FaqQuestion
                question={question.question}
                key={i}
                last={i >= questions.length - 1}
                first={i === 0}
                answer={question.answer}
                isOpen={i === activeQuestion}
                toggleAnswer={() => setActiveQuestion(i)}
              />
            )
          })}
        </InnerContainer>
      </VerticalPadding>
    </Container>
  )
}

export default Faq

const InnerContainer = styled.div`
  width: 90%;
  max-width: 967px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
`
const HeadingContainer = styled.div`
  margin-bottom: 64px;
  @media (max-width: 767px) {
    padding: 0 25px;
    margin-bottom: 33px;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  background-color: ${color.background.lightGrey};
  background-position: 0px 0px;
  background-size: 6px 6px;
`
const FaqIcon = styled.img`
  width: 159px;
  margin-bottom: 15px;
`

const Heading = styled.h1`
  margin-bottom: 50px;
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 30px;
  }
  @media (max-width: 850px) {
    font-size: 16px;
    line-height: 19px;
  }
`

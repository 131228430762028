import React, { useEffect, useState } from "react"
import Layout from "../helper/layout"
import { getParagraph } from "../util/paragraphHandler"
import Helmet from "react-helmet"
import NavigationContainer from "../shared/Navigation/NavigationDrupal"
import Footer from "../shared/Footer/FooterDrupal"
import LogoTop from "../shared/LogoTop/LogoTop"
import { graphql } from "gatsby"
import styled from "styled-components"
import PageForm from "../components/Form/PageForm"
import { UserProvider } from "../helper/UserContext"
import InfluencerEnquiryForm from "../components/Form/InfluencerEnquiryForm"
import SmallHeading from "../shared/SmallHeading/SmallHeading"
import SectionHeading from "../shared/SectionHeading/SectionHeading"

const MasterTemplate = ({ data }) => {
  const paragraphs = data.page.relationships.paragraphs.map(getParagraph)
  const [menuIsOpen, toggleMenu] = useState(false)
  const [mTTFilter, setMTTFilter] = useState("All")

  return (
    <Layout hideStickyButtons={data.page.field_hide_enquiry_buttons ||data.page.field_page_form === "Influencer" }>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.page.title}</title>
        <meta name="description" content={data.page.field_meta_description} />
        {process.env.GATSBY_AWS_BRANCH !== "master" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link
          rel="canonical"
          href={`https://www.whiteandcodental.co.uk` + data.page.path.alias}
        />
        <script type="application/ld+json">
          {`
          {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "White & Co",
          "url": "https://www.whiteandcodental.co.uk/",
          "address": "19 Battersea Rise, London, SW11 1HG",
          "sameAs": [
            "https://www.facebook.com/whiteandcodental/",
            "https://www.instagram.com/whiteandcodental/"
            ]
          }
        `}
        </script>
      </Helmet>
      <LogoTop mini={data.page.field_page_form === "Influencer"}/>
      {data.page.field_page_form === "Influencer" ? (
        <div style={{ height: 20, backgroundColor: "#061b30" }}></div>
      ) : (
        <NavigationContainer
          slug={data.page.path.alias}
          toggleMenu={toggleMenu}
          menuIsOpen={menuIsOpen}
        />
      )}

      <ComponentContainer id="component-container" hideContent={menuIsOpen}>
        {data.page.field_page_form === "Influencer" ? (
          <>
          <InfluencerEnquiryForm />
          <HeadingContainer>
          <SmallHeading heading={"OUR APPROACH"} marginBottom />
          <SectionHeading heading={"Delivering expertise and excellence for more than 25 years."} />
        </HeadingContainer>
          </>
        ) : (
          ""
        )}

        <UserProvider
          value={{
            filter: mTTFilter,
            setFilter: setMTTFilter,
          }}
        >
          {paragraphs}
        </UserProvider>
        <div id="page-form">
          <PageForm form={data.page.field_page_form} />
        </div>
        <Footer mini={data.page.field_page_form === "Influencer"} />
      </ComponentContainer>
    </Layout>
  )
}

export default MasterTemplate

const HeadingContainer = styled.div`
  text-align: center;
  padding-top: 10rem;
  padding-bottom: 10rem;

  @media (max-width: 767px) {
    padding: 50px 25px 0;
  }
`

interface ComponentContainerProps {
  hideContent: boolean
  height: string
}

const ComponentContainer = styled.div<ComponentContainerProps>`
  ${props => props.hideContent && `visibility: hidden`};
`

export const pageQuery = graphql`
  query($slug: String!) {
    page: nodeMasterTemplate(fields: { slug: { eq: $slug } }) {
      id
      title
      path {
        alias
      }
      field_meta_description
      field_page_form
      field_hide_enquiry_buttons
      relationships {
        paragraphs: field_component {
          type: __typename
          ...MeetTheTeamFilter
          ...FinanceCalculator
          ...Press
          ...Sitemap
          ...AllPatientStories
          ...PatientReferrals
          ...Breadcrumb
          ...TextContent
          ...FinanceTable
          ...AboutUsHeader
          ...TeamSection
          ...ToothSplit
          ...DentalImplantsTable
          ...RangeOfTreatments
          ...SmileHeader
          ...InvisalignTable
          ...InvisalignAnimation
          ...DentalAnimation
          ...ExperienceCarousel
          ...PracticesContainer
          ...PatientStories
          ...HalfAndHalf
          ...TreatmentContainer
          ...HomepageHeader
          ...GreyHeader
          ...PricePageTable
          ...TreatmentPriceTable
          ...CategoryHeader
          ...BeforeAfterCarousel
          ...PromotionBanner
          ...FinanceBanner
          ...Benefits
          ...StepsToPerfectSmile
          ...TreatmentHeader
          ...TreatmentListDrupal
          ...TreatmentExplanation
          ...Faq
          ...BlogPosts
          ...AboutUsSection
          ...PracticeCards
          ...PracticeDetails
          ...ReferralSection
          ...VideoConsultation
          ...HomepageMeetTheTeamDrupal
          ...GallerySection
          ...HomepageMeetTheTeamDrupal
          ...InvisalignVideo
        }
      }
    }
  }
`

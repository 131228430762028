import React from "react"
import styled from "styled-components"
import FullWidthContainer from "../../helper/FullWidthContainer"
import color from "../../style/color"

interface Props {
  text: string
  fullWidth?: boolean
}

const TextContent = ({ text, fullWidth }: Props) => {
  return fullWidth ? (
    <FullWidthContainer>
      <Content dangerouslySetInnerHTML={{ __html: text }} fullWidth />
    </FullWidthContainer>
  ) : (
    <TinySection>
      <Content dangerouslySetInnerHTML={{ __html: text }} />
    </TinySection>
  )
}

export default TextContent

interface ContentProps {
  fullWidth?: boolean
}

const Content = styled.div<ContentProps>`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-family: headingFontLight;
    font-weight: 300;
  }

  & h1,
  & h2 {
    margin-bottom: 6.2rem;
  }

  & h3,
  & h4,
  & h5,
  p,
  div {
    margin-bottom: 1.8rem;
    display: inline-block;
  }

  & hr {
    margin-top: 3.5rem;
    margin-bottom: 5.3rem;
    color: ${color.background.lightGrey};
    opacity: 0.2;
  }

  margin-top: 7.3rem;
  margin-bottom: 13.8rem;
  ${props => props.fullWidth && `margin-bottom: 8rem;`}

  @media (max-width: 1023px) {
    margin-top: 36px;
    margin-bottom: 102px;

    & h1,
    & h2 {
      margin-bottom: 36px;
    }

    & h3,
    & h4,
    & h5,
    p,
    div {
      margin-bottom: 16px;
    }

    & hr {
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
`

const TinySection = styled.div`
  max-width: 994px;
  margin: 0 auto;
  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 0 3%;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
    padding: 0 25px;
  }
`

import React from "react"
import styled from "styled-components"
import { get, formatLink } from "../../util/functions"
import { graphql } from "gatsby"
import color from "../../style/color"
import { headingFontLight } from "../../style/typography"
import Pill from "../../shared/Pill/Pill"
import Button from "../../shared/Button/Button"
import icon from "../../images/homepage/arrow-grey-icon.svg"
import { Breadcrumb, Link, Icon } from "../../shared/Breadcrumb/Breadcrumb"
import TextTwoColumnsSmall from "../TextTwoColumnsSmall/TextTwoColumnsSmall"
import StandardSection from "../../helper/StandardSection"
import { scrollPage, trackButtonClick } from "../../util/functions"

interface ICol {
  title?: string
  text?: string
}

interface ITreatmentHeader {
  altDesktop?: string
  altMob?: string
  imageMobile?: string
  imageDesktop?: string
  sectionHeading?: string
  sectionBody?: string
  pillText?: string
  price?: string
  details?: Array<ICol>
  ctaText1: string
  ctaHref1: string
  breadcrumb: any
  textColumnsSmall: any
  textColumnHeading: string
  columnsCta: any
  logoAlt?: string
  logo?: string
  financeAvailable?: boolean
  internalLink?: boolean
}

const TreatmentHeader = ({
  sectionHeading,
  sectionBody,
  imageDesktop,
  imageMobile,
  pillText,
  price,
  ctaText1,
  ctaHref1,
  details,
  altMob,
  breadcrumb,
  textColumnsSmall,
  textColumnHeading,
  columnsCta,
  logoAlt,
  logo,
  financeAvailable,
  internalLink,
}: ITreatmentHeader) => {
  return (
    <BackgroundColor>
      <MainContainer
        data-testid="main-container"
        imageMobile={imageMobile}
        imageDesktop={imageDesktop}
      >
        <StandardSection noPaddingMob noPaddingTablet>
          <InnerContainer>
            <TextContainer data-testid="text-container">
              {pillText && <TopText>{pillText}</TopText>}
              {sectionHeading && (
                <SectionHeading data-testid="treatment-heading">
                  {sectionHeading}
                </SectionHeading>
              )}
              {sectionBody && (
                <Body data-testid="treatment-body">{sectionBody}</Body>
              )}
              {price && (
                <Price data-testid="treatment-price">
                  From <span>£{price}</span>{" "}
                  {financeAvailable && (
                    <Finance href="/fees-and-finance/0-finance">
                      0% Finance available
                    </Finance>
                  )}
                </Price>
              )}
              {ctaText1 && (
                    <ButtonsContainer>
                  <div>To book online, please use the green 'Book Appointment' button at the bottom of the page and select the practice you wish to attend.</div>
                 {
                // <Button
                  //  text={ctaText1}
                  //  href={ctaHref1}
                  //  onClick={() => {
                  //    trackButtonClick(
                  //      `${sectionHeading} Treatment Header: ${ctaText1} Button`
                  //    )
                  //  }}
                  //  tertiary
                  //  target={internalLink ? false : true}
                  // />
                 
              //    <Button
              //      text={"Enquire Now"}
              //      onClick={() => {
              //        scrollPage("page-form")
               //     }}
               //     quaternary
               //   />
                 }
                </ButtonsContainer>
              )}

              {details?.length > 0 && <Hr />}
              {details && (
                <DetailsRow>
                  {details.map((col, key) => {
                    return (
                      <Col key={key}>
                        <p>{col.title}</p>
                        <span>{col.text}</span>
                      </Col>
                    )
                  })}
                </DetailsRow>
              )}
            </TextContainer>
            <ImageMob src={imageMobile} alt={altMob} />
          </InnerContainer>
        </StandardSection>
      </MainContainer>
      {breadcrumb && (
        <StandardSection>
          <Breadcrumb noPadding>
            {breadcrumb.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <Link
                    href={
                      key !== breadcrumb.length - 1
                        ? formatLink(item.uri)
                        : null
                    }
                  >
                    {item.title}
                  </Link>
                  <span>|</span>
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </StandardSection>
      )}

      <TextTwoColumnsSmall
        data={textColumnsSmall}
        sectionHeading={textColumnHeading}
        columnsCta={columnsCta}
        logo={logo}
        logoAlt={logoAlt}
      />
    </BackgroundColor>
  )
}

const Finance = styled.a`
  &,
  &:visited,
  &:link {
    display: inline-block;
    margin-left: 15px;
    color: #9099a3;

    &:hover {
      color: ${color.brand.green};
    }
  }
`

const BackgroundColor = styled.div`
  background-color: ${color.background.lightGrey};
`

const MainContainer = styled.div<ITreatmentHeader>`
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${color.brand.blue};
  background-image: url(${props => props.imageDesktop && props.imageDesktop});
  background-size: cover;
  background-position: center;
  margin-bottom: 7.3rem;
  @media (max-width: 1200px) {
    background-position: center;
  }

  @media (max-width: 767px) {
    background-image: none;
    flex-direction: column;
    height: auto;
    margin-bottom: 0;
    padding-top: 0;
    background-image: none;
  }
`
const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`

const ImageMob = styled.img`
  display: none;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
  }
`

const TextContainer = styled.div`
  padding: 5.2rem 5.5rem 5.1rem;
  width: 600px;
  background: ${color.brand.blue};
  z-index: 2;
  color: white;
  position: relative;
  bottom: -7.3rem;
  @media (max-width: 1199px) {
    left: 3%;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 44px 25px 40px;
    bottom: 0;
    left: 0;
  }
`

const TopText = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
`

const SectionHeading = styled.h1`
  margin: 2rem 0;
  font-family: headingFontLight;
  font-weight: 300;
  font-size: 4.8rem;
  line-height: 5.8rem;
  @media (max-width: 767px) {
    text-align: left;
    margin: 0 0 17px;
    font-size: 30px;
    line-height: 40px;
  }
`

const Body = styled.p`
  font-family: headingFontLight;
  font-weight: 300;
  margin-bottom: 7px;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const Price = styled.div`
  margin-bottom: 3.4rem;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`

const ButtonsContainer = styled.div`
 font-family: headingFontLight;
  font-weight: 300;  
  margin-bottom: 3rem;

  display: flex;
  & a {
    &:first-of-type {
      margin-right: 3.3rem;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & a {
      &:first-of-type {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
`

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (max-width: 767px) {
    padding: 15px 0 0;
  }
`

const Col = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.7);
  & p {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  & span {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: white;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
`
const Hr = styled.hr`
  border: 0;
  height: 1px;
  background: ${color.keyline.treatmentHeader};
`

const SubContent = styled.div`
  margin-top: 5rem;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`

export default TreatmentHeader

import React, { useState } from "react"
import styled from "styled-components"
import arrow from "../../white-co-images/arrow.svg"
import color from "../../style/color"
import ErrorMessage from "./ErrorMessage"

interface IOption {
  label: string
  value: string
}

interface IHeaderDropdown {
  options: Array<IOption>
  heading: string
  id: string
  placeholder: string
  hasError?: boolean
  color?: string
  errorTitle?: string
  toggleDropdownError?: any
  handleChange?: any
}

const Dropdown = ({
  heading,
  options,
  id,
  placeholder,
  hasError,
  errorTitle,
  color,
  toggleDropdownError,
  handleChange,
}: IHeaderDropdown) => {
  const [displayDropDown, toggleDisplayDropdown] = useState(false)
  const [label, setLabel] = useState(placeholder)

  const toggleDropdown = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const dropdown = document.getElementById(id)
      const headerArrow = document.getElementById(`${id}-arrow`)
      toggleDisplayDropdown(!displayDropDown)
      const element = document.getElementById(`${id}-links`)
      if (element && headerArrow) {
        if (dropdown) {
          if (!displayDropDown) {
            element.style.opacity = "1"
            // element.style.transition = "all .5s"
            element.style.visibility = "visible"

            headerArrow.style.transform = "rotate(180deg)"
          } else {
            element.style.opacity = "0"
            element.style.visibility = "hidden"

            headerArrow.style.transform = "rotate(0)"
          }
        }
      }
    }
  }

  const handleClickOutsideMobile = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const headerArrow = document.getElementById(`${id}-arrow`)
      toggleDisplayDropdown(false)
      const element = document.getElementById(`${id}-links`)
      if (element && headerArrow) {
        element.style.opacity = "0"
        element.style.visibility = "hidden"

        headerArrow.style.transform = "rotate(0)"
      }
    }
  }

  const handleSelect = el => {
    setLabel(el)
    if (handleChange) {
      handleChange(el)
    }
    toggleDisplayDropdown(true)
    toggleDropdown()
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const element = document.getElementById(`${id}-links`)
      const headerArrow = document.getElementById(`${id}-arrow`)
      if (element && headerArrow) {
        element.style.opacity = "0"
        element.style.visibility = "hidden"

        headerArrow.style.transform = "rotate(0)"
      }
    }
  }

  const handleMouseOverDesktop = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const headerArrow = document.getElementById(`${id}-arrow`)
      const element = document.getElementById(`${id}-links`)
      if (element && headerArrow) {
        element.style.opacity = "1"
        element.style.visibility = "visible"
        headerArrow.style.transform = "rotate(180deg)"
      }
    }
  }

  return (
    <Wrapper color={color}>
      <Anchor id={`${id}-container`} />

      <label htmlFor={id}>{heading}</label>
      <Container
        onMouseOut={handleClickOutsideMobile}
        onMouseOver={handleMouseOverDesktop}
        onFocus={handleMouseOverDesktop}
        onBlur={handleClickOutsideMobile}
        data-testid="header-dropdown"
        tabIndex={0}
        hasError={hasError}
        onClick={() => {
          if (!displayDropDown) {
            handleClickOutsideMobile()
          } else {
            handleMouseOverDesktop()
          }
          toggleDisplayDropdown(!displayDropDown)
        }}
      >
        <List hasError={hasError}>
          <li>
            <span id={id}>{label}</span>
            <InnerLinks id={`${id}-links`} displayDropDown={displayDropDown}>
              {options.map((option, key) => {
                const id: string =
                  Math.random()
                    .toString(36)
                    .substring(7) + key
                return (
                  <span
                    id={id}
                    tabIndex={0}
                    key={key}
                    onClick={() => {
                      handleSelect(option.label)
                      toggleDropdownError(false)
                    }}
                    onFocus={() => {
                      document
                        .getElementById(id)
                        .addEventListener("keypress", function(e) {
                          if (e.key === "Enter") {
                            handleSelect(option.label)
                            toggleDropdownError(false)
                          }
                        })
                    }}
                  >
                    {option.label}
                  </span>
                )
              })}
            </InnerLinks>
          </li>
          <ArrowContainer>
            <Triangle id={`${id}-arrow`} data-testid="header-dropdown-arrow" />
          </ArrowContainer>
        </List>
      </Container>
      {hasError && <ErrorMessage title={errorTitle} />}
    </Wrapper>
  )
}

export default Dropdown

interface IWrapper {
  color?: string
}
const Anchor = styled.div`
  position: absolute;
  top: -70px;
  left: 0;
`
const Wrapper = styled.div<IWrapper>`
  position: relative;
  margin-bottom: 2rem;
  & label {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 300;
    color: ${props => (props.color ? props.color : "white")};
    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
`
interface IList {
  hasError?: boolean
}
const Container = styled.div<IList>`
  position: relative;
  width: 100%;
  z-index: 3;
  cursor: pointer;
  margin-top: 8px;
  color: ${color.text.primary} !important;
  display: flex;
  background: white;
  ${props => props.hasError && `border: none`};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 6rem;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 3;
  &:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
  }
  @media (max-width: 767px) {
  }
  &:focus {
    outline: none;
  }
`

const List = styled.ul<IList>`
  list-style-type: none !important;
  margin: 0;
  padding: 0 2rem;
  height: 6rem;
  font-size: 16px;
  line-height: 22px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 6rem;
  position: relative;
  z-index: 3;
  border: 1px solid #ddd;
  & li {
    margin-bottom: 0 !important;
  }
  & li:before {
    content: none !important;
  }
  @media (max-width: 767px) {
  }
  ${props => props.hasError && `border: 1px solid ${color.support.red}`};
  ${Container}:hover & {
    border: 1px solid ${color.brand.green};
  }
  ${Container}:focus & {
    border: 1px solid ${color.brand.green};
  }
`

interface IInnerLink {
  displayDropDown?: boolean
}
const InnerLinks = styled.div<IInnerLink>`
  position: absolute; /* it doesn't cause the page to jump when visible */
  top: 5.8rem;
  left: 0;
  right: 0;
  background: white;
  transition: all 0.15s;
  opacity: 0;
  visibility: hidden;
  max-height: 180px;
  overflow-y: scroll;
  width: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 1;
  outline-left: 1px solid ${color.keyline.grey};
  border-top: 1px solid ${color.brand.green};
  outline: 1px solid ${color.keyline.grey};
  & span {
    &:focus {
      outline: none;
    }
    display: block;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    padding: 1rem 2rem 1.3rem;
    background: white;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    opacity: 1;
    color: ${color.text.primary} !important;
    @media (max-width: 767px) {
    }
    &:hover,
    &:focus {
      background-color: rgba(80, 227, 194, 0.25);
    }
    &:active {
      background-color: ${color.support.green};
      color: white !important;
    }
  }
  /* Chrome and Safari scroll bar */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${color.brand.green};
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 10px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.22, #50e3c2),
      color-stop(0.44, #50e3c2),
      color-stop(0.8, #00b092)
    );
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }

  /* Firefox scroll bar */
  scrollbar-width: thin;
  scrollbar-color: ${color.brand.green} #fff;

  ${Container}:hover & {
    opacity: 1;
  }
  &:focus {
    border: none;
  }
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Arrow = styled.img`
  height: 1rem;
  width: 1rem;
  transition: all 0.5s;

  ${Container}:hover & {
    transform: rotate(180deg);
  }
`
const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #0d0802;
`

import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import PriceRow from "./PricePageTable/PricePageTable"
import { formatLink } from "../../util/functions"

interface Price {
  title: string
  value: string
}
interface PriceComponentProps {
  array: Array<Price>
  heading: string
  smallText?: string
  href?: string
}

const PriceComponent = ({
  heading,
  array,
  smallText,
  href,
}: PriceComponentProps) => {
  return (
    <>
      {array && (
        <Container data-testid="price-component-container">
          <Text>
            <PriceRow
              heading={heading}
              array={array}
              noMarginBottom
              priceComponent
            />
            {smallText && (
              <SmallText data-testid="small-text-price" href={formatLink(href)}>
                {smallText}
              </SmallText>
            )}
          </Text>
        </Container>
      )}
    </>
  )
}

export default PriceComponent

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${color.brand.blue};
  color: white;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`
const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10rem;
  width: 100%;
  padding: 10rem 0 12rem;
  @media (max-width: 767px) {
    width: 100%;
    padding: 50px 0;
  }
`

const SmallText = styled.a`
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-top: 4rem;
  display: inline;
  padding: 0 25px;
  margin: 4rem auto 0;
  &,
  &:link,
  &:visited {
    color: white;
    font-weight: 300;

    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: center;
  }
`

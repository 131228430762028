import React from "react"
import CategoryHeader from "./CategoryHeader"
import { graphql } from "gatsby"

const TreatmentHeaderDrupal = ({ node }: any) => {
  const { sectionBody, sectionHeading, field_cta, breadcrumb, relationships } =
    node || {}
  const { dImg, mImg } = relationships
  const cta = field_cta || {}

  return (
    <CategoryHeader
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      imageMobile={mImg.localFile.publicURL}
      imageDesktop={dImg.localFile.publicURL}
      breadcrumb={breadcrumb}
      ctaText={cta.title}
      ctaUrl={cta.uri}
    />
  )
}

export default TreatmentHeaderDrupal

export const fragment = graphql`
  fragment CategoryHeader on paragraph__category_header {
    id
    sectionHeading: field_section_heading
    sectionBody: field_section_body
    field_cta {
      uri
      title
    }
    breadcrumb: field_breadcrumb {
      title
      uri
    }
    relationships {
      dImg: field_image_desktop {
        localFile {
          publicURL
        }
      }
      mImg: field_image_mobile {
        localFile {
          publicURL
        }
      }
    }
  }
`

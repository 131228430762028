import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export const useClinics = () => {
    const data: any = useStaticQuery(graphql`
    query practicesDropdownUse {
      nodeWhiteCoPractices {
        field_practice
      }
    }
  `)

  interface Option {
    label: string
    value: string
  }

  const preferedClinics: Option[] = data.nodeWhiteCoPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )
    return {preferedClinics}
}

import React, { useState } from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import Button from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import color from "../../style/color"
import VerticalPadding from "../../helper/VerticalPadding"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import { scrollTo } from "../../util/functions"

interface Card {
  heading: string
  body: string
  ctaText: string
  ctaUrl: string
  image: string
  imageAlt: string
  category: string
}

interface TreatmentListProps {
  heading?: string
  cards: Array<Card>
  subheading?: string
  meetTheTeam?: boolean
  sixRows?: boolean
}

const PatientReferrals = ({
  heading,
  cards,
  subheading,
  meetTheTeam,
  sixRows,
}: TreatmentListProps) => {
  const [showAllCards, toggleCards] = useState(false)
  const cardsLimit = sixRows ? 6 : 3
  const charLimit = 120
  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    } else {
      return text.substr(0, charLimit) + "..."
    }
  }

  const id: string = "id" + Math.random()

  return (
    <Outter>
      <VerticalPadding>
        <Container>
          <HeadingContainer>
            <Scroll id={id} />
            {heading && (
              <HContainer>
                <SmallHeading heading={heading} />
              </HContainer>
            )}
          </HeadingContainer>
          <StandardSection>
            <CardContainer>
              {cards.map((card: Card, i: number) => {
                return (
                  <Card data-testid="treatment-card" key={i}>
                    <img
                      data-testid="treatment-card-img"
                      src={card.image}
                      alt={card.imageAlt}
                    />
                    <Heading data-testid="treatment-card-heading">
                      {card.heading}
                    </Heading>

                    <p data-testid="treatment-card-body">{card.body}</p>

                    <BtnContainer
                      data-testid="treatment-card-cta"
                      fullWidth={card.ctaText.length > 20}
                    >
                      <Button href={card.ctaUrl} text={card.ctaText} />
                    </BtnContainer>
                  </Card>
                )
              })}
            </CardContainer>
          </StandardSection>
        </Container>
      </VerticalPadding>
    </Outter>
  )
}

const Heading = styled.p`
  font-weight: bold !important;
  font-size: 22px;
  line-height: 32px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 28px;
  }
`

const Scroll = styled.div`
  margin-top: -20px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -70px;
`

const Outter = styled.div`
  background: ${color.background.lightGrey};
`
interface IBtnContainer {
  fullWidth?: boolean
}
const BtnContainer = styled.div<IBtnContainer>`
  width: 200px;
  ${props => props.fullWidth && `width: 40%;`}

  @media (max-width: 1199px) {
    ${props => props.fullWidth && `width: 50%;`}
  }
  @media (max-width: 1023px) {
    width: auto;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`

const HContainer = styled.div`
  margin-bottom: 4.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  & p {
    font-weight: 400;
  }
  @media (max-width: 1023px) {
    padding: 0 25px;
    margin-bottom: 20px;
  }
`

const HeadingContainer = styled.div`
  position: relative;
`

const Card = styled.div`
  & img {
    width: 100%;
    max-height: 32rem;
    object-fit: cover;
    margin-bottom: 2.2rem;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  & > section {
    margin-bottom: 16px;
  }

  & h4 {
    font-weight: 300;
    font-family: headingFontLight;
    margin-bottom: 1.7rem;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  & h5 {
    font-weight: 300;
    font-family: headingFontLight;
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 6px;
    }
  }

  & p {
    margin-bottom: 19px;
    font-weight: 300;
    @media (max-width: 1023px) {
      margin-bottom: 5px;
    }
  }
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4.4rem;
  grid-row-gap: 7rem;
  margin: 0 auto;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`

const Container = styled.div`
  width: 100%;
`

export default PatientReferrals

import React from "react"
import styled from "styled-components"
import color from "../../style/color"

import { Breadcrumb, Link, Icon } from "../../shared/Breadcrumb/Breadcrumb"
import { get, formatLink } from "../../util/functions"

interface Link {
  uri: string
  title: string
}
interface FormHeadingGreyProps {
  heading?: string
  text?: string
  breadcrumb?: Array<Link>
  greyBackgroundBreadcrumb?: boolean
}

const FormHeadingGrey = ({
  heading,
  text,
  breadcrumb,
  greyBackgroundBreadcrumb,
}: FormHeadingGreyProps) => {
  return (
    <>
      <Text>
        <TextInner>
          <h1>{heading}</h1>
          <p>{text}</p>
        </TextInner>
      </Text>
      <Background greyBackgroundBreadcrumb={greyBackgroundBreadcrumb}>
        <BreadcrumbContainer>
          <Breadcrumb noPadding>
            {breadcrumb &&
              breadcrumb.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <Link
                      href={
                        key !== breadcrumb.length - 1
                          ? formatLink(item.uri)
                          : null
                      }
                    >
                      {item.title}
                    </Link>
                    <span>|</span>
                  </React.Fragment>
                )
              })}
          </Breadcrumb>
        </BreadcrumbContainer>
      </Background>
    </>
  )
}

export default FormHeadingGrey

const Text = styled.div`
  padding: 0 0 4.5rem;
  background: ${color.background.green10};
  width: 100%;
  & h1 {
    font-family: headingFontLight;
    font-weight: 300;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  & p {
    margin-top: 2rem;
    font-weight: 300;
    font-size: 2rem;
    line-height: 3rem;
  }
`
const TextInner = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding-top: 4.2rem;
  @media (max-width: 767px) {
    padding: 4.2rem 25px 0;
    width: 100%;
  }
`
const Background = styled.div`
  background: ${props =>
    props.greyBackgroundBreadcrumb && color.background.lightGrey};
`

const BreadcrumbContainer = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  @media (max-width: 767px) {
    display: none;
  }
`

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { scrollTo, get } from "../../util/functions"
import Btn from "../../shared/Button/Button"

const GallerySection = ({ heading, data }) => {
  const [showAllCards, toggleCards] = useState(false)
  const LoadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo("top-gallery-smile")
      }, 300)
    }
    toggleCards(!showAllCards)
  }
  return (
    <Container>
      <SectionHeading>{heading}</SectionHeading>

      <CardsContainer id={"top-gallery-smile"}>
        {data &&
          data.map((item, i) => {
            if (i < 4 || showAllCards) {
              return (
                <Card key={Math.random()}>
                  <a href={item.treatmentLink}>{item.treatmentName}</a>
                  <ImgContainer>
                    <ImageDescription marginRight>
                      <ImgMask>
                        <img src={item.before} alt={item.beforeAlt} />
                      </ImgMask>
                      <p>Before</p>
                    </ImageDescription>
                    <ImageDescription>
                      <ImgMask>
                        <img src={item.after} alt={item.afterAlt} />
                      </ImgMask>
                      <p>After</p>
                    </ImageDescription>
                  </ImgContainer>
                </Card>
              )
            }
          })}
      </CardsContainer>
      {data.length > 4 && (
        <BtnContainer data-testid="btn-container">
          <Btn
            onClick={LoadMore}
            text={!showAllCards ? "Load More" : "Show Less"}
          />
        </BtnContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 8rem;
`
const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 42.5% 42.5%;
  grid-row-gap: 6.6rem;
  justify-content: space-between;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`

const SectionHeading = styled.p`
  padding-bottom: 9px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.brand.green};
  text-transform: uppercase;
  text-align: center;
`
const Card = styled.div``

const ImgContainer = styled.div`
  margin-top: 18px;
  display: flex;
`
const ImgMask = styled.div`
  width: 100%;
  & img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    animation: smileGalleryImg 0.5s ease;
  }
`

const ImageDescription = styled.div`
  & p {
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 15px;
  }

  margin-right: ${props => props.marginRight && "10px"};
`
const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;

  @media (max-width: 767px) {
    margin-top: 50px;
    padding: 0 25px;
  }
`
export default GallerySection

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import { get, setItem } from "../../util/functions"
import DentistForm from "../Form/DentistReferralForm"
import { graphql, useStaticQuery } from "gatsby"
import ReferralPostCards from "../ReferralPostCards/ReferralPostCards"

interface Tab {
  icon: string
  field_value: string
}

interface IReferralTabs {
  sections: Array<Tab>
  sectionHeading: string
}

const ReferralTabs = ({
  sections,
  sectionHeading,
  practices,
}: IReferralTabs) => {
  const [option, setOption] = useState(null)

  const data: any = useStaticQuery(graphql`
    query practicesDropdown2 {
      nodeWhiteCoPractices {
        field_practice
      }
    }
  `)
  const preferedClinics: Option[] = data.nodeWhiteCoPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )

  return (
    <Background>
      <Container
        reducedPadding={option === 0 || option === 2}
        data-testid="referral-tabs-container"
      >
        <Heading>{sectionHeading}</Heading>
        <Tabs id="referral-tabs">
          {sections.map((item, i) => {
            const icon = get(
              ["relationships", "field_icon", "localFile", "publicURL"],
              item
            )

            const iconHovered = get(
              ["relationships", "field_icon_hovered", "localFile", "publicURL"],
              item
            )
            return (
              <Tab
                key={i}
                data-testid="referral-tab"
                onClick={() => {
                  setOption(i)
                }}
                isActive={i === option}
                onMouseEnter={() =>
                  (document.querySelector("#tab" + i).src = iconHovered)
                }
                onMouseLeave={() =>
                  (document.querySelector("#tab" + i).src =
                    i === option ? iconHovered : icon)
                }
              >
                <Icon
                  id={"tab" + i}
                  src={
                    i === option
                      ? get(
                          [
                            "relationships",
                            "field_icon_hovered",
                            "localFile",
                            "publicURL",
                          ],
                          item
                        )
                      : get(
                          [
                            "relationships",
                            "field_icon",
                            "localFile",
                            "publicURL",
                          ],
                          item
                        )
                  }
                  alt={item.field_icon.alt}
                />
                <Title isActive={i === option}> {item.field_value}</Title>
              </Tab>
            )
          })}
        </Tabs>
        <PaddingRight />
        {sections.map((item, i) => {
          return (
            <Section
              dangerouslySetInnerHTML={{
                __html: `${item.field_referral_tab_content.value}`,
              }}
              key={i}
              isActive={i === option}
            />
          )
        })}
      </Container>
      {option === 0 && <DentistForm preferedClinics={preferedClinics} />}
      {option === 2 && <ReferralPostCards practices={practices} />}
    </Background>
  )
}

const Background = styled.div`
  background: ${color.background.lightGrey};
`
const Heading = styled.h4`
  padding-bottom: 3rem;
  font-weight: 300;
  font-family: headingFontLight;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  ${props => (props.isActive ? "display: block;" : "display: none;")}
  padding-top: 4.3rem;
  width: 100%;
  max-width: 80rem;
  & h2,
  & h3,
  & h4,
  & h5 {
    margin-bottom: 1rem;
    font-weight: 300;
  }
  & p {
    font-size: 2rem;
    line-height: 3rem;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & a:link,
  & a:visited {
    font-size: 2rem !important;
    line-height: 3rem !important;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

const Container = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding: ${props => (props.reducedPadding ? "10rem 0 5rem" : "10rem 0")};
  @media (max-width: 767px) {
    padding-top: 30px;
  }
`
const Title = styled.div`
  color: ${props => props.isActive && "white"};
`

const Icon = styled.img`
  width: 5rem;
  height: 4rem;
`

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Tab = styled.div`
  width: 27%;
  font-weight: 300;
  position: relative;
  border: 1px solid #ddd;
  padding: 4rem 1rem 3rem;
  background: ${props => (props.isActive ? color.support.green : "white")};
  cursor: pointer;
  text-align: center;

  &:not(:last-of-type) {
    margin-right: 13px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 23px;
    width: 33%;
  }

  &:hover {
    background: ${color.support.radioBtnHover};
    color: white;
  }
`

const PaddingRight = styled.div`
  width: 25px;
  height: 10px;
  background: white;
  display: none;

  @media (max-width: 767px) {
    display: block;
    padding: 0 12px 0 0;
  }
`

export default ReferralTabs

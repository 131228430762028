import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Step from "./StepNewly"
import color from "../../../style/color"
import { throttled } from "../../../util/functions"

interface IStep {
  heading?: string
  text?: string
  period?: string
  week?: string
}

const defSteps: Array<IStep> = [
  {
    heading: "Insert implant",
    text:
      "After administering local anaesthesia, the implant will be carefully inserted into the bone. The gums will be sewn above the implant site and you will be left to heal for about two to three months depending on the site of the implant.",
    period: "After 2-3 months",
    week: "Week 1",
  },
  {
    heading: "Healing cap attached",
    text:
      "A test will be done on the implant to ensure osseointegration has occurred and the bone has bonded to the implant. At this stage, a small contoured healing cap will be attached to the implant to connect the implant to surface of the gums and help shape the gums and receive the final crown.",
    period: "After 2 weeks",
    week: "Week 10-15",
  },
  {
    heading: "Healing cap removed",
    text:
      "The final visit will be just as easy as the previous one. The healing cap will be removed, a disinfecting solution will  The final visit will be just as easy as the previous one. The healing cap will be removed, a disinfecting solution will  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
    period: "After 2 weeks",
    week: "Week 17",
  },
  {
    heading: "Impression coping attached",
    text:
      "The final visit will be just as easy as the previous one. The healing cap will be removed, a disinfecting solution will  The healing cap will be unscrewed an impression coping will be screwed in. An x-ray and putty impression will be taken to ensure proper fit. Photos will be taken of the teeth for the dental ceramist to create a life-like implant crown that no one will detect. The healing cap will be replaced and your next visit will be in two weeks for the final insertion of your crown.",
    period: "After 2 weeks",
    week: "Week 20",
  },
  {
    heading: "Crown attached",
    text:
      "The final visit will be just as easy as the previous one. The healing cap will be removed, a disinfecting solution will  The final visit will be just as easy as the previous one. The healing cap will be removed, a disinfecting solution will be applied to the implant to rid any bacteria, and the permanent custom abutment will be tried along with the implant crown. Dental x-rays will be taken to ensure a quality fit and that no micro-gaps are noted between the interface of the abutment and implant.",
    week: "Week 23",
  },
]

interface StepsProps {
  id: string
  steps: any
}
const StepsContainer = ({ id, steps = defSteps }: StepsProps) => {
  const [greenHeight, setGreenHeight] = useState(0)

  useEffect(() => {
    const calcPercent = (): void => {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        const container: HTMLElement | null = document.getElementById(id)

        if (container) {
          let offsetValue: number = 0
          let top: number = 0
          if (window.innerWidth <= 767) {
            offsetValue = 400
          } else if (window.innerWidth >= 768 && window.innerWidth <= 1199) {
            offsetValue = 550
          } else {
            offsetValue = 700
          }
          top = container.getBoundingClientRect().top - offsetValue
          const height: number = container.getBoundingClientRect().height

          if (top > 0) {
            setGreenHeight(0)
          } else if (top < -height) {
            setGreenHeight(90)
          } else {
            setGreenHeight(Math.round((Math.abs(top) / height) * 90))
          }
        }
      }
    }

    const eventListener = throttled(100, calcPercent)

    window.addEventListener("scroll", eventListener)

    return () => {
      window.removeEventListener("scroll", eventListener)
    }
  }, [])
  return (
    <Container>
      <InnerContainer id={id} data-testid="inner-container">
        <Green height={greenHeight} data-testid="green-line" />
        {steps.map((step: IStep, i: number) => {
          return (
            <Step
              heading={step.heading}
              text={step.text}
              period={step.period}
              key={i}
              stepNumber={i + 1}
              week={step.week}
            />
          )
        })}
        <Line />
      </InnerContainer>
    </Container>
  )
}

export default StepsContainer

const Container = styled.div`
  width: 50%;
  padding-left: 60px;
  @media (max-width: 1199px) {
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
  }
`
const InnerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
`

interface IGreen {
  height: number
}

const Green = styled.div<IGreen>`
  position: absolute;
  top: 0;
  left: 29px;
  width: 3px;
  height: ${props => props.height + "%"};
  background-color: ${color.brand.green};
  z-index: 2;
  transition: all 0.2s ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Line = styled.div`
  width: 1px;
  height: 90%;
  position: absolute;
  left: 30px;
  top: 0;
  background-color: ${color.keyline.grey};
`

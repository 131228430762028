import React, { useEffect } from "react"
import styled from "styled-components"
import { isInViewport, throttled } from "../../util/functions"
import StepsContainer from "./Steps/StepsContainerNewly"
import color from "../../style/color"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import LastStep from "./Steps/LastStep"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import tooth1 from "../../images/invisalign-animation/tooth_1.svg"
import tooth2 from "../../images/invisalign-animation/tooth_2.svg"
import tooth3 from "../../images/invisalign-animation/tooth_3.svg"
import tooth4 from "../../images/invisalign-animation/tooth_4.svg"
import tooth5 from "../../images/invisalign-animation/tooth_5.svg"
import tooth6 from "../../images/invisalign-animation/tooth_6.svg"
import brace from "../../images/invisalign-animation/brace.svg"
import gumLine from "../../images/invisalign-animation/gum.svg"

interface ToothAnimationMobileProps {
  topTextAnimated?: string
  sectionHeading?: string
  imageDesktop?: string
  imageMobile?: string
  stepNumber?: number
  heading?: string
  text?: string
  ctaUrl1?: string
  ctaUrl2?: string
  ctaText1?: string
  ctaText2?: string
}

const ToothAnimationMobile = ({
  sectionHeading,
  topTextAnimated,
  imageDesktop,
  imageMobile,
  stepNumber,
  heading,
  text,
  ctaUrl1,
  ctaUrl2,
  ctaText1,
  ctaText2,
  steps,
}: ToothAnimationMobileProps) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const tooth: HTMLElement | null = document.getElementById(`tooth-mob`)
      const brace: HTMLElement | null = document.getElementById(`brace-mob`)
      const tooth1: HTMLElement | null = document.getElementById("tooth1-mob")
      const tooth2: HTMLElement | null = document.getElementById("tooth2-mob")
      const tooth3: HTMLElement | null = document.getElementById("tooth3-mob")
      const tooth4: HTMLElement | null = document.getElementById("tooth4-mob")
      const tooth5: HTMLElement | null = document.getElementById("tooth5-mob")
      const tooth6: HTMLElement | null = document.getElementById("tooth6-mob")
      const container: HTMLElement | null = document.getElementById(
        `anim-container-mobile`
      )
      let distanceFromTopPage: number = 0
      if (container) {
        distanceFromTopPage = container.getBoundingClientRect().top
      }
      const animateElement = (
        elem: HTMLElement | null,
        percent: number,
        end: number,
        start: number
      ) => {
        let currentScroll =
          (document.documentElement.scrollTop || document.body.scrollTop) -
          distanceFromTopPage
        if (tooth) {
          if (isInViewport(tooth)) {
            let containerHeight = 0
            if (container) {
              containerHeight = container.getBoundingClientRect().height
            }
            if (elem && tooth) {
              if (container) {
                // animate the opacity
                if (elem === brace) {
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.opacity = "1"
                  } else {
                    elem.style.opacity = "0"
                  }
                }

                // aligner
                if (elem === brace) {
                  // const braceGoingUp: number = containerHeight * -0.12
                  const braceGoingUp: number = containerHeight * 0.15
                  const braceGoingDown: number = containerHeight * 0.49
                  const alignerUp: number = containerHeight * 0.58
                  const alignerOff: number = containerHeight * 0.73
                  // move aligner up
                  if (
                    currentScroll > braceGoingUp &&
                    currentScroll < braceGoingDown
                  ) {
                    elem.style.opacity = "1"
                    if (window.innerWidth < 768) {
                      elem.style.top = `${start + 4}px`
                    } else {
                      elem.style.top = `${start}px`
                    }
                  } else if (
                    currentScroll > braceGoingDown &&
                    currentScroll < alignerUp
                  ) {
                    elem.style.top = "600px"
                    elem.style.opacity = "0"
                  } else if (
                    currentScroll > alignerUp &&
                    currentScroll < alignerOff
                  ) {
                    elem.style.opacity = "1"
                    elem.style.top = `${start}px`
                  }
                  // if scrolling up, move the aligner down
                  else {
                    elem.style.top = "600px"
                    elem.style.opacity = "0"
                  }
                }
                // animate tooth1
                if (elem === tooth1 || elem === tooth6) {
                  elem.style.transform = `rotate(${start}deg)`
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.transform = `rotate(${end}deg)`
                  } else {
                    elem.style.transform = `rotate(${start}deg)`
                  }
                } else if (
                  elem === tooth2 ||
                  elem === tooth3 ||
                  elem === tooth5
                ) {
                  elem.style.left = `${start}%`
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.left = `${end}%`
                  } else {
                    elem.style.left = `${start}%`
                  }
                }

                // teeth 4
                else if (elem === tooth4) {
                  elem.style.left = `${start}%`
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.left = `${end}%`
                    elem.style.top = "52px"
                  } else {
                    elem.style.left = `${start}%`
                    elem.style.top = "55px"
                  }
                }
              }
            }
          }
        }
      }

      const eventListener = throttled(100, () => {
        animateElement(brace, 0.001, 100, 25)
        animateElement(tooth1, 0.4, 0, -9)

        animateElement(tooth2, 0.5, 15, 15)
        animateElement(tooth3, 0.56, 28, 30)
        animateElement(tooth4, 0.54, 48, 51)
        animateElement(tooth5, 0.52, 65, 66)

        animateElement(tooth6, 0.44, -9, 2)
        // animateElement(elementToAnimate, percentage of container when the animation should start, end value, start value)
      })

      window.addEventListener("scroll", eventListener)
      window.addEventListener("resize", eventListener)
      return () => {
        window.removeEventListener("scroll", eventListener)
        window.removeEventListener("resize", eventListener)
      }
    }
  }, [])

  return (
    <Outer>
      <SubHeadingContainer>
        <SmallHeading heading={topTextAnimated} marginBottom />
        {sectionHeading && <SectionHeading heading={sectionHeading} />}
      </SubHeadingContainer>

      <Container>
        <Animation id="anim-container-mobile">
          <StickyContainer>
            <StepsImages>
              <WhiteBackground />
              <GumLine id="tooth-mob" src={gumLine} data-testid="tooth" />
              <Brace id="brace-mob" src={brace} data-testid="screw" />
              <ToothContainer1>
                <Tooth1 id="tooth1-mob" src={tooth1} data-testid="tooth1" />
              </ToothContainer1>
              <ToothContainer2 id="tooth2-mob">
                <Tooth2 src={tooth2} data-testid="tooth2" />
              </ToothContainer2>
              <ToothContainer3 id="tooth3-mob">
                <Tooth3 src={tooth3} data-testid="tooth3" />
              </ToothContainer3>
              <ToothContainer6 id="tooth6-mob">
                <Tooth6 src={tooth6} data-testid="tooth6" />
              </ToothContainer6>
              <ToothContainer5 id="tooth5-mob">
                <Tooth5 src={tooth5} data-testid="tooth5" />
              </ToothContainer5>
              <ToothContainer4 id="tooth4-mob">
                <Tooth4 src={tooth4} data-testid="tooth4" />
              </ToothContainer4>
            </StepsImages>
          </StickyContainer>
          <StepsContainer id="stepsMobile" steps={steps} />
        </Animation>
      </Container>
      <LastStep
        greenBackground
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        stepNumber={stepNumber}
        heading={heading}
        text={text}
        ctaUrl1={ctaUrl1}
        ctaText1={ctaText1}
        ctaUrl2={ctaUrl2}
        ctaText2={ctaText2}
        id={"lastStepMob"}
      />
    </Outer>
  )
}

export default ToothAnimationMobile

const HeadingContainer = styled.div`
  padding: 0 25px;
`

const Outer = styled.div`
  display: none;
  @media (max-width: 1199px) {
    display: block;
    padding: 50px 0 0;
    height: auto;
  }
`
const Container = styled.div`
  width: 92%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: none;
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    padding: 0 25px;
    height: 100%;
  }
`
const SubHeadingContainer = styled.div`
  padding: 0 25px;
  position: relative;
  z-index: 555;
`
const Text = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${color.text.secondary};
  text-align: center;
  opacity: 0;
`

const Animation = styled.div`
  padding: 45px 0 50px;
  position: relative;
  @media (max-width: 1199px) {
    padding-bottom: 0;
  }
`
const StickyContainer = styled.div`
  left: 0;
  right: 0;
  position: sticky;
  top: 5.7rem;
  z-index: 10;
  height: 100px;
  height: 120px;
  width: 380px;
  max-width: 80vw;
  margin-bottom: 16rem;
  @media (max-width: 440px) {
    margin-bottom: 11rem;
  }
`
const WhiteBackground = styled.div`
  background: white;
  width: 100vw;
  height: 190%;
  position: absolute;
  top: -50%;
  left: -3%;
  @media (max-width: 767px) {
    left: -25px;
    top: -40%;
  }
  @media (max-width: 400px) {
    height: 160%;
    top: -40%;
    left: -25px;
  }
`

const StepsImages = styled.div`
  width: 100%;
`
const GumLine = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 99.18px;
  width: 372px;
  max-width: 78vw;
`

const Brace = styled.img`
  position: absolute;
  opacity: 0;
  left: -1px;
  top: 226px;
  right: 0%;
  transition: all ease 0.8s;
  z-index: 5;
  transform-style: preserve-3d;
  width: 374px;
  max-width: 78.1vw;
  min-height: 111px;
`
const ToothContainer1 = styled.div`
  width: 100%;
  height: 100%;
  max-width: 15%;
  position: relative;
  left: 2.5%;
  top: 40px;
  transition: all 0.8s;
`
const ToothContainer2 = styled(ToothContainer1)`
  max-width: 17%;
  left: 15%;
  top: 48px;
`
const ToothContainer3 = styled(ToothContainer1)`
  max-width: 22%;
  left: 30%;
  top: 51px;
`

const ToothContainer4 = styled(ToothContainer1)`
  max-width: 21%;
  left: 51%;
  top: 55px;
  transition: all 2s;
`
const ToothContainer5 = styled(ToothContainer1)`
  max-width: 18%;
  left: 66%;
  top: 50px;
`
const ToothContainer6 = styled(ToothContainer1)`
  max-width: 16%;
  left: 76%;
  top: 40px;
`
const Tooth1 = styled.img`
  transform: rotate(-9deg);
  transition: all 0.8s ease;
  position: absolute;
  width: 100%;
  top: 0;
`
const Tooth2 = styled(Tooth1)`
  transform: rotate(0);
  top: 0;
`
const Tooth3 = styled(Tooth1)`
  transform: rotate(0);
`

const Tooth4 = styled(Tooth1)`
  transform: rotate(0deg);
  transition: all 2s ease;
`
const Tooth5 = styled(Tooth1)`
  transform: rotate(0);
`

const Tooth6 = styled(Tooth1)`
  transform: rotate(2deg);
`

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import Button from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import color from "../../style/color"
import VerticalPadding from "../../helper/VerticalPadding"
import { scrollTo, isInViewport } from "../../util/functions"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"

interface Card {
  heading: string
  body: string
  ctaText: string
  ctaUrl: string
  image: string
  imageAlt: string
  category: string
  practiceName?: string
  role?: string
}

interface HomepageMeetTheTeamProps {
  heading?: string
  cards: Array<Card>
  subheading?: string
  meetTheTeam?: boolean
  cta?: any
}

const HomepageMeetTheTeam = ({
  heading,
  cards,
  subheading,
  cta,
}: HomepageMeetTheTeamProps) => {
  const [showAllCards, toggleCards] = useState(false)
  const charLimit = 120
  const formatText = text => {
    if (text.length < charLimit) {
      return text
    } else {
      return text.substr(0, charLimit) + "..."
    }
  }

  const id: string = "id" + Math.random()
  return (
    <Outter>
      <Container>
        <HeadingContainer>
          <Scroll id={id} />
          {heading && (
            <HContainer>
              <h3>{heading}</h3>
            </HContainer>
          )}
          {subheading && (
            <StandardSection>
              <Subheading>{subheading}</Subheading>
            </StandardSection>
          )}
        </HeadingContainer>
        <StandardSection>
          <CardContainer>
            {cards.map((card: Card, i: number) => {
              return (
                <Card data-testid="treatment-card" key={i}>
                  <Controller>
                    <Scene duration="100%">
                      <Timeline wrapper={<div className="parallax" />}>
                        <Tween
                          position="0"
                          from={{
                            scale: 1.05,
                          }}
                          to={{
                            scale: 1,
                          }}
                        >
                          <img
                            data-testid="treatment-card-img"
                            src={card.image}
                            alt={card.imageAlt}
                          />
                        </Tween>
                      </Timeline>
                    </Scene>
                  </Controller>
                  <Section>{card.role}</Section>
                  <PracticeName>{card.practiceName}</PracticeName>

                  <h5>{card.name}</h5>

                  <p data-testid="treatment-card-body">
                    {formatText(card.body)}
                  </p>

                  <BtnContainer data-testid="treatment-card-cta">
                    <Button href={card.ctaUrl} text={card.ctaText} />
                  </BtnContainer>
                </Card>
              )
            })}
          </CardContainer>
        </StandardSection>

        <BtnContainerLoadMore>
          <Button href={cta.uri} text={cta.title} />
        </BtnContainerLoadMore>
      </Container>
    </Outter>
  )
}

const Scroll = styled.div`
  margin-top: -20px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -70px;
`

const Outter = styled.div`
  padding-top: 10rem;
  @media (max-width: 767px) {
    padding-top: 50px;
  }
`

const BtnContainer = styled.div`
  width: 200px;
  @media (max-width: 1023px) {
    width: auto;
    display: flex;
    margin: 0 auto;
  }
`

const Subheading = styled.div`
  border-bottom: 1px solid ${color.brand.green};
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 70px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
`

const HContainer = styled.div`
  margin-bottom: 5.7rem;
  & h3 {
    font-family: headingFontLight;
    font-weight: 300;
    text-align: center;
  }
  @media (max-width: 1023px) {
    padding: 0 25px;
    margin-bottom: 40px;
  }
`

const HeadingContainer = styled.div`
  position: relative;
`

const Card = styled.div`
  overflow: hidden;
  & img {
    width: 100%;
    max-height: 32rem;
    height: 100%;
    object-fit: cover;
    margin-bottom: 2.2rem;
    transition: all 1s ease;
    backface-visibility: hidden;
    will-change: transform;
    @media (max-width: 1023px) {
    }
    @media (max-width: 767px) {
      margin-bottom: 15px;
      max-height: unset;
    }
  }

  & h4 {
    font-weight: 300;
    font-family: headingFontLight;
    margin-bottom: 1.7rem;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  & h5 {
    font-weight: 300;
    font-family: headingFontLight;
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 6px;
    }
  }

  & p {
    margin-bottom: 13px;
    font-weight: 300;
  }

  @media (max-width: 1023px) {
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 4.5rem;
  grid-row-gap: 7rem;
  margin: 0 auto;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`

const Container = styled.div`
  width: 100%;
`
const BtnContainerLoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  padding: 0 25px;

  @media (max-width: 767px) {
    margin-top: 60px;
    justify-content: flex-start;
  }
`

const Section = styled.section<ISection>`
  letter-spacing: 1px;
  text-transform: uppercase;
`

const PracticeName = styled.p``

export default HomepageMeetTheTeam

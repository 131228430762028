import React from "react"
import FinanceBanner from "./FinanceBanner"
import { graphql } from "gatsby"

const FinanceBannerDrupal = ({ node }: any) => {
  const { cta = {}, sectionBody, sectionHeading, smallText } = node || {}
  return (
    <FinanceBanner
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      smallText={smallText}
      ctaUrl={cta.uri}
      ctaText={cta.title}
    />
  )
}

export default FinanceBannerDrupal

export const fragment = graphql`
  fragment FinanceBanner on paragraph__finance_banner {
    id
    sectionBody: field_section_body
    sectionHeading: field_section_heading
    smallText: field_small_print
    cta: field_cta {
      title
      uri
    }
  }
`

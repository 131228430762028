import React, { useEffect } from "react"
import styled from "styled-components"
import { isInViewport, throttled } from "../../util/functions"
import gumLine from "../../images/invisalign-animation/gum.svg"
import brace from "../../images/invisalign-animation/brace.svg"
import tooth1 from "../../images/invisalign-animation/tooth_1.svg"
import tooth2 from "../../images/invisalign-animation/tooth_2.svg"
import tooth3 from "../../images/invisalign-animation/tooth_3.svg"
import tooth4 from "../../images/invisalign-animation/tooth_4.svg"
import tooth5 from "../../images/invisalign-animation/tooth_5.svg"
import tooth6 from "../../images/invisalign-animation/tooth_6.svg"
import StepsContainer from "./Steps/StepsContainerNewly"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import color from "../../style/color"
import { mainGridDesktop } from "../../style/typography"
import LastStep from "./Steps/LastStep"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"

interface IToothAnimation {
  sectionHeading?: string
  subHeading?: string
  imageDesktop?: string
  imageMobile?: string
  stepNumber?: number
  heading?: string
  text?: string
  ctaUrl1?: string
  ctaText1?: string
}

const ToothAnimation = ({
  sectionHeading,
  subHeading,
  imageDesktop,
  imageMobile,
  stepNumber,
  heading,
  text,
  ctaUrl1,
  ctaUrl2,
  ctaText1,
  ctaText2,
  steps,
}: IToothAnimation) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const tooth: HTMLElement | null = document.getElementById(`tooth`)
      const brace: HTMLElement | null = document.getElementById(`brace`)
      const tooth1: HTMLElement | null = document.getElementById("tooth1")
      const tooth2: HTMLElement | null = document.getElementById("tooth2")
      const tooth3: HTMLElement | null = document.getElementById("tooth3")
      const tooth4: HTMLElement | null = document.getElementById("tooth4")
      const tooth5: HTMLElement | null = document.getElementById("tooth5")
      const tooth6: HTMLElement | null = document.getElementById("tooth6")
      const container: HTMLElement | null = document.getElementById(
        `anim-container`
      )
      let distanceFromTopPage: number = 0
      if (container) {
        distanceFromTopPage = container.getBoundingClientRect().top
      }
      const animateElement = (
        elem: HTMLElement | null,
        percent: number,
        end: number,
        start: number
      ) => {
        let currentScroll =
          (document.documentElement.scrollTop || document.body.scrollTop) -
          distanceFromTopPage
        if (tooth) {
          if (isInViewport(tooth)) {
            let containerHeight = 0
            if (container) {
              containerHeight = container.getBoundingClientRect().height
            }
            if (elem && tooth) {
              if (container) {
                // animate the opacity
                if (elem === brace) {
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.opacity = "1"
                  } else {
                    elem.style.opacity = "0"
                  }
                }

                // aligner
                if (elem === brace) {
                  const braceGoingUp: number = containerHeight * -0.12
                  const braceGoingDown: number = containerHeight * 0.25
                  const alignerUp: number = containerHeight * 0.4
                  const alignerOff: number = containerHeight * 0.5

                  // move aligner up
                  if (
                    currentScroll > braceGoingUp &&
                    currentScroll < braceGoingDown
                  ) {
                    elem.style.opacity = "1"
                    elem.style.top = `${start}px`
                  } else if (
                    currentScroll > braceGoingDown &&
                    currentScroll < alignerUp
                  ) {
                    elem.style.top = "600px"
                    elem.style.opacity = "0"
                  } else if (
                    currentScroll > alignerUp &&
                    currentScroll < alignerOff
                  ) {
                    elem.style.opacity = "1"
                    elem.style.top = `${start}px`
                  }
                  // if scrolling up, move the aligner down
                  else {
                    elem.style.top = "600px"
                    elem.style.opacity = "0"
                  }
                }
                // animate tooth1
                if (elem === tooth1 || elem === tooth6) {
                  elem.style.transform = `rotate(${start}deg)`
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.transform = `rotate(${end}deg)`
                  } else {
                    elem.style.transform = `rotate(${start}deg)`
                  }
                } else if (
                  elem === tooth2 ||
                  elem === tooth3 ||
                  elem === tooth5
                ) {
                  elem.style.left = `${start}px`
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.left = `${end}px`
                  } else {
                    elem.style.left = `${start}px`
                  }
                }

                // teeth 4
                else if (elem === tooth4) {
                  elem.style.left = `${start}px`
                  if (currentScroll - containerHeight * percent > start) {
                    elem.style.left = `${end}px`
                    elem.style.top = "246px"
                  } else {
                    elem.style.left = `${start}px`
                    elem.style.top = "250px"
                  }
                }
              }
            }
          }
        }
      }

      const eventListener = throttled(100, () => {
        animateElement(brace, 0.001, 100, 216)
        animateElement(tooth1, 0.2, 0, -9)
        animateElement(tooth2, 0.3, 64, 60)
        animateElement(tooth3, 0.38, 114, 116)
        animateElement(tooth4, 0.4, 188, 197)
        animateElement(tooth5, 0.36, 254, 262)
        animateElement(tooth6, 0.24, -9, 4)
        // animateElement(elementToAnimate, percentage of container when the animation should start, end value, start value)
      })

      window.addEventListener("scroll", eventListener)
      window.addEventListener("resize", eventListener)

      return () => {
        window.removeEventListener("scroll", eventListener)
        window.removeEventListener("resize", eventListener)
      }
    }
  }, [])

  return (
    <Outer>
      {subHeading && (
        <SubHeadingContainer>
          <SmallHeading heading={subHeading} marginBottom />
        </SubHeadingContainer>
      )}
      {sectionHeading && <SectionHeading heading={sectionHeading} />}

      <Container id="anim-container">
        <Animation>
          <StickyContainer>
            <Brace id="brace" src={brace} data-testid="screw" />
            <GumLine id="tooth" src={gumLine} data-testid="tooth" />
            <Tooth1 id="tooth1" src={tooth1} data-testid="tooth1" />
            <Tooth2 id="tooth2" src={tooth2} data-testid="tooth2" />
            <Tooth3 id="tooth3" src={tooth3} data-testid="tooth3" />
            <Tooth6 id="tooth6" src={tooth6} data-testid="tooth6" />
            <Tooth5 id="tooth5" src={tooth5} data-testid="tooth5" />
            <Tooth4 id="tooth4" src={tooth4} data-testid="tooth4" />
          </StickyContainer>
        </Animation>
        <StepsContainer id="stepsDesktop" steps={steps} />
      </Container>
      <LastStep
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        stepNumber={stepNumber}
        heading={heading}
        text={text}
        ctaUrl1={ctaUrl1}
        ctaText1={ctaText1}
        id={"lastStepDesktop"}
      />
    </Outer>
  )
}

export default ToothAnimation
const Outer = styled.div`
  padding: 10rem 0 0;
  @media (max-width: 1199px) {
    display: none;
  }
`
const SubHeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const Container = styled.div`
  display: flex;
  width: 92%;
  max-width: ${mainGridDesktop};
  margin: 53px auto 0;
  height: 100%;
  @media (max-width: 1199px) {
    display: none;
  }
`

const Animation = styled.div`
  width: 50%;
  margin-bottom: 180px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`
const StickyContainer = styled.div`
  position: sticky;
  top: 30vh;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 300px;
  width: 488px;
  margin-top: -100px;
`
const GumLine = styled.img`
  height: 99.18px;
  width: 375.95px;
  left: 5px;
  position: relative;
`

const Brace = styled.img`
  opacity: 0;
  position: absolute;
  left: 0%;
  top: 600px;
  right: 0%;
  bottom: 0%;
  transition: all ease 0.8s;
  z-index: 5;
  transform-style: preserve-3d;
  height: 145px;
  width: 384.1px;
`

const Tooth1 = styled.img`
  position: absolute;
  left: 17px;
  top: 240px;
  width: 56.97px;
  transform: rotate(-9deg);
  transition: all 0.8s ease;
`
const Tooth2 = styled(Tooth1)`
  transform: rotate(0);
  left: 60px;
  width: 63.58px;
  top: 244px;
`
const Tooth3 = styled(Tooth1)`
  transform: rotate(0);
  left: 116px;
  width: 82.53px;
  top: 246px;
`

const Tooth4 = styled(Tooth1)`
  transform: rotate(0deg);
  left: 197px;
  width: 78.55px;
  top: 250px;
  transition: all 2s ease;
`
const Tooth5 = styled(Tooth1)`
  transform: rotate(0);
  left: 262px;
  width: 68px;
  top: 247px;
`

const Tooth6 = styled(Tooth1)`
  transform: rotate(5deg);
  left: 305px;
  width: 61.02px;
  top: 240px;
`

import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import VerticalPadding from "../../helper/VerticalPadding"
import getOpeningTimes from "./ReferralTimesFunction"
import moment from "moment"
import Pill from "../../shared/Pill/Pill"

interface Practice {
  heading: string
  title: string
  phone?: string
  comingSoon: boolean
  body: string
  ctaUrl: string
  ctaText: string
}
interface OurPracticesProps {
  practices: Array<Practice>
}

const ReferralPostCards = ({ practices }: OurPracticesProps) => {
  const renderOpeningTimes = () => {
    const { isOpen, text } = getOpeningTimes(moment())

    return (
      <OpeningTimes>
        <Pill>{isOpen ? "OPEN NOW" : "CLOSED"}</Pill> <p>{text}</p>
      </OpeningTimes>
    )
  }

  return (
    <Background>
      <MainContainer>
        <Container>
          <ColContainer>
            {practices &&
              practices.map((practice, key) => {
                return (
                  <Col key={key} marginRight={(key + 1) % 3 !== 0}>
                    <Title data-testid="practice-heading">
                      {practice.heading}
                    </Title>
                    <Name data-testid="practice-title">{practice.title}</Name>
                    {practice.phone && (
                      <Phone
                        href={`tel:${practice.phone}`}
                        data-testid="practice-phone"
                      >
                        {practice.phone}
                      </Phone>
                    )}
                    {practice.comingSoon && (
                      <OpeningTimes data-testid="pill-text-coming-soon">
                        <Pill>COMING SOON</Pill>
                      </OpeningTimes>
                    )}
                    <Value
                      data-testid="practice-body"
                      dangerouslySetInnerHTML={{
                        __html: `${practice.body}`,
                      }}
                    />
                  </Col>
                )
              })}
          </ColContainer>
        </Container>
      </MainContainer>
    </Background>
  )
}

const Background = styled.div`
  background: ${color.background.lightGrey};
  padding-bottom: 10rem;
`

const MainContainer = styled.section`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px;
  }
`

const Phone = styled.a`
  &,
  &:visited,
  &:link {
    font-weight: 300;
    font-size: 20px;
    line-height: 3rem;
    cursor: pointer;
    display: inline-block;
  }
`

const OpeningTimes = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  & p {
    margin-left: 7px;
    font-weight: 300;
    font-size: 14px;
  }
`

export default ReferralPostCards

const Container = styled.div`
  width: 100%;
`

const ColContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 995px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`
interface ICol {
  marginRight?: boolean
}
const Col = styled.div<ICol>`
  width: 30%;
  margin: 6.3rem 5% 0 0;
  margin-right: ${props => (props.marginRight ? "5%" : "0px")};
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type):after {
      content: "";
      width: 150px;
      height: 1px;
      margin: 0;
      background-color: ${color.brand.green};
      margin-top: 51px;
    }

    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }
`

const Title = styled.h3`
  font-family: headingFontLight;
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-weight: 300;

  @media (max-width: 1023px) {
    font-size: 26px;
    line-height: 36px;
  }
`

const Name = styled(Title)`
  font-family: Futura;
  font-weight: 400;
  margin-bottom: 16px;
`

const Value = styled.div`
  margin-top: 10px;

  *,
  & * {
    font-weight: 300;

    @media (max-width: 767px) {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
  }
  & a {
    font-size: inherit;
  }
`

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import closeGreen from "../../white-co-images/close-green.svg"
import closeRed from "../../white-co-images/close-red.svg"
import upload from "../../white-co-images/upload.svg"

interface IUploadInput {
  bold?: string
  heading?: string
  label?: string
  id: string
  uploadLimit?: number
  fileList: Array<any>
  editFileArray: any
  editFileList(any: any): any
  imageList: Array<any>
}
const UploadInput = ({
  id,
  heading,
  uploadLimit = 99,
  fileList,
  editFileList,
  editFileArray,
  imageList,
}: IUploadInput) => {
  const getFileNames = (fileNames, files) => {
    let newList: Array<string> = []

    for (let i = 0; i < fileList.length; i++) {
      if (!fileList[i].error) {
        newList.push(fileList[i])
      }
    }
    let list = [...newList, ...fileNames]
    let allFiles = [...imageList, ...files]
    editFileList(list.slice(0, uploadLimit))
    editFileArray(allFiles)
  }

  const uploadFile = e => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const fileList = Array.from(document.getElementById(id).files)
      console.log(fileList)
      const imageFiles = []

      let filenameList = []
      for (let i = 0; i < fileList.length; i++) {
        if (checkFileExtension(fileList[i].name)) {
          filenameList.push({ name: fileList[i].name, error: false })
          imageFiles.push(fileList[i])
        } else {
          filenameList.push({ name: fileList[i].name, error: true })
        }
      }

      getFileNames(filenameList, imageFiles)
    }
  }

  const removeUpload = file => {
    let index: number = fileList.indexOf(file)
    console.log(index)

    let list: Array<string> = [...fileList]
    list.splice(index, 1)

    let uploadList: Array<string> = [...imageList]
    uploadList.splice(index, 1)

    // console.log(uploadList)

    editFileList(list)
    editFileArray(uploadList)
  }

  const handleUploadMore = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const input = document.getElementById(id)
      input.click()
    }
  }

  const checkFileExtension = (fileName: string): boolean => {
    const arr: Array<string> = fileName.split(".")

    const fileExtension: string = arr[arr.length - 1]

    const supportedExtensions: Array<string> = ["jpg", "png", "gif"]

    if (supportedExtensions.indexOf(fileExtension) !== -1) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <Container>
        <InputContainer>
          <HeadingSmall>{heading}</HeadingSmall>
          <Upload src={upload} />
          <Drag>Drag & drop your files here</Drag>
          <div>
            <Input
              type="file"
              accept="image/jpg,image/gif,image/png"
              id={id}
              multiple
              onChange={uploadFile}
            />
          </div>
        </InputContainer>
        <Button onClick={handleUploadMore} tabIndex="0">
          Or browse from your computer
        </Button>

        {fileList.length >= 0 &&
          fileList.map((file, i) => {
            return (
              <Flex key={i} marginTop={i === 0}>
                <div>
                  <Text>{file.name}</Text>
                  <Remove
                    src={file.error ? closeRed : closeGreen}
                    onClick={() => removeUpload(file)}
                  />
                </div>
                <Line hasError={file.error} id={`line${i}`} />
                {file.error && (
                  <ErrorText>
                    The file type is not supported. Please try again
                  </ErrorText>
                )}
              </Flex>
            )
          })}

        {fileList.length > 5 && (
          <UploadMore tabIndex="0" onClick={handleUploadMore}>
            Upload more documents
          </UploadMore>
        )}
      </Container>
    </>
  )
}

export default UploadInput

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 995px;
  margin: 0 auto;
  position: relative;
  & label {
    margin-bottom: 8px;
  }
  @media (max-width: 767px) {
  }
`
const InputContainer = styled.div`
  position: relative;
`

const Input = styled.input`
  position: absolute;
  top: 18%;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  outline: none;
  cursor: pointer;
  background: grey;
`
const Button = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
  padding: 0 2rem;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer !important;
  border: 1px solid ${color.brand.black};
  background: linear-gradient(
    to right,
    ${color.brand.green} 50%,
    transparent 50%
  );
  transition: background-color 200ms ease, border-color 200ms ease,
    box-shadow 200ms ease;
  background-size: 200% 110%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
  ${props => props.width && `width ${props.width}px`};

  ::placeholder {
    color: #aaa;
    font-weight: 300;

    ${props =>
      props.hasError &&
      `
    color: ${color.support.red};
    `}
  }

  &:hover,
  &:focus {
    background-color: ${color.brand.green};
    background-position: left bottom;
    border: 1px solid ${color.brand.green};
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }

  @media only screen and (max-width: 850px) {
    font-size: 16px;
    line-height: 26px;
    height: 50px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 22px;
    padding: 0 10px;
  }
  @media (max-width: 340px) {
    font-size: 11px;
  }
`
const Label = styled.label`
  margin-top: 6rem;
`
const HeadingSmall = styled.p`
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: 500;
  margin-bottom: 5.8rem;
`
const Drag = styled.p`
  margin-bottom: 3.7rem;
  font-size: 2rem;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Text = styled.p`
  font-size: 1.5rem;
  line-height: 2.9rem;
  font-weight: 300;
`
interface IFlex {
  marginTop?: boolean
}
const Flex = styled.div<IFlex>`
  width: 100%;
  max-width: 624px;
  margin: 0 auto;
  margin-top: ${props => (props.marginTop ? "6rem" : "3rem")};
  position: relative;

  & div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
interface ILine {
  hasError?: boolean
}
const Line = styled.div<ILine>`
  width: 100%;
  background: ${color.support.green};
  background: ${props =>
    props.hasError ? color.support.red : color.support.green};
  height: 5px;
  border-radius: 100px;
  margin-top: 8px;
`

const Remove = styled.img`
  cursor: pointer;
  height: 12px;
`

const Upload = styled.img`
  height: 6rem;
  margin-bottom: 1rem;
`
const UploadMore = styled.p`
  color: ${color.brand.green};
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  max-width: 624px;
  margin: 17px auto 0;
  transition: all 0.2s;
  &:hover,
  &:focus {
    color: ${color.brand.blue};
  }
  &:focus {
    outline: none;
  }
`
const ErrorText = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  color: ${color.support.red};
  text-align: left;
  margin-top: 5px;
`

import React from "react"
import { graphql } from "gatsby"
import { get } from "../../util/functions"
import TreatmentExplaination from "./TreatmentExplanation"

const TreatmentExplainationDrupal = ({ node }: any) => {
  const {
    heading,
    topText,
    flip,
    text,
    relationships,
    altMobile,
    altDesktop,
    cta,
    greenCta,
  } = node || {}

  const { dImg, mImg } = relationships

  const imageDesktop = get(["localFile", "publicURL"], dImg, "")
  const imageMobile = get(["localFile", "publicURL"], mImg, "")

  return (
    <TreatmentExplaination
      heading={heading}
      text={text}
      imageDesktop={imageDesktop}
      imageMobile={imageMobile}
      topText={topText}
      altDesktop={altDesktop.alt}
      altMobile={altMobile.alt}
      flip={flip}
      cta={cta}
      greenCta={greenCta}
      sources={[
        {
          ...dImg.localFile.childImageSharp.fluid,
          media: `(min-width: 767px)`,
        },
        {
          ...mImg.localFile.childImageSharp.fluid,
          media: `(max-width: 767px)`,
        },
      ]}
    />
  )
}

export default TreatmentExplainationDrupal

export const fragment = graphql`
  fragment TreatmentExplanation on paragraph__treatment_explanation {
    id
    greenCta: field_green_ctas
    flip: field_flip_image_and_text
    topText: field_top_text
    heading: field_section_heading
    text: field_text {
      value
    }
    cta: field_cta {
      uri
      title
    }
    relationships {
      dImg: field_image_desktop {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 731, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      mImg: field_image_mobile {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 767, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    altDesktop: field_image_desktop {
      alt
    }
    altMobile: field_image_mobile {
      alt
    }
  }
`

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import Btn from "../../shared/Button/Button"
import { scrollTo, get } from "../../util/functions"
import MinimalSection from "../../helper/MinimalSection"
import poster from "../../white-co-images/halfImage.jpeg"

const AllPatientStories = ({ sections }) => {
  const [category, setCategory] = useState(0)
  const [showAllCards, toggleCards] = useState(false)
  const LoadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo("tabs")
      }, 300)
    }
    toggleCards(!showAllCards)
  }

  const [cardCount, setCardCount] = useState(5)

  const setCardLimit = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 768) {
        setCardCount(3)
      } else {
        setCardCount(5)
      }
    }
  }

  useEffect(() => {
    setCardLimit()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", setCardLimit)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", setCardLimit)
      }
    }
  }, [])

  if (!sections) {
    return <></>
  }

  return (
    <MinimalSection noPaddingMob>
      <Container data-testid="all-patients-stories">
        <Tabs id="tabs">
          {sections.map((ex, i) => {
            return (
              <Tab
                key={i}
                data-testid="tab-patients-stories"
                onClick={() => {
                  setCategory(i)
                  toggleCards(false)
                }}
                isActive={i === category}
              >
                {ex.field_section_heading}
              </Tab>
            )
          })}
          <PaddingRight />
        </Tabs>

        <SectionContainer>
          {sections[category].relationships.field_patient_story_card.map(
            (section, i) => {
              if (i < cardCount || showAllCards) {
                return (
                  <Section
                    key={i}
                    centerContent={section?.relationships?.field_video}
                  >
                    <TextContainer
                      noPaddingTop={section?.relationships?.field_video}
                    >
                      <p>{sections[category].field_section_heading}</p>
                      <h5>{section.field_section_heading}</h5>
                      <p>{section.field_section_body}</p>
                      <p>{section.field_name}</p>
                    </TextContainer>

                    {section?.relationships?.field_video ? (
                      <Video
                        controls
                        src={
                          section.relationships.field_video.localFile.publicURL
                        }
                        poster={
                          section.relationships.field_video_poster.localFile
                            .publicURL
                        }
                      ></Video>
                    ) : (
                      <img
                        src={get(
                          [
                            "relationships",
                            "field_image_desktop",
                            "localFile",
                            "publicURL",
                          ],
                          section,
                          ""
                        )}
                        alt={section.field_image_desktop.alt}
                      />
                    )}
                  </Section>
                )
              }
            }
          )}
        </SectionContainer>
        {sections[category].relationships.field_patient_story_card.length >
          cardCount && (
          <BtnContainer data-testid="btn-container">
            <Btn
              onClick={LoadMore}
              text={!showAllCards ? "Load More" : "Show Less"}
            />
          </BtnContainer>
        )}
      </Container>
    </MinimalSection>
  )
}

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 0 25px;
  }
`
const VideoContainer = styled.div`
  position: relative;
  width: 443px;
`

const Video = styled.video`
  max-width: 443px;
  outline: none;
  width: 100%;
  height: 300px;
  object-fit: cover;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`

const SectionContainer = styled.div``

const Section = styled.div`
  display: flex;
  margin-bottom: 50px;
  align-items: ${props => props.centerContent && "center"};
  &:nth-of-type(2n) {
    flex-direction: row-reverse;

    & > div {
      padding-right: 0;
      padding-left: 8rem;
    }
  }

  & img {
    height: 430px;
    max-height: 430px;
    object-fit: cover;

    @media (max-width: 1023px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      height: 350px;
      width: 100%;
    }
    @media (max-width: 450px) {
      height: 280px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0 25px;
    & > div {
      padding: 0;
    }

    &:nth-of-type(2n) {
      flex-direction: column-reverse;
      & > div {
        padding: 0;
      }
    }
  }
`
const TextContainer = styled.div`
  padding-top: 6rem;
  padding-top: ${props => props.noPaddingTop && "0"};
  padding-right: 8rem;
  width: 60%;

  @media (max-width: 767px) {
    width: 100%;
  }
  & p:nth-of-type(1) {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 2.4rem;

    @media (max-width: 767px) {
      margin-top: 17px;
    }
  }

  & h5 {
    margin-bottom: 2rem;
    font-weight: 500;
  }

  & p:nth-of-type(2) {
    margin-bottom: 1.7rem;
  }
`

const Container = styled.div`
  margin-top: 8.9rem;
  margin-bottom: 10rem;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 30px;
  }
`

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5rem;

  @media (max-width: 767px) {
    overflow-x: scroll;
    margin: 0 0 40px 25px;
  }
`

const Tab = styled.p`
  width: 200px;
  padding-bottom: 23px;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  &::after {
    position: absolute;
    content: " ";
    height: 5px;
    width: 0%;
    bottom: -1px;
    left: -1px;
    background: ${color.brand.green};
    transition: ease-out 0.3s;
    margin: 0 auto;
    ${props => props.isActive && `width: 100%;`}
  }

  cursor: pointer;
  text-align: center;
  margin-right: 13px;

  @media (max-width: 1023px) {
    width: auto;
    flex-shrink: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 5px;

    font-size: 13px;
    line-height: 23px;
  }
`

const PaddingRight = styled.div`
  width: 25px;
  height: 10px;
  background: white;
  display: none;

  @media (max-width: 767px) {
    display: block;
    padding: 0 12px 0 0;
  }
`

export default AllPatientStories

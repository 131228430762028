import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import FullWidthContainer from "../../helper/FullWidthContainer"
import StandardSection from "../../helper/StandardSection"

interface Col {
  title: string
  text: string
}
interface BenefitsProps {
  data: Array<Col>
  heading: string
  image: string
  alt: string
  mobImage: string
  mobAlt: string
  subheading: string
}
const Benefits = ({
  heading,
  data,
  subheading,
  image,
  mobImage,
}: BenefitsProps) => {
  return (
    <MainContainer>
      <SectionImageMob mobile={mobImage} />
      <StandardSection>
        <InnerContainer>
          <SectionImage desktop={image} />
          <RightSide>
            <Inner>
              {subheading && <Subheading>{subheading}</Subheading>}
              {heading && (
                <HeadingContainer data-testid="benefits-heading-test">
                  <SectionHeading leftAlign heading={heading} />{" "}
                </HeadingContainer>
              )}

              <Grid centerColumn={data.length <= 3}>
                {data.map((column, key) => {
                  return (
                    <Column key={key}>
                      <TextContainer>
                        {column.title && (
                          <div data-testid="benefits-headings">
                            <Heading>{column.title}</Heading>
                          </div>
                        )}

                        {column.text && (
                          <div data-testid="benefits-card-text-content">
                            <Body>{column.text}</Body>
                          </div>
                        )}
                      </TextContainer>
                    </Column>
                  )
                })}
              </Grid>
            </Inner>
          </RightSide>
        </InnerContainer>
      </StandardSection>
    </MainContainer>
  )
}

export default Benefits

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const RightSide = styled.div`
  flex: 1;
  padding: 9.5rem 0 7.5rem 8.2rem;
  align-self: flex-start;

  @media (max-width: 1199px) {
    padding-top: 5rem;
    padding-left: 5rem;
  }

  @media (max-width: 1023px) {
    padding: 0;
    padding-bottom: 50px;
  }
`

const SectionImageMob = styled.div`
  display: none;
  background-image: url(${props => props.mobile});
  background-size: cover;
  background-position: center center;
  flex: 1;
  height: 80rem;
  width: 100%;

  @media (max-width: 1023px) {
    flex: auto;
    height: 380px;
    display: block;
  }
`

const SectionImage = styled.div`
  background-image: url(${props => props.desktop});
  background-size: cover;
  background-position: center center;
  flex: 1;
  height: 90rem;
  width: 100%;
  position: relative;

  &:before {
    position: absolute;
    left: -36%;
    top: 0;
    content: "";
    height: 100%;
    width: 138%;
    background-image: url(${props => props.desktop});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media (max-width: 1199px) {
    height: 75rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Subheading = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-weight: 400 !important;

  @media (max-width: 1023px) {
    margin-top: 51px;
    margin-left: 0;
  }
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1023px) {
  }
`
const Inner = styled.div`
  width: 100%;
`
const HeadingContainer = styled.div`
  width: 100%;
  max-width: 50rem;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`

interface GridProps {
  centerColumn?: boolean
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10rem;
  grid-row-gap: 4rem;
  margin-top: 6rem;
  @media (max-width: 1199px) {
    grid-column-gap: 5rem;
    grid-row-gap: 2rem;
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    margin-top: 27px;
  }
`

const Column = styled.div`
  width: 100%;
  margin: 0 2.6% 1% 0;
  &:nth-of-type(4n) {
    margin-right: 0%;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 0 25px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const TextContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    padding: 0;
    & div {
      font-size: 14px;
      line-height: 24px;
    }
  }
`

const Heading = styled.h4`
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 10px;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
  }
`

const Body = styled.div`
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;
`

import React, { useEffect } from "react"
import SpecificTreatmentForm from "./SpecificTreatmentForm"
import EmergencyTreatmentForm from "./EmergencyTreatmentForm"
import GeneralEnquiry from "./GeneralEnquiryForm"
import PatientReferral from "./PatientReferralForm"
import FreeConsultation from "./FreeConsultationForm"
import DentistReferral from "./DentistReferralForm"
import SendASmile from "./SendASmileForm"
import Invisalign from "./Invisalign/InvisalignForm"
import { graphql, useStaticQuery } from "gatsby"
import InfluencerEnquiryForm from "./InfluencerEnquiryForm"

const getPageForm = ({ form }) => {
  const data: any = useStaticQuery(graphql`
    query practicesDropdown {
      nodeWhiteCoPractices {
        field_practice
      }
    }
  `)

  interface Option {
    label: string
    value: string
  }

  const preferedClinics: Option[] = data.nodeWhiteCoPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )

  if (!form) return <></>

  switch (form) {
    // case "Influencer":
    //   return <InfluencerEnquiryForm preferedClinics={preferedClinics} />
    case "General Enquiry":
      return <GeneralEnquiry preferedClinics={preferedClinics} />
    case "Patient Referral Form":
      return <PatientReferral preferedClinics={preferedClinics} />
    case "Book A Free Consultation":
      return <FreeConsultation preferedClinics={preferedClinics} />
    case "Dentist Referral Form":
      return <DentistReferral preferedClinics={preferedClinics} />
    case "Send A Smile":
      return <SendASmile preferedClinics={preferedClinics} />
    case "Invisalign Assessment":
      return <Invisalign preferedClinics={preferedClinics} />
    case "Children's Treatments":
      return (
        <SpecificTreatmentForm
          treatmentName={"Children's"}
          preferedClinics={preferedClinics}
        />
      )
    case "Dental Hygiene Form":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental hygiene treatments"}
          pageTitle={
            "Please fill in the form to find out more about dental hygiene treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Dental Examination Form":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental examinations"}
          pageTitle={
            "Please fill in the form to find out more about dental examinations at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Invisalign":
      return (
        <SpecificTreatmentForm
          treatmentName={"Invisalign®"}
          pageTitle={
            "Please fill in the form to find out more about Invisalign® treatment at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Dental Implants":
      return (
        <SpecificTreatmentForm
          treatmentName={"dental implants treatments"}
          pageTitle={
            "Please fill in the form to find out more about dental implants treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Root Canal Treatments":
      return (
        <SpecificTreatmentForm
          treatmentName={"root canal treatments"}
          pageTitle={
            "Please fill in the form to find out more about root canal treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Emergency Treatment":
      return (
        <EmergencyTreatmentForm
          treatmentName={"Emergency treatments"}
          pageTitle={
            "Please fill in the form to find out more about emergency treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Crowns, Bridges, Inlays and Onlays":
      return (
        <SpecificTreatmentForm
          treatmentName={"crown, bridge, inlay and onlay treatments"}
          pageTitle={
            "Please fill in the form to find out more about crown, bridge, inlay and onlay treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Amalgam Free Filing":
      return (
        <SpecificTreatmentForm
          treatmentName={"amalgam free fillings treatments"}
          pageTitle={
            "Please fill in the form to find out more about amalgam free fillings treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Teeth Whitening":
      return (
        <SpecificTreatmentForm
          treatmentName={"teeth whitening treatments"}
          pageTitle={
            "Please fill in the form to find out more about teeth whitening treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Composite Bonding":
      return (
        <SpecificTreatmentForm
          treatmentName={"composite bonding treatments"}
          pageTitle={
            "Please fill in the form to find out more about composite bonding treatments at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Porcelain Veneers":
      return (
        <SpecificTreatmentForm
          treatmentName={"porcelain veneers treatment"}
          pageTitle={
            "Please fill in the form to find out more about porcelain veneers treatment at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Re-contouring Gumline":
      return (
        <SpecificTreatmentForm
          treatmentName={"re-contouring gumline treatment"}
          pageTitle={
            "Please fill in the form to find out more about re-contouring gumline treatment at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Lingual Brace":
      return (
        <SpecificTreatmentForm
          treatmentName={"fixed and lingual brace treatment"}
          pageTitle={
            "Please fill in the form to find out more about fixed and lingual brace treatment at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Fixed Brace":
      return (
        <SpecificTreatmentForm
          treatmentName={"fixed braces"}
          preferedClinics={preferedClinics}
        />
      )
    case "Botox":
      return (
        <SpecificTreatmentForm
          treatmentName={"wrinkle-relaxing treatment"}
          pageTitle={
            "Please fill in the form to find out more about wrinkle-relaxing treatment at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    case "Fillers":
      return (
        <SpecificTreatmentForm
          treatmentName={"dermal fillers treatment"}
          pageTitle={
            "Please fill in the form to find out more about dermal fillers treatment at White & Co."
          }
          preferedClinics={preferedClinics}
        />
      )
    default:
      return <></>
  }
}

export default getPageForm

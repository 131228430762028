import React from "react"
import styled from "styled-components"
import color from "../../style/color"

interface HeadingProps {
  heading?: string
}

const Heading = ({ heading }: HeadingProps) => {
  return <Text>{heading}</Text>
}

export default Heading

const Text = styled.p`
  font-size: 3.8rem;
  line-height: 4.8rem;
  font-family: headingFontLight;
  font-weight: 300;
  margin-bottom: 3.2rem;
  color: ${color.brand.blue};
`

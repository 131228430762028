import React from "react"
import { graphql } from "gatsby"
import { get } from "../../util/functions"
import color from "../../style/color"
import styled from "styled-components"
import Button from "../../shared/Button/Button"
import { headingFontLight } from "../../style/typography"
import StandardSection from "../../helper/StandardSection"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import Img from "gatsby-image"

interface IRow {
  heading: string
  subHeading?: string
  ctaText?: string
  ctaUrl?: string
  flip?: boolean
  text?: string
  imageDesktop?: string
  imageMobile?: string
  pillText?: string
  altDesktop?: string
  altMobile?: string
}

interface IMainContainer {
  flip?: boolean
}
interface IImage {
  imageDesktop?: string
  imageMobile?: string
}
interface IHalfAndHalf {
  data: Array<IRow>
}
const HalfAndHalf = ({ data }: IHalfAndHalf) => {
  return (
    <>
      {data &&
        data.map((row: any, key: number) => {
          return (
            <MainContainer flip={row.flip} key={key} data-testid="halfAndHalf">
              <TextSection flip={row.flip}>
                <InnerTextContainer>
                  {row.pillText && (
                    <TopText>
                      <SmallHeading heading={row.pillText} leftAlign />
                    </TopText>
                  )}
                  {row.heading && (
                    <div data-testid="heading-half-container">
                      <SectionHeading>{row.heading}</SectionHeading>
                    </div>
                  )}
                  {row.subHeading && <SubHeading>{row.subHeading}</SubHeading>}
                  {row.text && <SectionBody>{row.text}</SectionBody>}
                  {row.ctaText && (
                    <ButtonContainer>
                      <Button href={row.ctaUrl} text={row.ctaText} />
                    </ButtonContainer>
                  )}
                </InnerTextContainer>
              </TextSection>
              <HideOverflow>
                <Controller>
                  <Scene duration="100%">
                    <Timeline wrapper={<div className="parallax" />}>
                      <Tween
                        position="0"
                        from={{
                          scale: 1,
                        }}
                        to={{
                          scale: 1.05,
                        }}
                      >
                        <ImgWrapper>
                          {row.sources && (
                            <Image
                              fluid={row.sources}
                              alt={row.altDesktop}
                              placeholderStyle={{ opacity: 0 }}
                            />
                          )}
                        </ImgWrapper>
                      </Tween>
                    </Timeline>
                  </Scene>
                </Controller>
              </HideOverflow>
            </MainContainer>
          )
        })}
    </>
  )
}

const MainContainer = styled.div<IMainContainer>`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: ${props => props.flip && "row-reverse"};
  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column-reverse;
    padding: 0;
  }
`
const TextSection = styled.div<IMainContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5.8rem 10.5rem;
  position: absolute;
  bottom: 0;
  ${props => (props.flip ? "right: 10%;" : "left: 10%;")}
  z-index: 3;
  width: 55%;
  max-width: 70rem;
  background: white;
  backface-visibility: hidden;
  @media (max-width: 1600px) {
    ${props => (props.flip ? "right: 10%;" : "left: 10%;")}
  }
  @media (max-width: 1023px) {
    padding: 7rem;
    ${props => (props.flip ? "right: 3%;" : "left: 3%;")}
    width: 50%;
  }
  @media (max-width: 767px) {
    position: relative;
    width: 97%;
    max-width: 100%;
    min-height: 50px;
    left: 0;
    top: -50px;
    padding: 43px 57px 0 38px;
  }
`
const HideOverflow = styled.div`
  width: 60%;
  height: 67rem;
  overflow: hidden;
  margin-bottom: 9rem;
  position: relative;
  & div {
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 0;
    height: 40rem;
  }
`
const ImgWrapper = styled.div`
  transition: transform 1s ease;
`

const Image = styled(Img)`
  display: block;
  height: 100%;
  max-height: 67rem;
  width: 100%;
  object-fit: cover;
  backface-visibility: hidden;
  overflow: hidden;
  // transform-origin: bottom-right;
  backface-visibility: hidden;
  will-change: transform;
`

const TopText = styled.div`
  margin-bottom: 4.5rem;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`
const SectionHeading = styled.h3`
  margin-bottom: 3.5rem;
  font-family: ${headingFontLight};
  font-weight: 300;
`

const SubHeading = styled.p`
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 13px;
  }
`

const SectionBody = styled.p`
  margin-bottom: 3.5rem;
  font-weight: 300;
  @media (max-width: 1023px) {
  }
`

const InnerTextContainer = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  max-width: 210px;
`
export default HalfAndHalf

import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import React, { useEffect, useState } from "react"
import pin from "../../white-co-images/pin.svg"

const GoogleMap = ({ place }) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 })

  useEffect(() => {
    fetch(
      ` https://api.opencagedata.com/geocode/v1/json?q=${place}&key=${process.env.PLACES_KEY}`
    )
      .then(resp => resp.json())
      .then(data => {
        console.log(data.results)
        setCenter(data.results[0].geometry)
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.MAPS_KEY,
          language: "en",
        }}
        defaultCenter={center}
        center={center}
        defaultZoom={15}
      >
        <img lat={center.lat} lng={center.lng} src={pin} />
      </GoogleMapReact>
    </Container>
  )
}

const Container = styled.div`
  height: 460px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  & img {
    height: 30px;
  }

  @media (max-width: 767px) {
    height: 377px;
  }
`

export default GoogleMap

import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import { headingFontLight } from "../../style/typography"
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import Img from "gatsby-image"

interface IHomepageHeader {
  imageMobile?: string
  imageDesktop?: string
  sectionHeading?: string
  sectionBody?: string
  altDesktop?: string
  altMob?: string
  dropdown: any
  dropdownLabel: string
  sources: any
}

const HomepageHeader = ({
  sectionHeading,
  sectionBody,
  imageDesktop,
  imageMobile,
  altDesktop,
  altMob,
  dropdown,
  dropdownLabel,
  sources,
}: IHomepageHeader) => {
  return (
    <MainContainer data-testid="main-container">
      <Controller>
        <Scene duration="100%">
          <Timeline wrapper={<div className="parallax" />}>
            <Tween
              from={{
                scale: 1,
              }}
              to={{
                scale: 1.05,
              }}
            >
              <ImageContainer>
                {sources && (
                  <Image
                    fluid={sources}
                    alt={altDesktop}
                    placeholderStyle={{ opacity: 0 }}
                    loading={"eager"}
                  />
                )}
              </ImageContainer>
              <LightOverlay />
            </Tween>
          </Timeline>
        </Scene>
      </Controller>

      <InnerContainer>
        <TextContainer data-testid="text-container">
          {sectionBody && <Body data-testid="hp-body">{sectionBody}</Body>}
          {sectionHeading && (
            <SectionHeading data-testid="hp-heading">
              {sectionHeading}
            </SectionHeading>
          )}
        </TextContainer>
        <DropdownContainer>
          <HeaderDropdown
            placeholder={dropdownLabel}
            dropdown={dropdown}
            id="hp-dropdown"
          />
        </DropdownContainer>
      </InnerContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 13rem);
  height: 100vh;
  padding: 12vh 5% 0;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background: ${color.brand.blue};
  overflow: hidden;

  @media (max-width: 1200px) {
    background-position: center;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 80px 0 140px;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transition: all 0.7s;
`

const Image = styled(Img)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: transform;

  @media (max-width: 767px) {
    height: 100%;
  }
`

export const Overlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`
const LightOverlay = styled(Overlay)`
  background: rgba(0, 0, 0, 0.4);
`

const InnerContainer = styled.div`
  max-width: 80vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0 25px;
  }
`

const TextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  color: white;
  @media (max-width: 1199px) {
  }
  @media (max-width: 1023px) {
    text-align: center;
    width: 100%;
  }
`

const SectionHeading = styled.h1`
  font-size: 5vw;
  line-height: 6vw;
  font-family: ${headingFontLight};
  font-weight: 300;
  margin-bottom: 2.5vw;
  @media (max-width: 1023px) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 20px;
  }
`

const Body = styled.h3`
  width: 100%;
  max-width: 60vw;
  margin: 0 auto;
  font-family: ${headingFontLight};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 2vw;
  font-size: 1.3vw;
  line-height: 2.3vw;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 100%;
  }
`
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export default HomepageHeader

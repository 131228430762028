import React from "react"
import RangeOfTreatments from "./RangeOfTreatments"
import { graphql } from "gatsby"

const RangeOfTreatmentsDrupal = ({ node }) => {
  const { topText, sectionHeading, relationships } = node || {}
  const { field_treatment_column } = relationships || {}
  const columns = field_treatment_column.map(column => {
    return {
      heading: column.field_section_heading,
      body: column.field_subheading,
      list: column.field_treatment_item,
    }
  })

  return (
    <RangeOfTreatments
      topText={topText}
      sectionHeading={sectionHeading}
      columns={columns}
    />
  )
}

export const fragment = graphql`
  fragment RangeOfTreatments on paragraph__range_of_treatments {
    id
    topText: field_top_text
    sectionHeading: field_section_heading
    relationships {
      field_treatment_column {
        field_section_heading
        field_subheading
        field_treatment_item {
          title
          uri
        }
      }
    }
  }
`

export default RangeOfTreatmentsDrupal

import React, { useState, useEffect } from "react"
import InvisalignStep1 from "./InvisalignStep1"
import InvisalignStep2 from "./InvisalignStep2"
import InvisalignStep3 from "./InvisalignStep3"
import InvisalignStep4 from "./InvisalignStep4"
import {
  validatePhoneNumber,
  validateEmail,
  scrollPage,
  getPracticeEmail,
  trackButtonClick,
} from "../../../util/functions"
import { updateGoogleSheet, getAuthToken } from "../../../googleapis/api-hooks"
import styled from "styled-components"
import { setItem, getItem } from "../../../util/functions"
import emailjs from "emailjs-com"
import Loading from "../Loading"

const breadcrumb = [
  { uri: process.env.BASE, title: "Home" },

  {
    uri: "https://www.google.com",
    title: "Invisalign",
  },
  {
    uri: "https://www.google.com",
    title: "Invisalign® assessment",
  },
]

const reasonsList: Array<Reason> = [
  {
    value: "Getting married",
    image:
      "https://vignette.wikia.nocookie.net/heroism/images/c/c3/Marriage.jpeg/revision/latest/scale-to-width-down/340?cb=20160610053609",
  },
  {
    value: "Looking for or starting a new job",
    image:
      "https://images2.minutemediacdn.com/image/upload/c_fill,g_auto,h_1248,w_2220/f_auto,q_auto,w_1100/v1555360464/shape/mentalfloss/istock_000067177343_small.jpg",
  },
  {
    value: "Generally straighten teeth",
    image:
      "https://i0.wp.com/images-prod.healthline.com/hlcmsresource/images/News/tech/011316_3Dteeth_THUMB_LARGE.jpg?w=1155",
  },
  {
    value: "Feel more confident",
    image:
      "https://www.incimages.com/uploaded_files/image/970x450/getty_615524918_130727.jpg",
  },
]

const InvisalignForm = ({ preferedClinics }) => {
  const [step, changeStep] = useState(1)
  const [reasons, setReasons] = useState({
    "Getting married": false,
    "Looking or starting a new job": false,
    "Generally straighten teeth": false,
    "Feel more confident": false,
  })
  const [hasReason, setHasReason] = useState(false)
  const [teeth, setTeeth] = useState("")
  const [howSoon, setHowSoon] = useState("")
  const [title, setTitle] = useState(getItem("title"))
  const [titleError, toggleTitleError] = useState(false)
  const [reasonsError, toggleReasonsError] = useState(false)
  const [teethTypeError, toggleTeethTypeError] = useState(false)
  const [firstName, setFirstName] = useState(getItem("firstName"))
  const [lastName, setLastName] = useState(getItem("lastName"))
  const [firstNameError, toggleFirstNameError] = useState(false)
  const [lastNameError, toggleLastNameError] = useState(false)
  const [phone, setPhone] = useState(getItem("phone"))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem("email"))
  const [emailError, toggleEmailError] = useState(false)
  const [practice, setPractice] = useState("")
  const [practiceError, togglePracticeError] = useState(false)
  const [token, setToken] = useState("")

  const [isLoading, toggleLoading] = useState(false)

  const handleClick = (reason: string) => {
    setReasons({
      ...reasons,
      [reason]: !reasons[reason],
    })
    toggleReasonsError(false)
  }

  const anyReasonTrue = () => {
    for (let i = 0; i < reasonsList.length; i++) {
      if (reasons[reasonsList[i].value]) {
        return true
      }
    }
    return false
  }

  const getReasons = () => {
    let reasonsString: Array<string> = []
    for (let i = 0; i < reasonsList.length; i++) {
      if (reasons[reasonsList[i].value]) {
        reasonsString.push(reasonsList[i].value)
      }
    }
    return reasonsString.join(", ")
  }

  useEffect(() => {
    let practice = document.querySelector("#invisalign-form-dropdown1")
      .innerText
    setItem("title", title)
    setItem("firstName", firstName)
    setItem("lastName", lastName)
    setItem("phone", phone)
    setItem("practice", practice)
    setItem("email", email)

    if (token) {
      toggleLoading(true)
      document.querySelector("body").style.overflow = "hidden"

      const params = {
        emailTo: getPracticeEmail(practice),
        message: `
        <p>Form: Invisalign Assessment</p>
        <p>Name: ${title} ${firstName} ${lastName}</p>
        <p>Preferred practice: ${practice}</p>
        <p>Phone: ${phone}</p>
        <p>Email: ${email}</p>
        <p>Current teeth/smile: ${teeth}</p>
        <p>Reasons for treatment: ${getReasons()}</p>
        <p>How soon do they want treatment: ${howSoon}</p>
        `,
      }

      emailjs
        .send(
          "gmail",
          process.env.EMAILJS_TEMPLATE,
          params,
          process.env.EMAILJS_ID
        )
        .then(
          result => {
            console.log(result.text)
          },
          error => {
            console.log(error.text)
          }
        )
        .then(() => {
          updateGoogleSheet(
            token,
            "Invisalign",
            [
              firstName + " " + lastName,
              phone,
              email,
              practice,
              getReasons(),
              teeth,
              howSoon,
              window.location.href,
              new Date(),
            ],
            "invisalign-form-confirmation"
          )
        })
    }
  }, [token])

  const submitForm = () => {
    const goToNextScreen = () => {
      trackButtonClick(`Invisalign Form Submission`)
      getAuthToken(setToken)
    }

    let practice = document.querySelector("#invisalign-form-dropdown1")
      .innerText
    let idToScroll: string = ""

    let titleError: boolean = false
    let teethTypeError: boolean = false
    let firstNameError: boolean = false
    let lastNameError: boolean = false
    let phoneError = false
    let practiceError = false
    let reasonsError = false
    let emailError: boolean = false

    if (!anyReasonTrue()) {
      reasonsError = true
      toggleReasonsError(true)
      if (!idToScroll) {
        idToScroll = "reasons-container"
      }
    }
    if (!teeth) {
      teethTypeError = true
      toggleTeethTypeError(true)
      if (!idToScroll) {
        idToScroll = "teeth-type-container"
      }
    }
    if (!title) {
      titleError = true
      toggleTitleError(true)
      if (!idToScroll) {
        idToScroll = "smile-form-title"
      }
    }
    if (firstName.length < 1) {
      firstNameError = true
      toggleFirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }

    if (lastName.length < 1) {
      lastNameError = true
      toggleLastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (practice === "Please select") {
      practiceError = true
      togglePracticeError(true)
      if (!idToScroll) {
        idToScroll = "invisalign-form-dropdown1"
      }
    }

    if (
      !reasonsError &&
      !teethTypeError &&
      !titleError &&
      !firstNameError &&
      !lastNameError &&
      !phoneError &&
      !emailError &&
      !practiceError
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  const renderStep = () => {
    return isLoading ? (
      <Loading />
    ) : (
      <>
        <InvisalignStep1
          changeStep={changeStep}
          reasons={reasons}
          setReasons={setReasons}
          toggleReasonsError={toggleReasonsError}
          reasonsError={reasonsError}
          setHasReason={setHasReason}
          handleClick={handleClick}
          anyReasonTrue={anyReasonTrue}
          reasonsList={reasonsList}
        />
        <InvisalignStep2
          changeStep={changeStep}
          teeth={teeth}
          setTeeth={setTeeth}
          teethTypeError={teethTypeError}
          toggleTeethTypeError={toggleTeethTypeError}
        />
        <InvisalignStep3
          changeStep={changeStep}
          howSoon={howSoon}
          setHowSoon={setHowSoon}
        />
        <InvisalignStep4
          changeStep={changeStep}
          title={title}
          setTitle={setTitle}
          titleError={titleError}
          toggleTitleError={toggleTitleError}
          firstName={firstName}
          setFirstName={setFirstName}
          firstNameError={firstNameError}
          toggleFirstNameError={toggleFirstNameError}
          lastName={lastName}
          setLastName={setLastName}
          lastNameError={lastNameError}
          toggleLastNameError={toggleLastNameError}
          phone={phone}
          setPhone={setPhone}
          phoneError={phoneError}
          togglePhoneError={togglePhoneError}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          toggleEmailError={toggleEmailError}
          practice={practice}
          setPractice={setPractice}
          practiceError={practiceError}
          togglePracticeError={togglePracticeError}
          preferedClinics={preferedClinics}
          submitForm={submitForm}
        />
      </>
    )
  }

  return <Container>{renderStep()}</Container>
}

export default InvisalignForm

const Container = styled.div`
  @media (max-width: 767px) {
  }
`

import React, { useContext } from "react"
import List from "../CategoryTreatmentList/CategoryTreatmentList"
import MeetTheTeam from "../../helper/UserContext"

const TeamSection = ({ heading, cards, sixRows }) => {
  const { filter } = useContext(MeetTheTeam)

  const getFilteredCards = () => {
    if (filter.toLowerCase() === "all") return cards

    let filtered = []

    for (let i = 0; i < cards.length; i++) {
      if (cards[i].practice.includes(filter)) {
        filtered.push(cards[i])
      }
    }

    return filtered
  }

  return (
    <List
      subheading={heading}
      cards={getFilteredCards()}
      sixRows={sixRows}
      meetTheTeam
    />
  )
}

export default TeamSection

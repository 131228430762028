import React from "react"
import TreatmentHeader from "./TreatmentHeader"
import { graphql } from "gatsby"
import { get } from "../../util/functions"

const TreatmentHeaderDrupal = ({ node }: any) => {
  const {
    price,
    sectionHeading,
    sectionBody,
    pillText,
    relationships,
    field_cta,
    mobAlt,
    breadcrumb,
    financeAvailable,
    internalLink,
  } = node || {}
  const { details, dImg, mImg, textColumns } = relationships
  const imageDesktop = get(["localFile", "publicURL"], dImg, "")
  const imageMobile = get(["localFile", "publicURL"], mImg, "")
  const logoAlt = textColumns?.field_icon?.alt
  const logo = get(
    ["relationships", "field_icon", "localFile", "publicURL"],
    textColumns,
    ""
  )
  return (
    <TreatmentHeader
      sectionHeading={sectionHeading}
      sectionBody={sectionBody}
      imageDesktop={imageDesktop}
      imageMobile={imageMobile}
      pillText={pillText}
      price={price}
      ctaText1={field_cta.title}
      ctaHref1={field_cta.uri}
      details={details}
      altMob={mobAlt.alt}
      breadcrumb={breadcrumb}
      textColumnsSmall={textColumns?.field_suitable_for}
      textColumnHeading={textColumns?.field_section_heading}
      columnsCta={textColumns?.field_cta}
      logo={logo}
      logoAlt={logoAlt}
      financeAvailable={financeAvailable}
      internalLink={internalLink}
    />
  )
}

export default TreatmentHeaderDrupal

export const fragment = graphql`
  fragment TreatmentHeader on paragraph__treatment_header {
    id
    financeAvailable: field_0_finance_available
    internalLink: field_internal_link
    price: field_price
    sectionHeading: field_section_heading
    sectionBody: field_section_body
    pillText: field_category
    field_cta {
      uri
      title
    }
    breadcrumb: field_breadcrumb {
      title
      uri
    }

    mobAlt: field_image_mobile {
      alt
    }
    relationships {
      textColumns: field_text_column {
        field_section_heading
        field_icon {
          alt
        }
        field_cta {
          title
          uri
        }
        field_suitable_for
        relationships {
          field_icon {
            localFile {
              publicURL
            }
          }
        }
      }
      details: field_treatment_details {
        ... on paragraph__treatment_details {
          title: field_section_heading
          text: field_value
        }
      }
      dImg: field_image_desktop {
        localFile {
          publicURL
        }
      }
      mImg: field_image_mobile {
        localFile {
          publicURL
        }
      }
    }
  }
`

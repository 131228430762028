import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import VerticalPadding from "../../helper/VerticalPadding"
import StandardSection from "../../helper/StandardSection"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import Img from "gatsby-image"

interface Col {
  title: string
  image: string
  mobImage: string
  text: string
  alt: string
}
interface StepsToPerfectSmileProps {
  data: Array<Col>
  heading: string
  subheading: string
}
const StepsToPerfectSmile = ({
  heading,
  subheading,
  data,
}: StepsToPerfectSmileProps) => {
  return (
    <MainContainer>
      <StandardSection>
        <VerticalPadding>
          <Inner>
            {subheading && <SmallHeading heading={subheading} marginBottom />}
            {heading && (
              <div data-testid="heading-steps-container">
                <SectionHeading heading={heading} />{" "}
              </div>
            )}

            <Grid centerColumn={data.length <= 2}>
              {data.map((column, key) => {
                return (
                  <Column key={key}>
                    <ImageContainer>
                      {column.image && (
                        <Image src={column.image} alt={column.alt} />
                      )}
                    </ImageContainer>

                    <TextContainer>
                      {column.title && (
                        <div data-testid="steps-headings">
                          <Heading>{column.title}</Heading>
                        </div>
                      )}

                      {column.text && (
                        <div data-testid="steps-card-text-content">
                          <Body
                            dangerouslySetInnerHTML={{ __html: column.text }}
                          />
                        </div>
                      )}
                    </TextContainer>
                  </Column>
                )
              })}
            </Grid>
          </Inner>
        </VerticalPadding>
      </StandardSection>
    </MainContainer>
  )
}

export default StepsToPerfectSmile

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background: ${color.background.blueLight};
  @media (max-width: 1023px) {
  }
`
const Inner = styled.div`
  width: 100%;
`

interface GridProps {
  centerColumn?: boolean
}

const Grid = styled.div<GridProps>`
  display: flex;
  // flex-wrap: wrap;
  width: 100%;
  margin-top: 5rem;
  ${props => props.centerColumn && "justify-content: center;"}

  @media (min-width: 1023px) and (max-width: 1200px) {
  }
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`

interface ColumnProps {}
const Column = styled.div<ColumnProps>`
  text-align: center;
  width: 100%;
  max-width: 32.6%;
  margin: 0 1% 1% 0;
  &:nth-of-type(3n) {
    margin-right: 0%;
  }
  @media (max-width: 767px) {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    margin: 0 0 25px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  background: ${color.brand.blue};
  border-radius: 100%;
  width: 12rem;
  height: 12rem;
  @media (max-width: 767px) {
    margin: 0;
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
`

const TextContainer = styled.div`
  padding: 0 3rem;

  @media (max-width: 767px) {
    padding: 0;
    padding-left: 12px;
    text-align: left;
  }
`

const Image = styled.img`
  // height: 4rem;
  @media (max-width: 767px) {
    height: 20px;
  }
`

const Heading = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 10px;
  font-family: Futura;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
  }
`

const Body = styled.div`
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 300;

  @media (max-width: 1023px) {
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
`

const Subheading = styled.p`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.6rem;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
`

import React from "react"
import styled from "styled-components"
import StandardSection from "../../helper/StandardSection"
import { Breadcrumb, Link } from "../../shared/Breadcrumb/Breadcrumb"
import { formatLink } from "../../util/functions"
import color from "../../style/color"

interface HeaderProps {
  name: string
  image: string
  imageAlt: string
  mobileImage: string
  mobileImageAlt: string
  breadcrumb: any
}

const AboutUsHeader = ({ name, image, imageAlt, breadcrumb }: HeaderProps) => {
  return (
    <>
      <Container>
        <StandardSection>
          <InnerContainer>
            <Heading data-testid="heading">{name}</Heading>
            <Image data-testid="image" src={image} alt={imageAlt} />
          </InnerContainer>
        </StandardSection>
      </Container>
      <StandardSection>
        <Breadcrumb noPadding>
          {breadcrumb.map((item: any, key: number) => {
            return (
              <React.Fragment key={key}>
                <Link
                  href={
                    key !== breadcrumb.length - 1 ? formatLink(item.uri) : null
                  }
                >
                  {item.title}
                </Link>
                <span>|</span>
              </React.Fragment>
            )
          })}
        </Breadcrumb>
      </StandardSection>
    </>
  )
}

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 400px;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding-top: 25px;
  }
`

const Container = styled.div`
  height: 400px;
  overflow: hidden;
  background: #f5f4f4;
  @media (max-width: 1023px) {
    height: 485px;
  }
`

const Image = styled.img`
  height: 100%;
  max-width: 550px;
`

const Heading = styled.p`
  color: ${color.brand.blue};
  font-family: headingFontLight;
  font-size: 55px;
  font-weight: 300;
  line-height: 65px;

  @media (max-width: 1023px) {
    font-size: 40px;
    margin-bottom: 25px;
  }
`
export default AboutUsHeader

import React from "react"
import styled from "styled-components"
import { get, formatLink, scrollPage } from "../../util/functions"
import { graphql } from "gatsby"
import color from "../../style/color"
import { headingFontLight } from "../../style/typography"
import Cta from "../../shared/Button/Button"
import VerticalPadding from "../../helper/VerticalPadding"

interface IPromotionBanner {
  sectionHeading?: string
  sectionBody?: string
  ctaText?: string
  ctaUrl: string
  scroll: boolean
}

const PromotionBanner = ({
  sectionHeading,
  sectionBody,
  ctaText,
  ctaUrl,
  scroll,
}: IPromotionBanner) => {
  return (
    <Outer>
      <VerticalPadding>
        <MainContainer data-testid="promotion-banner">
          <TextContainer data-testid="text-container">
            {sectionHeading && (
              <SectionHeading data-testid="promotion-heading">
                {sectionHeading}
              </SectionHeading>
            )}
            {sectionBody && (
              <Body data-testid="promotion-body">{sectionBody}</Body>
            )}
          </TextContainer>
          {ctaText && scroll ? (
            <Cta
              onClick={() => {
                scrollPage("page-form")
              }}
              text={ctaText}
            />
          ) : (
            <Cta href={ctaUrl} text={ctaText} />
          )}
        </MainContainer>
      </VerticalPadding>
    </Outer>
  )
}

const Outer = styled.div`
  background: ${color.brand.green};
`

const MainContainer = styled.div<IPromotionBanner>`
  margin: 0 auto;
  width: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1199px) {
  }
  @media (max-width: 1023px) {
    text-align: center;
    width: 100%;
  }
`

const SectionHeading = styled.h2`
  font-family: ${headingFontLight};
  font-weight: 300;
  margin-bottom: 3rem;
  @media (max-width: 767px) {
    // text-align: left;
  }
`

const Body = styled.p`
  width: 100%;
  margin: 0 auto;
  font-weight: 300;
  margin-bottom: 3.5rem;
  font-size: 2.2rem;
  line-height: 3.2rem;
  max-width: 650px;
  @media (max-width: 767px) {
  }
`

export default PromotionBanner

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ReactSlider from "react-slider"
import color from "../../style/color"
import Dropdown from "../Form/CustomDropdown"
import {
  numberWithCommas,
  numberWithCommasWithoutDecimals,
} from "../../util/functions"
import MinimalSection from "../../helper/MinimalSection"
import Button from "../../shared/Button/Button"
import ErrorMessage from "../Form/ErrorMessage"

interface FinanceCalculatorProps {
  sectionHeading: string
}

const options = [
  { label: "6 Months", value: 0.5 },
  { label: "12 Months", value: 1 },
  { label: "36 Months", value: 2 },
  { label: "48 Months", value: 3 },
]

const FinanceCalculator = ({ sectionHeading }: FinanceCalculatorProps) => {
  const [monthly, setMonthly] = useState(0)
  const [loan, setLoan] = useState(1500)
  const [years, setYears] = useState(0.5)
  const [isMobile, setIsMobile] = useState(true)
  const [sliderStep, setSliderStep] = useState(0)

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
      setSliderStep(100)
    } else {
      setIsMobile(false)
      setSliderStep(100)
    }
  }

  useEffect(() => {
    handleResize()
  }, [isMobile])

  const getApr = () => {
    return years < 2 ? 0 : 9.9
  }

  const getDeposit = () => {
    return 0
  }

  const calculate = (val: number) => {
    setLoan(val)
    if (years === 5 && val < 1000) {
      return setMonthly("N/A")
    }

    const calc = (total: number, apr: number, years: number) => {
      var totalWithInterest = total * Math.pow(1 + apr / 100, years - 1)
      return {
        total: totalWithInterest,
        monthly: totalWithInterest / (years * 12),
      }
    }

    const deposit = getDeposit()
    const total = val - deposit
    const apr = getApr()

    let monthlyPayment

    if (years >= 2) {
      monthlyPayment = calc(total, apr, years).monthly
    } else {
      monthlyPayment = total / (years * 12)
    }
    setMonthly(monthlyPayment)
  }

  useEffect(() => {
    calculate(1500)
  }, [])

  useEffect(() => {
    calculate(loan)
  }, [years])

  const handleChange = val => {
    switch (val) {
      case "6 Months":
        setYears(0.5)
        break
      case "12 Months":
        setYears(1)
        break
      case "24 Months":
        setYears(2)
        break
      case "36 Months":
        setYears(3)
        break
      case "48 Months":
        setYears(4)
        break
    }
  }

  const onClick = () => {
    if (monthly !== "N/A") {
      window.location.href = `/general-enquiry/`
    }
  }

  return (
    <>
      <MinimalSection>
        <Container>
          <h1>{sectionHeading}</h1>
          <TopLevel>
            <SliderContainer>
              <SliderTitle>How much do you want to borrow?</SliderTitle>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                step={sliderStep}
                onChange={val => {
                  calculate(val)
                }}
                renderThumb={(props, state) => (
                  <ThumbContainer {...props}>
                    <div>
                      <div>
                        <span>£{numberWithCommasWithoutDecimals(loan)}</span>
                      </div>
                    </div>
                  </ThumbContainer>
                )}
                ariaLabel="Number of users"
                value={loan}
                max={4500}
                min={1500}
              />
              <SlideLabels>
                <p>£1,500</p>
                <p>£4,500</p>
              </SlideLabels>
            </SliderContainer>
            <DropdownContainer>
              <SliderTitleHowLong>How long for?</SliderTitleHowLong>
              <Dropdown
                options={options}
                placeholder={options[0].label}
                toggleDropdownError={() => {}}
                handleChange={handleChange}
              />
            </DropdownContainer>
          </TopLevel>
          {monthly === "N/A" && (
            <MessageContainer>
              <ErrorMessage title="If you wish to borrow less than £1000 please reduce the term length." />
            </MessageContainer>
          )}
          <Table>
            <TableHeading>Representative Example</TableHeading>
            <Rows>
              <div>
                <span>Loan amount</span>
                <p>£{numberWithCommas(loan)}</p>
              </div>
              <div>
                <span>Monthly repayment</span>
                <p>
                  {monthly !== "N/A" ? `£` : ""}
                  {numberWithCommas(monthly)}
                </p>
              </div>
              <div>
                <span>Deposit amount</span>
                <p>{monthly === "N/A" ? "N/A" : `£${getDeposit()}`}</p>
              </div>
              <div>
                <span>Representative APR</span>
                <p>{getApr()}% APR</p>
              </div>
            </Rows>
          </Table>
          <BtnContainer>
            <Button
              text="Apply for finance"
              size={"large"}
              primary
              target
              onClick={onClick}
            />
          </BtnContainer>
        </Container>
      </MinimalSection>
      <FooterMessageContainer>
        <p>
        PLEASE NOTE: The calculator is to be used for illustration only. All finance options are subject to acceptance and terms & conditions. E&OE.
        </p>
        <p>
          White & Co. Battersea Rise is a trading name of Ramco Dental Limited
          (FRN: 654205), authorised and regulated by the Financial Conduct
          Authority. We are credit broker and only offer credit products from
          V12 Finance. Credit is subject to age and status.
          <br />
          <br />
          White & Co. West Wickham is a trading name of Ramco Dental Limited
          (FRN: 654205), authorised and regulated by the Financial Conduct
          Authority. We are credit broker and only offer credit products from
          V12 Finance. Credit is subject to age and status.
        </p>
      </FooterMessageContainer>
    </>
  )
}

const FooterMessageContainer = styled.div`
  max-width: 994px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.8;
  > p {
    font-size: 12px;
    line-height: 17px;
    padding-bottom: 50px;
  }
  @media (max-width: 1200px) {
    padding: 0 3%;
  }
`

const MessageContainer = styled.div`
  margin-top: -20px;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.8rem 0 0;
`

const TableHeading = styled.div`
  background-color: rgba(80, 227, 194, 0.25);
  padding: 14px 0 14px 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  @media (max-width: 767px) {
    text-align: center;
    padding: 10px;
  }
`
const Rows = styled.div`
  display: flex;

  & div {
    flex: 1;
    padding: 25px 0 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(73, 221, 194, 0.1);

    &:not(:last-of-type) {
      border-right: 1px solid #a7f1e0;
      @media (max-width: 767px) {
        border-right: none;
        border-bottom: 1px solid #a7f1e0;
      }
    }
    @media (max-width: 767px) {
      &:nth-of-type(1) {
        border-right: 1px solid #a7f1e0;
      }
      &:nth-of-type(3) {
        border-right: 1px solid #a7f1e0;
        border-bottom: none;
      }
    }
    & span {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 4px;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 22px;
      }
    }

    & p {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const SlideLabels = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -33px;
  font-size: 16px;
  line-height: 26px;
  width: 100%;
  max-width: 726px;
`

const DropdownContainer = styled.div`
  width: 180px;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: -10px;
  }
`

const Container = styled.div`
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-bottom: 50px;
  }

  & h1 {
    font-weight: 300;
    font-size: 48px;
    line-height: 58px;
    padding-bottom: 7rem;
    text-align: center;
    font-family: headingFontLight;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
`
const SliderTitle = styled.p`
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 400;
  padding-bottom: 48px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`

const SliderTitleHowLong = styled(SliderTitle)`
  padding-bottom: 20px;
  @media (max-width: 767px) {
    padding-bottom: 0px;
  }
`

const TopLevel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 80%;
  @media (max-width: 1199px) {
    max-width: 70rem;
  }
@media (max-width: 830px) {
  max-width: 60rem;
}
  & .horizontal-slider {
    max-width: 726px;
    height: 50px;
  }

  & .thumb {
    @media (max-width: 768px) {
      height: 30px;
      width: 30px;
      top: -12px;
    }
    background: ${color.brand.green};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    outline: none;
    top: -7px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & > div {
      position: absolute;
      top: -33px;
      background: ${color.brand.blue};
      border-radius: 2px;
      box-shadow: 0 -4px 8px 0 rgba(140,140,140,0.2), 0 4px 8px 0 rgba(140,140,140,0.3);
      left: 50%;
      transform: translateX(-50%);
    }

    & > div > div {
      font-weight: bold;
      font-size: 14px;
      color: white;
      border-radius: 2px;
      display: flex;
      padding: 0px 8px;


      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @media (max-width: 767px) {
        padding: 0px 4px;
      }
      & span {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        position: relative;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 24px;
        }
        &:after {
          position: absolute;
          content: '';
          bottom: -4px;
          right: 50%;
          transform: translateX(50%);
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          ${props => `transform: translate(-${props.transform}%);`}
          border-top: 4px solid ${color.brand.blue};
        }
      }
    }
  }

  & .track {
    max-width: 726px;
    height: 8px;
    background: #e6e6e6;
    cursor: pointer;
    border-radius: 100px;

  }

  & .track-0 {
    background: ${color.text.secondary};
    position: relative;
    border-radius: 100px;
  }

  & .track-1 {
    position: relative;
  }
`
const ThumbContainer = styled.div``
const BtnContainer = styled.div`
  margin: 0 auto;
  margin-top: 43px;
  display: flex;
  justify-content: center;
`

export default FinanceCalculator

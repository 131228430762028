import React, { useState, useEffect } from "react"
import Input from "./Input"
import Button from "../../shared/Button/Button"
import styled from "styled-components"
import color from "../../style/color"
import { setItem } from "../../util/functions"
import Label from "./Label"
import TextArea from "./TextArea"
import moment from "moment"

import {
  validateOnBlur,
  validatePhoneNumber,
  validateDateOfBirth,
  validateEmail,
  scrollPage,
  renderDate,
  validatePostcode,
  getPracticeEmail,
  trackButtonClick,
} from "../../util/functions"
import emailjs from "emailjs-com"
import Loading from "./Loading"

import { updateGoogleSheet, getAuthToken } from "../../googleapis/api-hooks"
import UploadInput from "./UploadInput"
import TitleButtons from "./TitleButtons"
import Heading from "./Heading"
import FirstSection from "./Layout/FirstSection"
import SecondSection from "./Layout/SecondSection"
import Inner from "./Layout/Inner"
import RadioBtn from "./RadioButton"

const SpecificTreatmentForm = ({ preferedClinics }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [firstNameError, togglefirstNameError] = useState(false)
  const [lastNameError, togglelastNameError] = useState(false)
  const [phone, setPhone] = useState("")
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState("")
  const [emailError, toggleEmailError] = useState(false)
  const [practiceEmail, setPracticeEmail] = useState("")
  const [practiceEmailError, togglePracticeEmailError] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState(moment().format("DD/MM/YY"))
  const [dateOfBirthError, toggleDobDateError] = useState(false)
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [address3, setAddress3] = useState("")
  const [anythingElse, setAnythingElse] = useState("")
  const [title, setTitle] = useState()
  const [titleError, toggleTitleError] = useState(false)
  const [city, setCity] = useState("")
  const [postcode, setPostcode] = useState("")
  const [pAddress1, setPAddress1] = useState("")
  const [pAddress2, setPAddress2] = useState("")
  const [pAddress3, setPAddress3] = useState("")
  const [pCity, setPCity] = useState("")
  const [pPostcode, setPPostcode] = useState("")
  const [reason, setReason] = useState("")
  const [pAddress1Error, togglePAddress1Error] = useState(false)
  const [pAddress2Error, togglePAddress2Error] = useState(false)
  const [pCityError, togglePCityError] = useState(false)
  const [pPostcodeError, togglePPostcodeError] = useState(false)
  const [address1Error, toggleAddress1Error] = useState(false)
  const [address2Error, toggleAddress2Error] = useState(false)
  const [cityError, toggleCityError] = useState(false)
  const [postcodeError, togglePostcodeError] = useState(false)
  const [practiceNumber, setPracticeNumber] = useState("")
  const [practiceNumberError, togglePracticeNumberError] = useState(false)
  const [practiceName, setPracticeName] = useState("")
  const [practiceNameError, togglePracticeNameError] = useState(false)
  const [dentistName, setDentistName] = useState("")
  const [dentistNameError, toggleDentistNameError] = useState(false)
  const [practiceError, togglePracticeError] = useState(false)
  const [token, setToken] = useState("")
  const [fileArray, editFileArray] = useState([])

  const [isLoading, toggleLoading] = useState(false)
  const [category, setCategory] = useState("")
  const [categoryError, toggleCategoryError] = useState(false)

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      typeof document !== "undefined" &&
      token
    ) {
      setItem("category-treatment", category)
      setItem("dentist-name", dentistName)
      setItem("firstName", firstName)
      setItem("lastName", lastName)
      setItem("email", email)
      setItem("title", title)
      setItem("address-1", address1)
      setItem("dob", dateOfBirth)
      setItem("phone", phone)
      setItem("practice-name", practiceName)
      setItem("p-address-1", pAddress1)
      setItem("practice-number", practiceNumber)

      toggleLoading(true)
      document.querySelector("body").style.overflow = "hidden"

      const sendFormData = async () => {
        if (fileArray.length > 0) {
          Promise.all(
            Array.from(fileArray).map(file => {
              let data = new FormData()
              data.append("upload_preset", "dental")
              data.append("file", file)
              data.append("cloud_name", "dnsepqj3v")

              return fetch(
                `https://api.cloudinary.com/v1_1/dnsepqj3v/image/upload`,
                {
                  method: "POST",
                  body: data,
                }
              )
                .then(resp => resp.json())
                .then(data => data.secure_url)
            })
          ).then(images => {
            const imageString = () => {
              console.log(images.join(" <br />"))
              return images.join(" <br />")
            }

            const params = {
              emailTo: getPracticeEmail("practice"),
              message: `
                <p>Form: Dentist Referral Form</p>
                <p>Dentist Name: ${dentistName}</p>
                <p>Dental Practice Phone Number: ${practiceNumber}</p>
                <p>Dental Practice Address: ${pAddress1}</p>
                <p>Dental Practice Email: ${practiceEmail}</p>
                <p>Patient Name: ${title} ${firstName} ${lastName}</p>
                <p>Date of referral: ${dateOfBirth}</p>
                <p>Category Treatment: ${category} </p>
                <p>Patient Phone: ${phone}</p>
                <p>Patient Email: ${email}</p>
                <p>Detailed reason: ${reason}</p>
                <p>Images :</p>
                <div>
                ${imageString()}
                </div>
                `,
            }

            emailjs
              .send(
                "gmail",
                process.env.EMAILJS_TEMPLATE,
                params,
                process.env.EMAILJS_ID
              )
              .then(
                result => {
                  console.log(result.text)
                },
                error => {
                  console.log(error.text)
                }
              )
              .then(() => {
                updateGoogleSheet(
                  token,
                  "DentistReferral",
                  [
                    category,
                    firstName + " " + lastName,
                    phone,
                    email,
                    dentistName,
                    practiceNumber,
                    practiceEmail,
                    dateOfBirth,
                    [pAddress1, pAddress2, pAddress3, pCity, pPostcode].join(
                      " "
                    ),
                    reason,
                    window.location.href,
                    new Date(),
                  ],
                  "dentist-form-confirmation"
                )
              })
          })
        } else {
          const params = {
            emailTo: getPracticeEmail("practice"),
            message: `
              <p>Form: Dentist Referral Form</p>
              <p>Dentist Name: ${dentistName}</p>
              <p>Dental Practice Phone Number: ${practiceNumber}</p>
              <p>Dental Practice Address: ${pAddress1}</p>
              <p>Dental Practice Email: ${practiceEmail}</p>
              <p>Patient Name: ${title} ${firstName} ${lastName}</p>
              <p>Date of referral: ${dateOfBirth}</p>
              <p>Category Treatment: ${category} </p>
              <p>Patient Phone: ${phone}</p>
              <p>Patient Email: ${email}</p>
              <p>Detailed reason: ${reason}</p>
              `,
          }

          emailjs
            .send(
              "gmail",
              process.env.EMAILJS_TEMPLATE,
              params,
              process.env.EMAILJS_ID
            )
            .then(
              result => {
                console.log(result.text)
              },
              error => {
                console.log(error.text)
              }
            )
            .then(() => {
              updateGoogleSheet(
                token,
                "DentistReferral",
                [
                  category,
                  firstName + " " + lastName,
                  phone,
                  email,
                  dentistName,
                  practiceNumber,
                  practiceEmail,
                  dateOfBirth,
                  [pAddress1, pAddress2, pAddress3, pCity, pPostcode].join(" "),
                  reason,
                  window.location.href,
                  new Date(),
                ],
                "dentist-form-confirmation"
              )
            })
        }
      }

      sendFormData()
    }
  }, [token])

  const submitForm = () => {
    const goToNextScreen = () => {
      trackButtonClick(`Dentist Referral Form Submission`)
      getAuthToken(setToken)
    }
    // let practice = document.querySelector("#dentist-referral-form-dropdown1")
    //   .innerText
    let idToScroll: string = ""

    let titleError: boolean = false
    let firstNameError: boolean = false
    let lastNameError: boolean = false
    let phoneError: boolean = false
    let dentistNameError: boolean = false
    // let practiceError: boolean = false
    let emailError: boolean = false
    let address1Error: boolean = false
    // let dateOfBirthError: boolean = false
    let practiceNameError: boolean = false
    let pAddress1Error: boolean = false
    let practiceNumberError: boolean = false
    let categoryError: boolean = false
    let practiceEmailError: boolean = false

    if (!category) {
      categoryError = true
      toggleCategoryError(true)
      if (!idToScroll) {
        idToScroll = "category-container"
      }
    }
    if (!title) {
      titleError = true
      toggleTitleError(true)
      if (!idToScroll) {
        idToScroll = "title-container-dentist"
      }
    }
    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = "firstName"
      }
    }

    if (lastName.length < 1) {
      lastNameError = true
      togglelastNameError(true)
      if (!idToScroll) {
        idToScroll = "lastName"
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = "phone"
      }
    }
    if (dentistName.length < 1) {
      dentistNameError = true
      toggleDentistNameError(true)
      if (!idToScroll) {
        idToScroll = "dentist-name"
      }
    }

    if (!validatePhoneNumber(practiceNumber)) {
      practiceNumberError = true
      togglePracticeNumberError(true)
      if (!idToScroll) {
        idToScroll = "practice-number"
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = "email"
      }
    }
    if (!validateEmail(practiceEmail)) {
      practiceEmailError = true
      togglePracticeEmailError(true)
      if (!idToScroll) {
        idToScroll = "practiceEmail"
      }
    }
    // if (dateOfBirth === "") {
    //   dateOfBirthError = true
    //   toggleDobDateError(true)
    //   if (!idToScroll) {
    //     idToScroll = "dob"
    //   }
    // }

    if (pAddress1.length < 1) {
      pAddress1Error = true
      togglePAddress1Error(true)
      if (!idToScroll) {
        idToScroll = "p-address-1"
      }
    }

    if (
      !categoryError &&
      !titleError &&
      !firstNameError &&
      !lastNameError &&
      !emailError &&
      !address1Error &&
      // !dateOfBirthError &&
      !phoneError &&
      !pAddress1Error &&
      !dentistNameError &&
      !practiceEmailError &&
      !practiceNumberError
    ) {
      goToNextScreen()
    } else {
      if (idToScroll) {
        scrollPage(idToScroll)
      }
    }
  }

  const handleDateOfBirth = (val: string): void => {
    setDateOfBirth(renderDate(val))
  }

  const [fileList, editFileList] = useState([])

  // useEffect(() => {
  //   console.log(fileList)
  //
  //   if (fileList.length > 0) {
  //     const files = document.getElementById("dentist-form-upload1").files
  //
  //     let data = new FormData()
  //     data.append("upload_preset", "dental")
  //     data.append("file", files[0])
  //     data.append("cloud_name", "dnsepqj3v")
  //
  //     fetch(`https://api.cloudinary.com/v1_1/dnsepqj3v/image/upload`, {
  //       method: "POST",
  //       body: data,
  //     })
  //       .then(resp => resp.json())
  //       .then(data => console.log(data))
  //   }
  // }, [fileList])

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Form>
        <FirstSection background="#fafafa" paddingTop={"3rem"}>
          <Inner paddingBottom>
            <Heading heading="Patient details" />
            <Label label="I am referring the patient for*: (Please select one)" />
            <ErrorAnchor id="category-container" top={"-70px"} />
            <BtnsContainer hasError={categoryError}>
              <RadioBtn
                onClick={() => {
                  setCategory("To Endodontist")
                  toggleCategoryError(false)
                }}
                isActive={category === "To Endodontist"}
              >
                To Endodontist
              </RadioBtn>
              <RadioBtn
                onClick={() => {
                  setCategory("To Periodontist")
                  toggleCategoryError(false)
                }}
                isActive={category === "To Periodontist"}
              >
                To Periodontist
              </RadioBtn>
              <RadioBtn
                onClick={() => {
                  setCategory("To Orthodontist")
                  toggleCategoryError(false)
                }}
                isActive={category === "To Orthodontist"}
              >
                To Orthodontist
              </RadioBtn>
              <RadioBtn
                onClick={() => {
                  setCategory("To Oral Surgeon")
                  toggleCategoryError(false)
                }}
                isActive={category === "To Oral Surgeon"}
              >
                To Oral Surgeon
              </RadioBtn>
            </BtnsContainer>
            <Label label="Patient title*" />
            <TitleButtons
              title={title}
              titleError={titleError}
              toggleTitleError={toggleTitleError}
              setTitle={setTitle}
              id="title-container-dentist"
            />

            <Flex>
              <Input
                value={firstName}
                onChange={setFirstName}
                label={"Patient first name*"}
                isValidated={firstName.length > 0}
                hasError={firstNameError}
                errorTitle="Please enter patient's first name"
                onBlur={() =>
                  validateOnBlur(firstName.length > 0, togglefirstNameError)
                }
                id="firstName"
                color="#061B30"
              />
              <Gap />
              <Input
                value={lastName}
                onChange={setLastName}
                label={"Patient last name*"}
                isValidated={lastName.length > 0}
                hasError={lastNameError}
                errorTitle="Please enter patient's last name"
                onBlur={() =>
                  validateOnBlur(lastName.length > 0, togglelastNameError)
                }
                id="lastName"
                color="#061B30"
              />
            </Flex>
            <Flex>
              <Input
                value={phone}
                onChange={phone => phone.length < 20 && setPhone(phone)}
                label={"Patient contact number*"}
                isValidated={validatePhoneNumber(phone)}
                hasError={phoneError}
                errorTitle="Please enter a valid telephone number"
                onBlur={() =>
                  validateOnBlur(validatePhoneNumber(phone), togglePhoneError)
                }
                id="phone"
                color="#061B30"
              />
              <Gap />
              <Input
                value={email}
                onChange={setEmail}
                label={"Patient email address*:"}
                isValidated={validateEmail(email)}
                hasError={emailError}
                errorTitle="Please enter a valid email address"
                onBlur={() => {
                  validateOnBlur(validateEmail(email), toggleEmailError)
                  toggleEmailError
                }}
                id="email"
                color="#061B30"
              />
            </Flex>
          </Inner>
        </FirstSection>
        <SecondSection>
          <Inner>
            <Heading heading="Referring dentist’s details" />
            <Flex>
              <Input
                value={dentistName}
                onChange={setDentistName}
                label={"Dentist name*"}
                isValidated={dentistName.length > 0}
                hasError={dentistNameError}
                errorTitle="Please enter your dentist's name"
                onBlur={() =>
                  validateOnBlur(dentistName.length > 0, toggleDentistNameError)
                }
                id="dentist-name"
                color="#061B30"
              />
              <Gap />
              <Input
                value={practiceNumber}
                onChange={practiceNumber =>
                  practiceNumber.length < 20 &&
                  setPracticeNumber(practiceNumber)
                }
                label={"Dentist contact number*"}
                isValidated={validatePhoneNumber(practiceNumber)}
                hasError={practiceNumberError}
                errorTitle="Please enter a valid telephone number"
                onBlur={() =>
                  validateOnBlur(
                    validatePhoneNumber(practiceNumber),
                    togglePracticeNumberError
                  )
                }
                id="practice-number"
                color="#061B30"
              />
            </Flex>
            <Flex>
              <Input
                value={practiceEmail}
                onChange={setPracticeEmail}
                label={"Your email address*:"}
                isValidated={validateEmail(practiceEmail)}
                hasError={practiceEmailError}
                errorTitle="Please enter a valid email address"
                onBlur={() => {
                  validateOnBlur(
                    validateEmail(practiceEmail),
                    togglePracticeEmailError
                  )
                }}
                id="practiceEmail"
                color="#061B30"
              />
              <Gap />
              <Input
                value={moment().format("DD/MM/YY")}
                onChange={(val: any) =>
                  handleDateOfBirth(moment().format("DD/MM/YY"))
                }
                label={"Referral date*"}
                isValidated={dateOfBirth.length > 0}
                placeholder={"DD/MM/YY"}
                hasError={dateOfBirthError}
                errorTitle="This is not a valid date"
                onBlur={() => {
                  if (dateOfBirth) {
                    validateOnBlur(
                      validateDateOfBirth(dateOfBirth),
                      toggleDobDateError
                    )
                  } else {
                    toggleDobDateError(false)
                  }
                }}
                id="dob"
                color="#061B30"
              />
            </Flex>
            <Input
              value={pAddress1}
              onChange={setPAddress1}
              label={"Practice address*"}
              isValidated={pAddress1.length > 0}
              hasError={pAddress1Error}
              errorTitle="Please enter an address line"
              onBlur={() =>
                validateOnBlur(pAddress1.length > 0, togglePAddress1Error)
              }
              id="p-address-1"
              color="#061B30"
            />
            <TextArea
              heading={"Detailed reason for referral:"}
              value={reason}
              onChange={setReason}
              color="#061B30"
              minHeight="16rem"
            />
          </Inner>
        </SecondSection>
      </Form>

      <Center>
        <UploadInput
          id="dentist-form-upload1"
          bold={"Upload your relevant files"}
          heading={"(Photos, digital x-rays)"}
          fileList={fileList}
          editFileList={editFileList}
          editFileArray={editFileArray}
          imageList={fileArray}
        />

        <BtnContainer>
          <Button text="Send patient referral" onClick={submitForm} primary />
        </BtnContainer>

        <LabelContainer>
          <Label label="Upload your documents as jpg, png, or gif formats." />
        </LabelContainer>
      </Center>
    </Container>
  )
}

export default SpecificTreatmentForm

interface ErrorAnchor {
  top?: string
}
export const ErrorAnchor = styled.div`
  position: absolute;
  top: ${props => props.top && props.top};
  width: 50px;
  height: 50px;
`

const Container = styled.div`
  // background: ${color.background.green10};
  padding-bottom: 10rem;
  @media (max-width: 767px) {
  }
`

const Form = styled.div`
  & label {
    font-weight: 300;
  }
`

interface IRadioContainer {
  hasError: boolean
}

const Flex = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
const Gap = styled.div`
  width: 4.3rem;
  @media (max-width: 767px) {
    display: none;
  }
`

const BtnContainer = styled.div`
  width: 100%;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 0 25px;
  }
`

const LabelContainer = styled.div`
  margin-top: 4.4rem;
`

const Center = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 7rem 0;
  width: 100%;
  max-width: 995px;
  margin: 5.5rem auto 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background: ${color.background.green10};
  @media (max-width: 767px) {
    width: auto;
    margin: 0 25px;
    padding: 7rem 25px 5rem;
  }
`
const BtnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;

  & div {
    ${props =>
      props.hasError &&
      `
        border-color: ${color.support.red};
        color: ${color.support.red};
        `}
  }
`

import React, { useState } from "react"
import styled from "styled-components"
import arrow from "../../white-co-images/down-arrow.svg"
import q from "../../white-co-images/q.svg"
import a from "../../white-co-images/answerFaq.svg"
import color from "../../style/color"

interface QuestionProps {
  children: React.ReactNode
  question: string
  last?: boolean
  first?: boolean
  toggleAnswer(): void
  isOpen: boolean
  answer: string
}
interface ContainerProps {
  last?: boolean
  first?: boolean
  answer: string
}

const FaqQuestion = ({
  question,
  answer,
  last,
  first,
  isOpen,
  toggleAnswer,
}: QuestionProps) => {
  return (
    <Container last={last} first={first}>
      <Question isOpen={isOpen} onClick={toggleAnswer}>
        <p>
          <img src={q} alt="Question icon" />
          {question}
        </p>
        <FaqButton isOpen={isOpen} src={arrow} alt="Arrow" />
      </Question>
      <AnswerContainer isOpen={isOpen}>
        <img src={a} alt="Answer icon" />
        <Answer isOpen={isOpen} dangerouslySetInnerHTML={{ __html: answer }} />
      </AnswerContainer>
    </Container>
  )
}

const FaqButton = styled.img`
  width: 18px;
  transition: all 0.2s ease;
  margin-top: 3px;
  ${props => props.isOpen && `transform: rotate(180deg)`};

  @media (max-width: 600px) {
    margin-top: 6px;
  }
`

const AnswerContainer = styled.div<IAnswerProps>`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
  transition: all 0.3s ease;

  display: flex;
  padding: 0 31px;
  ${props => props.isOpen && `max-height: 1000px;`}
  & img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }

  @media (max-width: 1023px) {
    padding: 0 25px;
  }
`

const Container = styled.div<ContainerProps>`
  width: 100%;
  &:nth-of-type(1) {
    border-top: 1px solid #eee;
  }
  &:last-of-type {
    border-bottom: 1px solid ${color.keyline.grey};
  }
`

const Question = styled.div`
  padding: 48px 31px;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  border-top: 1px solid ${color.keyline.grey};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  align-items: center;
  text-decoration: none;
  color: #0b1033;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  cursor: pointer;

  @media (max-width: 1023px) {
    padding: 38px 25px;
    align-items: flex-start;
  }

  & p {
    display: flex;
    align-items: center;
    color: black;
    width: 100%;
    font-weight: 400;

    @media (max-width: 1023px) {
      align-items: flex-start;
    }
    & img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
  }
  @media (max-width: 1023px) {
    font-size: 15px;
    line-height: 25px;
    padding: 38px 25px;
    & p {
      width: 80%;
      @media (max-width: 767px) {
        display: flex;
      }
    }
  }
  @media (max-width: 850px) {
    padding: 38px 25px;
  }
`

interface IAnswerProps {
  isOpen: boolean
}

const Answer = styled.div<IAnswerProps>`
  padding: 0 40px 34px 0;
  @media only screen and (max-width: 1024px) {
    padding: 0 40px 34px 0;
  }

  & p {
    margin-bottom: 0px;
  }
`

export default FaqQuestion

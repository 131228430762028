import React from "react"
import styled from "styled-components"
import color from "../../style/color"

const Table = ({ data }) => {
  return (
    <Container>
      <TopRow>
        <p>Treatment cost</p>
        <p>Term</p>
        <p>APR</p>
        <p>Deposit</p>
        <p>Monthly repayment</p>
      </TopRow>
      {data.relationships.field_finance_row.map((field, i) => {
        return (
          <React.Fragment key={i}>
            <Row>
              <p>{field.field_treatment_cost}</p>
              <p>{field.field_term}</p>
              <p>{field.field_apr}</p>
              <p>{field.field_deposit}</p>
              <p>{field.field_monthly_repayment}</p>
            </Row>
            <RepaymentMobile>
              <div>Monthly Repayment</div>
              <p>{field.field_monthly_repayment}</p>
            </RepaymentMobile>
          </React.Fragment>
        )
      })}
    </Container>
  )
}

const RepaymentMobile = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    & div {
      flex: 1;
      width: 100%;
      font-size: 13px;
      font-weight: 700;
      padding: 4px 0;
      background: ${color.background.green20};
      text-align: center;
    }

    & p {
      text-align: center;
      flex: 1;
      width: 100%;
      background: ${color.background.green10};
      font-size: 13px;
      padding: 10px 0;
    }
  }
`

const Row = styled.div`
  transition: all 0.5s ease-in;

  &:hover {
    background-color: ${color.background.tableHighlight} !important;
    cursor: pointer;
  }
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${color.background.grey};

  & p {
    flex: 1;
    padding: 17px 0;
    text-align: center;

    &:last-of-type {
      background: ${color.background.green10};

      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      font-size: 13px;
      padding: 11px 0;
    }
  }
`

const TopRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${color.background.grey};
  background: ${color.background.green10};

  & p {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 25px 15px;
    text-align: center;
    border-right: 1px solid white;

    &:last-of-type {
      background: ${color.background.green20};

      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 23px;
      padding: 5px 8px;
    }
  }
`

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`

export default Table

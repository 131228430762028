import React from "react"
import styled from "styled-components"
import VerticalPadding from "../../helper/VerticalPadding"
import SmallSection from "../../helper/SmallSection"

const Sitemap = ({ links }) => {
  return (
    <VerticalPadding>
      <SmallSection>
        <Inner>
          <HeadingContainer data-testid="heading-container">
            <h2 data-testid="sitemap-title">Sitemap</h2>
          </HeadingContainer>
          <Container>
            {links.map((link, i) => {
              return (
                <div
                  data-testid="sitemap-link"
                  key={i}
                  dangerouslySetInnerHTML={{ __html: link.value }}
                />
              )
            })}
          </Container>
        </Inner>
      </SmallSection>
    </VerticalPadding>
  )
}

const Inner = styled.div`
  width: 100%;
`

const HeadingContainer = styled.div`
  text-align: center;
  margin-bottom: 10rem;

  @media (max-width: 767px) {
    margin-bottom: 5rem;
  }

  & h2 {
    font-weight: 300 !important;
    font-family: headingFontLight;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & p {
    font-size: 2rem;
    line-height: 3rem;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  & div {
    // margin-right: 70px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & div {
      margin-right: 0px;
    }
  }
`

export default Sitemap

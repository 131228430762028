import React from "react"
import styled from "styled-components"

interface IVideo {
  link: string
}
const InvisalignVideo = ({ link }: IVideo) => {
  return (
    <IframeContainer>
      <iframe
        width="100%"
        height="100%"
        src={link}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </IframeContainer>
  )
}

const IframeContainer = styled.div`
  width: 100%;
  height: 40vw;
  position: relative;
  overflow: hidden;
  padding: 0 5rem 10rem;
  & iframe {
    border: 0;
  }

  @media (max-width: 1023px) {
    min-height: 300px;
    padding: 0 25px 50px;
  }
  @media (max-width: 767px) {
    min-height: 250px;
  }
`

export default InvisalignVideo

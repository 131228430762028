import React, { useEffect } from "react"
import styled from "styled-components"
import StepNumber from "./StepNumber"
import { StepHeading, Text } from "./Step"
import FullWidthContainer from "../../../helper/FullWidthContainer"
import Button from "../../../shared/Button/Button"
import color from "../../../style/color"
import { isInViewport, scrollPage, throttled } from "../../../util/functions"
import SectionHeading from "../../../shared/SectionHeading/SectionHeading"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"

interface ILastStep {
  imageDesktop: string
  imageMobile: string
  stepNumber?: number
  heading?: string
  text: string
  ctaUrl1?: string
  ctaText1?: string
  ctaUrl2?: string
  ctaText2?: string
  id: string
  greenBackground: boolean
}
const LastStep = ({
  imageDesktop,
  imageMobile,
  stepNumber,
  heading,
  text,
  ctaUrl1,
  ctaUrl2,
  ctaText1,
  ctaText2,
  id,
  greenBackground,
}: ILastStep) => {
  return (
    <Container greenBackground={greenBackground}>
      <FullWidthContainer noPaddingMobile noPaddingLeft noPaddingTablet>
        <InnerContainer>
          <Col>
            <Controller>
              <Scene duration="100%">
                <Timeline wrapper={<div className="parallax-treatment" />}>
                  <Tween
                    position="0"
                    from={{
                      scale: 1,
                    }}
                    to={{
                      scale: 1.1,
                    }}
                  >
                    <Image
                      imageDesktop={imageDesktop}
                      imageMobile={imageMobile}
                      id={id}
                    />
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>
          </Col>
          <Col>
            <TextContainer>
              {heading && (
                <HeadingContainer>
                  <SectionHeading heading={heading} leftAlign />
                </HeadingContainer>
              )}
              {text && <Text>{text}</Text>}
              <BtnContainer>
                <Button primary href={ctaUrl1} text={ctaText1} />
                <SecondBtnWrapper>
                  <Button
                    onClick={() => scrollPage("page-form")}
                    text={"Enquire now"}
                    type="secondary"
                  />
                </SecondBtnWrapper>
              </BtnContainer>
            </TextContainer>
          </Col>
        </InnerContainer>
      </FullWidthContainer>
    </Container>
  )
}

export default LastStep

const HeadingContainer = styled.div`
  margin-top: 20px;
`

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 1199px) {
    flex-direction: column;
    height: auto;
  }
`

const Container = styled.div`
  width: 100%;

  background: #ffffff;
  display: flex;
  justify-content: center;
  align-content: center;
  ${props =>
    props.greenBackground && `background: ${color.background.green10};`}

  @media (max-width: 1199px) {
    // border-bottom: 1px solid ${color.keyline.grey};
  }
`
const Col = styled.div`
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;

  & div:first-of-type {
    width: 100%;
  }
  @media (max-width: 1199px) {
    width: 100%;
    &:first-of-type {
    }
  }
`

interface IImage {
  imageDesktop: string
  imageMobile: string
}
const Image = styled.div<IImage>`
  background-image: url(${props => props.imageDesktop && props.imageDesktop});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  height: 100%;
  min-height: 750px;
  max-height: 750px;
  width: 100%;
  align-items: center;
  transform-style: preserve-3d;
  transition: all 2s ease;

  @media (max-width: 1199px) {
    min-height: 380px;
    height: 380px;
  }
`

const TextContainer = styled.div`
  width: 100%;
  max-width: 64rem;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  padding: 14rem 0 14rem 60px;
  @media (max-width: 1199px) {
    padding-left: 0px;
    padding: 40px 0 45px 0;
    width: 90%;
    margin: 0 auto;
    max-width: 90%;
    padding-bottom: 61px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    padding: 40px 25px 50px;
  }
`

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) {
    margin-top: 3rem;
  }
`
const SecondBtnWrapper = styled.div`
  margin-top: 1rem;
`
/*   animation: ${props =>
    props.scaleImage
      ? "zoomInImage 5s ease forwards"
      : "zoomInImage 5s ease backwards"}; */

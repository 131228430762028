import React, { useEffect } from "react"
import styled from "styled-components"
import { isInViewport, throttled } from "../../util/functions"
import gumLine from "../../white-co-images/gum-line.svg"
import gum from "../../white-co-images/gum.svg"
import screw from "../../white-co-images/screw.svg"
import cap from "../../white-co-images/cap.svg"
import green from "../../white-co-images/green.svg"
import crown from "../../white-co-images/crown.svg"
import StepsContainer from "./Steps/StepsContainer"
import color from "../../style/color"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import LastStep from "./Steps/LastStep"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"

interface ToothAnimationMobileProps {
  topTextAnimated?: string
  sectionHeading?: string
  subHeading?: string
  imageDesktop?: string
  imageMobile?: string
  stepNumber?: number
  heading?: string
  text?: string
  ctaUrl1?: string
  ctaText1?: string
}

const ToothAnimationMobile = ({
  sectionHeading,
  subHeading,
  topTextAnimated,
  imageDesktop,
  imageMobile,
  stepNumber,
  heading,
  text,
  ctaUrl1,
  ctaText1,
  steps,
}: ToothAnimationMobileProps) => {
  useEffect(() => {
    const tooth: HTMLElement | null = document.getElementById(
      `anim-container-mobile`
    )
    const screw: HTMLElement | null = document.getElementById(`screw-mob`)
    const gum: HTMLElement | null = document.getElementById(`gum-mob`)
    const cap: HTMLElement | null = document.getElementById(`cap-mob`)
    const green: HTMLElement | null = document.getElementById(`green-mob`)
    const crown: HTMLElement | null = document.getElementById(`crown-mob`)
    const text: HTMLElement | null = document.getElementById(`text-mob-tooth`)

    const animateElementMobile = (
      elem: HTMLElement | null,
      animationName: string,
      timingFunction: string,
      duration: string,
      delay?: string
    ) => {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        if (tooth) {
          if (isInViewport(tooth)) {
            if (elem) {
              elem.style.animation = `${animationName} ${duration} ${timingFunction} forwards ${delay}`
            }
          } else if (elem) {
            elem.style.animationName = ""
          }
        }
      }
    }

    const eventListener = throttled(200, () => {
      animateElementMobile(screw, "animateScrew", "ease", "5s", "0s")
      animateElementMobile(cap, "animateCap", "ease", "3s", "1s")
      animateElementMobile(green, "animateGreen", "ease", "3s", "4s")
      animateElementMobile(crown, "animateGreen", "ease", "3s", "5s")
      animateElementMobile(gum, "animateGum", "ease", "2s", "2s")
      animateElementMobile(
        text,
        "animateTextOpacityMobile",
        "linear",
        "1s",
        "6s"
      )
    })

    window.addEventListener("scroll", eventListener)

    return () => {
      window.removeEventListener("scroll", eventListener)
    }
  }, [])

  return (
    <Outer>
      <HeadingContainer>
        {subHeading && (
          <SubHeadingContainer>
            <SmallHeading heading={subHeading} marginBottom />
          </SubHeadingContainer>
        )}
        {sectionHeading && <SectionHeading heading={sectionHeading} />}
      </HeadingContainer>

      <Container>
        <Animation id="anim-container-mobile">
          <StickyContainer>
            <StepsImages>
              <Screw
                id="screw-mob"
                src={screw}
                data-testid="screw-mob"
                alt="Screw"
              />
              <Gum id="gum-mob" src={gum} data-testid="gum-mob" alt="Gum" />
              <Cap id="cap-mob" src={cap} data-testid="cap-mob" alt="Cap" />
              <Green
                id="green-mob"
                src={green}
                data-testid="green-mob"
                alt="Green part"
              />
              <Crown
                id="crown-mob"
                src={crown}
                data-testid="crown-mob"
                alt="Crown"
              />
            </StepsImages>
            <GumLine
              id="tooth-mob"
              src={gumLine}
              data-testid="tooth-mob"
              alt="Gum line"
            />
          </StickyContainer>
        </Animation>
        <StepsContainer id="stepsMobile" steps={steps} />
      </Container>
      <LastStep
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        stepNumber={stepNumber}
        heading={heading}
        text={text}
        ctaUrl1={ctaUrl1}
        ctaText1={ctaText1}
        id={"lastStepMob"}
      />
    </Outer>
  )
}

export default ToothAnimationMobile

const HeadingContainer = styled.div`
  padding: 0 25px;
`

const Outer = styled.div`
  background: ${color.background.blueBackground};
  display: none;
  @media (max-width: 1199px) {
    display: block;
    padding: 50px 0 0;
    height: auto;
  }
`
const Container = styled.div`
  width: 92%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: none;
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    padding: 0 25px;
    height: 100%;
    background: ${color.background.blueBackground};
  }
`
const SubHeadingContainer = styled.div``
const Text = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${color.text.secondary};
  text-align: center;
  opacity: 0;
`

const Animation = styled.div`
  padding: 45px 0 50px;
  display: flex;
  justify-content: center;
  @media (max-width: 1199px) {
    padding-bottom: 0;
  }
`
const StickyContainer = styled.div`
  left: 0;
  display: flex;
  flex-direction: column;
`
const GumLine = styled.img`
  width: 100%;
  position: relative;
`
const StepsImages = styled.div`
  position: relative;
  width: 100%;
`

const Screw = styled.img`
  width: 100%;
  position: absolute;
  left: 0%;
  top: -120px;
  right: 0%;
  bottom: 0%;
  margin: 0 auto;
  transition: all ease 0.5s;
  z-index: 5;
  transform-style: preserve-3d;
`
const Cap = styled(Screw)`
  z-index: 4;
  opacity: 0;
  top: -120px;
`
const Green = styled(Screw)`
  top: -120px;
  opacity: 0;
`

const Crown = styled(Green)``

const Gum = styled.img`
  position: absolute;
  top: 0px;
  left: 0;
  transition: opacity ease 0.3s;
  z-index: 2;
  width: 100%;
  opacity: 1;
`

import React from "react"
import ToothSplit from "./ToothSplit"
import { graphql } from "gatsby"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"

const ToothSplitDrupal = ({ node }) => {
  const { artificial, abutment, implant, sectionHeading, subheading } =
    node || {}
  const detailsLeftSide = [
    { heading: "Natural tooth crown" },
    { heading: "Gum tissue" },
    { heading: "Jawbone" },
  ]

  const detailsRightSide = [
    { heading: "Artificial tooth crown", text: artificial },
    { heading: "Abutment", text: abutment },
    { heading: "Dental implant", text: implant },
  ]

  return (
    <Container>
      <HeadingContainer>
        <SmallHeading heading={subheading} marginBottom />
        <SectionHeading heading={sectionHeading} />
      </HeadingContainer>
      <ToothSplit
        leftHeading={"Natural tooth"}
        rightHeading={"Dental implant"}
        detailsLeftSide={detailsLeftSide}
        detailsRightSide={detailsRightSide}
      />
    </Container>
  )
}

const HeadingContainer = styled.div`
  text-align: center;
  padding-top: 10rem;

  @media (max-width: 767px) {
    padding: 50px 25px 0;
  }
`

const Container = styled.div``

export const fragment = graphql`
  fragment ToothSplit on paragraph__dental_implant_tooth_diagram {
    id
    sectionHeading: field_section_heading
    subheading: field_top_text
    artificial: field_artificial_tooth_crown_des
    abutment: field_abutment_description
    implant: field_dental_implant_description
  }
`

export default ToothSplitDrupal

import React from "react"
import styled from "styled-components"
import { formatLink } from "../../util/functions"
import { Breadcrumb, Link, Icon } from "../../shared/Breadcrumb/Breadcrumb"
import Button from "../../shared/Button/Button"
import StandardSection from "../../helper/StandardSection"
import color from "../../style/color"

interface ICategoryHeader {
  sectionHeading?: string
  sectionBody?: string
  imageMobile?: string
  imageDesktop?: string
  ctaText?: string
  ctaUrl?: string
  breadcrumb: any
}

const CategoryHeader = ({
  sectionHeading,
  sectionBody,
  imageMobile,
  imageDesktop,
  breadcrumb,
  ctaText,
  ctaUrl,
}: ICategoryHeader) => {
  return (
    <>
      <MainContainer imageMobile={imageMobile} imageDesktop={imageDesktop}>
        <StandardSection noPaddingSmallTablet noPaddingMob>
          {sectionHeading && (
            <TextContainer>
              {sectionHeading && (
                <SectionHeading data-testid="category-header">
                  {sectionHeading}
                </SectionHeading>
              )}
              {sectionBody && (
                <Body data-testid="category-body" noPaddingBottom={!ctaText}>
                  {sectionBody}
                </Body>
              )}
              {ctaText && <Button text={ctaText} href={ctaUrl} />}
            </TextContainer>
          )}
        </StandardSection>
      </MainContainer>
      {breadcrumb && (
        <StandardSection>
          <Breadcrumb noPadding>
            {breadcrumb.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <Link
                    href={
                      key !== breadcrumb.length - 1
                        ? formatLink(item.uri)
                        : null
                    }
                  >
                    {item.title}
                  </Link>
                  <span>|</span>
                </React.Fragment>
              )
            })}
          </Breadcrumb>
        </StandardSection>
      )}
    </>
  )
}

const MainContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  background: ${color.brand.blue};
  background-image: url(${props => props.imageDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 140px 0 155px;
  display: flex;
  align-items: center;
  min-height: 400px;
  position: relative;

  @media (max-width: 1200px) {
    background-position: center;
  }
  @media (max-width: 1023px) {
    padding: 200px 0 0px;
    align-items: flex-end;
  }
  @media (max-width: 767px) {
    background-image: url(${props => props.imageMobile});
    min-height: 350px;
  }
`
const TextContainer = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: 1024px) {
 top:95px;
 color:white;
 padding:5px;
 margin-top:20px;
  }
  @media (max-width: 1023px) {
    text-align: center;
    background: rgba(255, 255, 255, 0.65);
    padding: 25px;
  }
`

const SectionHeading = styled.h1`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 48rem;
  font-family: headingFontLight;
  font-weight: 300;
  @media (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: 34px;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 5px;
  }
`
interface IBody {
  noPaddingBottom?: boolean
}
const Body = styled.p<IBody>`
  width: 100%;
  max-width: 38rem;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 300;
  margin-bottom: ${props => (props.noPaddingBottom ? "0" : "3rem")};

  @media (max-width: 1023px) {
    max-width: 100%;
    margin-bottom: ${props => (props.noPaddingBottom ? "0" : "20px")};
  }
`

export default CategoryHeader

import React from "react"
import styled from "styled-components"
import StandardSection from "../../helper/StandardSection"
import VerticalPadding from "../../helper/VerticalPadding"
import color from "../../style/color"
import Img from "gatsby-image"
import GoogleMap from "../GoogleMap/GoogleMap"
import { isMobile } from "react-device-detect"
import whatsAppIcon from "../../white-co-images/whatsapp_colour.svg"
import whatsAppIconHovered from "../../white-co-images/whatsapp.svg"
import { IconsWrapper, SIcon, SIconHovered } from "../../shared/Footer/Footer"
import { trackWhatsApp, trackButtonClick } from "../../util/functions"

interface DetailsProps {
  practiceName: string
  address: string
  postcode: string
  email: string
  body: string
  openingTimes: Array<any>
  howToFindUs: Array<any>
  phone: string
  logoAlt?: string
  logo?: string
  sources: any
  iconFluid: any
  whatsApp: any
}

const toggleIcon = (id: string, on: boolean) => {
  const icon: HTMLElement | null = document.getElementById(id)
  if (icon) {
    if (!on) {
      icon.style.display = "block"
    } else {
      icon.style.display = "none"
    }
  }
}

const PracticeDetails = ({
  practiceName,
  address,
  postcode,
  email,
  phone,
  body,
  logoAlt,
  logo,
  openingTimes,
  howToFindUs,
  sources,
  iconFluid,
  whatsApp,
}: DetailsProps) => {
  return (
    <VerticalPadding>
      <Container>
        <StandardSection>
          <TopRow>
            <div>
              <h2>{practiceName}</h2>
              <p>
                {address}
                <span>{postcode}</span>
              </p>
              <a href={`mailto:${email}`}>{email}</a>
              {logo && (
                // <BlueBackground>
                <Logo src={logo} alt={logoAlt} />
                // </BlueBackground>
              )}
            </div>
            <div>
              <a
                onClick={() =>
                  trackButtonClick("Phone Number Click: Practice Details")
                }
                href={`tel:${phone}`}
              >
                {phone}
              </a>

              <Section>
                {/* @TODO This is a hot patch just to get to the booking form with a unquie code. I wouldn't recomend expanding it. If we need to do another lets refactor  */}
                {practiceName === "Battersea Power Station" ? (
                  <>
                    <a
                      href="https://uk.dentalhub.online/soe/new/White%20%26%20Co.%20Dental?pid=UKANP06"
                      // href="https://onlinebookinguk.3pointdata.com/soe/new/White %26 Co.?pid=UKBAE02"
                      target="_blank"
                      style={{ fontSize: 16, margin: 0 }}
                    >
                      Book Now
                    </a>
                    <br />
                  </>
                ) : (
                  ""
                )}

                {/*End of hot patch*/}
                {body}
              </Section>
              <strong>Opening times</strong>
              <section>
                {openingTimes.map((op, i) => {
                  return (
                    <OpeningTimes key={i}>
                      <p>{op.days}</p>
                      <p>{op.times}</p>
                    </OpeningTimes>
                  )
                })}
              </section>
              {whatsApp && isMobile && (
                <WhatsAppContainer>
                  <a
                    href={`${whatsApp.uri}?text=${encodeURI(whatsApp.title)}`}
                    target="_blank"
                  >
                    <WAWrapper
                      onClick={trackWhatsApp}
                      onMouseEnter={() =>
                        !isMobile && toggleIcon("whatsapp-practice", true)
                      }
                      onTouchStart={() =>
                        isMobile && toggleIcon("whatsapp-practice", true)
                      }
                      onMouseLeave={() =>
                        toggleIcon("whatsapp-practice", false)
                      }
                      onTouchEnd={() => toggleIcon("whatsapp-practice", false)}
                    >
                      <SIconHovered src={whatsAppIcon} alt={"whatsApp"} />
                      <SIcon
                        id={"whatsapp-practice"}
                        src={whatsAppIcon}
                        alt={"whatsApp"}
                      />
                      <p>Enquire with WhatsApp</p>
                    </WAWrapper>
                  </a>
                </WhatsAppContainer>
              )}
            </div>
          </TopRow>
        </StandardSection>
        <GoogleMap place={`${address}, ${postcode}, UK`} />

        <StandardSection>
          <HowToFindUs>
            <h2>How to find us</h2>
            <Grid>
              {howToFindUs.map((how, i) => {
                return (
                  <Card key={i}>
                    <h4>{how.title}</h4>
                    <p>{how.text}</p>
                  </Card>
                )
              })}
            </Grid>
          </HowToFindUs>
        </StandardSection>
      </Container>
    </VerticalPadding>
  )
}

const WAWrapper = styled.div`
  display: flex;
  position: relative;

  &:link,
  &:visited {
    text-decoration: none;
  }

  & p {
    margin-left: 40px;
    text-decoration: none;
  }
`

const WhatsAppContainer = styled.div`
  & a,
  &:link,
  a:visited {
    text-decoration: none;
    color: ${color.text};
  }
`

const Section = styled.section`
  margin-bottom: 5rem;
  @media (max-width: 1023px) {
    margin-bottom: 23px;
  }
`

const HowToFindUs = styled.div`
  margin-top: 7.2rem;
  width: 100%;
  & h2 {
    text-align: center;
    @media (max-width: 1023px) {
      text-align: left;
    }
  }
  @media (max-width: 1023px) {
    margin-top: 37px;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 4.4rem;
  grid-row-gap: 4.4rem;
  margin-top: 5.7rem;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
  }
`
const Card = styled.div`
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
`
const OpeningTimes = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  padding: 15px 0;
  border-bottom: 1px solid ${color.background.grey};
`

const Container = styled.div`
  width: 100%;

  & h2,
  h4 {
    font-weight: 300;
    font-family: headingFontLight;
  }
`

const TopRow = styled.div`
  display: flex;
  margin-bottom: 10rem;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1023px) {
    margin-bottom: 50px;
    margin-right: 0;
    width: 100%;
  }

  & h2 {
    margin-bottom: 2rem;

    @media (max-width: 1023px) {
      padding-right: 40px;
    }
  }
  & span {
    white-space: nowrap;
  }
  & > div {
    width: 100%;
    max-width: 45%;
    & a:link,
    & a:visited,
    & a:active {
      display: inline-block;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 27px;

      @media (max-width: 1023px) {
        margin-bottom: 28px;
        margin-top: 30px;
      }
      @media (max-width: 359px) {
        font-size: 35px;
        line-height: 45px;
      }
    }
    @media (max-width: 1023px) {
      width: 100%;
      max-width: 100%;
    }

    &:nth-of-type(1) {
      width: 100%;
      max-width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 1023px) {
        max-width: 100%;
        margin-right: 0;
      }

      & p {
        font-size: 22px;
        line-height: 32px;

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 28px;
        }
        @media (max-width: 359px) {
          font-size: 15px;
          line-height: 25px;
        }
      }

      & a:link,
      & a:visited,
      & a:active {
        display: inline-block;
        font-size: 16px;
        line-height: 26px;
        margin-top: 5px;
        @media (max-width: 1023px) {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    max-width: 100%;
  }
`
const BlueBackground = styled.div`
  display: flex;
  align-items: center;
  background: ${color.brand.blue};
  padding: 5rem 3.6rem;
`
const Map = styled(Img)`
  display: block;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
`

const Logo = styled.img`
  // height: 10rem;
  @media (max-width: 767px) {
    height: 74px;
  }
`
export default PracticeDetails

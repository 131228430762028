import React from "react"
import RadioBtn from "../RadioButton"
import Btn from "../../../shared/Button/Button"
import styled from "styled-components"
import Label from "../Label"

interface StepProps {
  changeStep: any
  howSoon: string
  setHowSoon: any
}

const InvisalignStep3 = ({ changeStep, howSoon, setHowSoon }: StepProps) => {
  return (
    <Form>
      <Inner>
        <Label label="How soon would you like to start the treatment?" />
        <BtnsContainer>
          <RadioBtn
            onClick={() => {
              setHowSoon("Immeadiate")
            }}
            isActive={howSoon === "Immeadiate"}
          >
            Immediate
          </RadioBtn>
          <RadioBtn
            onClick={() => {
              setHowSoon("In the next 3 months")
            }}
            isActive={howSoon === "In the next 3 months"}
          >
            In the next 3 months
          </RadioBtn>
          <RadioBtn
            onClick={() => {
              setHowSoon("Later this year")
            }}
            isActive={howSoon === "Later this year"}
          >
            Later this year
          </RadioBtn>
          <RadioBtn
            onClick={() => {
              setHowSoon("Just looking")
            }}
            isActive={howSoon === "Just looking"}
          >
            Just looking
          </RadioBtn>
        </BtnsContainer>
      </Inner>
    </Form>
  )
}

const BtnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default InvisalignStep3

const Form = styled.div`
  @media (max-width: 767px) {
  }
`

const Inner = styled.div`
  width: 92%;
  max-width: 995px;
  margin: 0 auto;
  padding-bottom: 6.5rem;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 25px 6.5rem;
  }
`

import React from "react"
import HalfAndHalf from "./HalfAndHalf"
import { graphql } from "gatsby"
import { get, formatLink } from "../../util/functions"

const HalfAndHalfDrupal = ({ node }) => {
  const { relationships } = node || {}
  const { field_half_and_half } = relationships || {}

  const data = field_half_and_half.map(card => {
    return {
      heading: card.field_section_heading,
      subHeading: card.field_subheading,
      ctaText: card.field_cta && card.field_cta.title,
      ctaUrl: card.field_cta && formatLink(card.field_cta.uri),
      flip: card.field_flip,
      text: card.field_section_body,
      imageDesktop: get(
        ["relationships", "field_image_desktop", "localFile", "publicURL"],
        card,
        ""
      ),
      imageMobile: get(
        ["relationships", "field_image_mobile", "localFile", "publicURL"],
        card,
        ""
      ),
      pillText: card.field_category,
      altDesktop: card.field_image_desktop.alt,
      altMobile: card.field_image_mobile.alt,
      sources: [
        {
          media: `(min-width: 767px)`,
          ...card.relationships.field_image_desktop.localFile.childImageSharp
            .fluid,
        },
        {
          ...card.relationships.field_image_mobile.localFile.childImageSharp
            .fluid,
          media: `(max-width: 767px)`,
        },
      ],
    }
  })
  return <HalfAndHalf data={data} />
}

export const fragment = graphql`
  fragment HalfAndHalf on paragraph__half_and_half_section {
    id
    relationships {
      field_half_and_half {
        field_flip
        field_section_body
        field_section_heading
        field_subheading
        field_cta {
          title
          uri
        }
        field_category
        field_image_desktop {
          alt
        }
        field_image_mobile {
          alt
        }
        relationships {
          field_image_desktop {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 864, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          field_image_mobile {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 767, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HalfAndHalfDrupal

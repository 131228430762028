import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../../shared/Button/Button"
import { get } from "../../util/functions"
import "./blogPosts.css"
import VerticalPadding from "../../helper/VerticalPadding"
import { scrollTo } from "../../util/functions"
import { AnchorBar } from "../../style/scrollTo"
import styled from "styled-components"
import SectionHeading from "../../shared/SectionHeading/SectionHeading"
import SmallSection from "../../helper/SmallSection"

const BlogPosts = ({ node }) => {
  const data = useStaticQuery(graphql`
    query allBlogPosts {
      allNodeBlog {
        edges {
          node {
            created
            field_preview_heading
            fields {
              slug
            }
            field_preview_text
            field_preview_image {
              alt
            }
            relationships {
              field_preview_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const desktopLimit = 120
  const mobileLimit = 80
  const headingCharLimit = 72

  const [charLimit, setCharLimit] = useState(desktopLimit)
  const [headingLimit, setHeadingLimit] = useState(headingCharLimit)
  const [postLimit, setPostLimit] = useState(14)

  const checkCharLimit = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 1200 && window.innerWidth >= 768) {
        setCharLimit(mobileLimit)
      } else {
        setCharLimit(desktopLimit)
      }

      if (window.innerWidth < 768) {
        setPostLimit(7)
      } else {
        setPostLimit(14)
      }
    }
  }

  useEffect(() => {
    checkCharLimit()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkCharLimit)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", checkCharLimit)
      }
    }
  }, [])

  const blogPosts = [...data.allNodeBlog.edges]
  const { sectionHeading, body } = node

  const formatText = text => {
    if (text.length < charLimit) {
      return text
    } else {
      return text.substr(0, charLimit) + "..."
    }
  }

  const formatHeading = text => {
    if (text.length < headingLimit) {
      return text
    } else {
      return text.substr(0, headingLimit) + "..."
    }
  }

  const [showMore, toggleShowMore] = useState(false)
  const id = "blog-posts" + Math.random()
  return (
    <VerticalPadding>
      <div>
        <SmallSection>
          <TextContainer>
            <SectionHeading heading={sectionHeading} />
            <Body>{body}</Body>
          </TextContainer>
        </SmallSection>
        <BlogContainer>
          <AnchorBar id={id} />

          <PostContainer>
            {blogPosts.map((post, i) => {
              const postLink = get(["fields", "slug"], post.node, "")
              if (
                postLink &&
                postLink !== "null" &&
                postLink !== "/blog/test-page"
              ) {
                if (i < postLimit || showMore) {
                  const link =
                    (process.env.BASE === "/" ? "" : process.env.BASE) +
                    postLink
                  const imageAlt = get(
                    ["field_preview_image", "alt"],
                    post.node,
                    ""
                  )
                  const image = get(
                    [
                      "relationships",
                      "field_preview_image",
                      "localFile",
                      "publicURL",
                    ],
                    post.node,
                    ""
                  )

                  return (
                    <Post key={i} i={i}>
                      <PostImageContainer>
                        <img src={image} alt={imageAlt} />
                      </PostImageContainer>
                      <PostTextContainer>
                        <h3>
                          {formatHeading(post.node.field_preview_heading)}
                        </h3>
                        <p>{formatText(post.node.field_preview_text)}</p>
                        <PostCtaContainer>
                          <Button fullWidth text="Read more" href={link} />
                        </PostCtaContainer>
                      </PostTextContainer>
                    </Post>
                  )
                }
              }
            })}
          </PostContainer>
          {blogPosts.length >= postLimit && (
            <ShowMoreContainer>
              <Button
                type="secondary"
                text={showMore ? "Show less" : "Show more"}
                onClick={() => {
                  toggleShowMore(!showMore)
                  if (showMore) {
                    scrollTo(id)
                  }
                }}
              />
            </ShowMoreContainer>
          )}
        </BlogContainer>
      </div>
    </VerticalPadding>
  )
}

export default BlogPosts

const Body = styled.p`
  margin-top: 1.7rem;
`
const BlogContainer = styled.div``

const TextContainer = styled.div`
  margin-bottom: 7.2rem;

  text-align: center;

  @media only screen and (max-width: 767px) {
    margin-bottom: 32px;
  }
`
const PostContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 92%;
  max-width: 1200px;

  @media (max-width: 1023px) {
    width: auto;
    padding: 0 25px;
  }
`
interface IPost {
  i: number
}
const Post = styled.div<IPost>`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  margin-bottom: 30px;
  &:nth-of-type(1) {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-right: 0;
  }
  &:nth-of-type(7n + 1) {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-right: 0;
    @media (max-width: 767px) {
      flex-direction: column;
      height: 100%;
    }
  }
  &:nth-of-type(1) img {
    height: 100%;
    width: 100%;
  }

  //image container
  &:nth-of-type(7n + 1) > div:nth-child(1) {
    height: 100%;
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
      height: 215px;
    }
  }

  // text container
  &:nth-of-type(7n + 1) > div:nth-child(2) {
    padding: 70px 58px 70px 40px;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
      padding: 25px 25px 40px;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &:nth-of-type(7n),
  &:nth-of-type(7n - 3) {
    margin-right: 0;
  }

  &:nth-of-type(7n + 1) h3 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 5px;
  }

  // cta container
  &:nth-of-type(7n + 1) > div:nth-child(2) > div {
    max-width: 300px;
    margin-top: 0;
  }
  @media (max-width: 1023px) {
    &:nth-of-type(7n + 1) h3 {
      font-size: 20px;
      line-height: 30px;
    }

    width: calc(100% / 3 - 10px);
    margin-right: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`
const PostImageContainer = styled.div`
  width: 100%;
  height: 220px;

  & img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    height: 160px;
  }
  @media (max-width: 767px) {
    height: 215px;
  }
`

const PostTextContainer = styled.div`
  background-color: rgba(80, 227, 194, 0.1);
  padding: 33px 30px 40px;
  display: flex;
  flex-direction: column;

  & p {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: #666666;
    margin-bottom: 22px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (max-width: 767px) {
      margin-bottom: 13px;
    }
  }

  & h3 {
    font-family: headingFontLight;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #333333;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 1023px) {
    padding: 20px 25px 28px;
  }
`
const PostCtaContainer = styled.div`
  margin-top: auto;
  width: 100%;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    max-width: 300px;
  }
`
const ShowMoreContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: 30px;

  @media (max-width: 767px) {
    padding: 0 25px;
    margin-top: 10px;
  }
`

export const fragment = graphql`
  fragment BlogPosts on paragraph__display_blog_posts {
    id
    sectionHeading: field_section_heading
    body: field_section_body
  }
`

import React from "react"
import styled from "styled-components"
import color from "../../../style/color"
import StepNumber from "./StepNumber"
import Pill from "../../../shared/Pill/Pill"

interface StepProp {
  heading?: string
  text?: string
  period?: string
  stepNumber?: number
  week?: string
  middleTime: string
}
const Step = ({
  heading,
  text,
  period,
  stepNumber,
  week,
  middleTime,
}: StepProp) => {
  return (
    <Container data-testid="step">
      <StepDetails>
        <PillContainer>
          <SlidePill>{period}</SlidePill>
        </PillContainer>
        {stepNumber && <TopDetails>{week && <Week>{week}</Week>}</TopDetails>}

        {heading && <StepHeading>{heading}</StepHeading>}
        {text && <Text>{text}</Text>}
      </StepDetails>

      {middleTime && (
        <MiddlePillContainer>
          <SlidePill>{middleTime}</SlidePill>
        </MiddlePillContainer>
      )}
    </Container>
  )
}

export default Step
const MiddlePillContainer = styled.div`
  display: flex;
  align-self: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: 1199px) {
    bottom: 11%;
  }
`
const Container = styled.div`
  height: 50vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  z-index: 7;
  position: relative;
  @media (max-width: 1199px) {
    height: auto;
    padding: 0;
    min-height: 400px;
  }
`

const StepDetails = styled.div`
  padding: 40px 0 10px;
  width: 100%;
  background: white;
  @media (max-width: 1199px) {
    padding: 20px 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0;
  }
`
const TopDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin: 5px 0 20px;
`
const Week = styled.div`
  background: rgba(8, 37, 72, 0.6);
  color: #fff;
  border-radius: 50px;
  padding: 0 10px;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

export const StepHeading = styled.h4`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.4rem;
  color: ${color.brand.blue};
`

export const Text = styled.p`
  margin: 15px 0 20px;
  color: ${color.brand.secondary};
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 1199px) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
  }
`

const StepPeriod = styled.div`
  position: absolute;
  bottom: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
`
const PillContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 25px;
`
const DentalPill = styled(Pill)`
  font-size: 13px;
  line-height: 23px;
`
const SlidePill = styled(Pill)`
  padding: 0 20px;
`

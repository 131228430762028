import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import StandardSection from "../../helper/StandardSection"
import arrowGrey from "../../white-co-images/largeArrowBlueInactive.svg"
import arrowGreen from "../../white-co-images/largeArrowBlue.svg"
import Img from "gatsby-image"

interface Slide {
  heading: string
  body: string
  imageDesktop: string
  imageDesktopAlt: string
  imageMobile: string
  imageMobileAlt: string
}

interface ExpProps {
  heading: string
  slides: Array<Slide>
}

interface DotProps {
  isActive: boolean
}

const ExperienceCarousel = ({ heading, slides }: ExpProps) => {
  const [blur, toggleBlur] = useState(false)
  const [clientScroll, setClientScroll] = useState(0)

  const [currentSlide, setCurrentSlide] = useState(0)
  const textSlides = slides.slice()

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      animateChangeSlide(currentSlide + 1)
    }
  }

  const prevSlide = () => {
    if (currentSlide > 0) {
      animateChangeSlide(currentSlide - 1)
    }
  }
  const animateChangeSlide = i => {
    toggleBlur(true)
    setTimeout(() => {
      setCurrentSlide(i)
      toggleBlur(false)
    }, 500)
  }

  const setClientX = e => {
    if (e) {
      const clientX = e.touches[0].clientX
      setClientScroll(clientX)
    }
  }

  const handleTouch = (e: any) => {
    if (e.changedTouches.length > 0) {
      const touchStart = e.changedTouches[0].clientX
      const touchEnd = clientScroll

      //get end touch coordinates
      if (touchStart !== touchEnd) {
        if (touchStart > touchEnd) {
          prevSlide()
        } else {
          nextSlide()
        }
      }
    }
  }

  return (
    <Container>
      <StandardSection>
        <InnerContainer onTouchStart={setClientX} onTouchEnd={handleTouch}>
          <TopRowContainer>
            <LeftArrow
              onClick={prevSlide}
              src={currentSlide === 0 ? arrowGrey : arrowGreen}
              alt="Left arrow"
            />
            <TextContainer>
              <h5>{heading}</h5>
              <div>
                <Heading blur={blur}>
                  {textSlides[currentSlide].heading}
                </Heading>
                <Body data-testid="carousel-text" blur={blur}>
                  {textSlides[currentSlide].body}
                </Body>
              </div>
            </TextContainer>
            <ImageContainer>
              {slides.map((slide, i: number) => {
                return (
                  <div key={i}>
                    {slide.sources && (
                      <Image
                        data-testid="carousel-image"
                        fade={i === currentSlide}
                        fluid={slide.sources}
                        alt={slide.imageDesktopAlt}
                        objectFit="cover"
                        placeholderStyle={{ opacity: 0 }}
                      />
                    )}
                  </div>
                )
              })}
            </ImageContainer>
            <RightArrow
              onClick={nextSlide}
              src={currentSlide === slides.length - 1 ? arrowGrey : arrowGreen}
              alt="Right arrow"
            />
          </TopRowContainer>
          <DotsContainer>
            {slides.map((slide, i: number) => {
              return (
                <Dot
                  key={i}
                  onClick={() => animateChangeSlide(i)}
                  isActive={currentSlide === i}
                />
              )
            })}
          </DotsContainer>
        </InnerContainer>
      </StandardSection>
    </Container>
  )
}

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const LeftArrow = styled.img`
  position: relative;
  z-index: 4;
  width: 40px;
  cursor: pointer;
  position: absolute;
  left: 5%;
  top: 46%;
  transform: rotate(180deg);
  @media (max-width: 767px) {
    top: 43%;
    width: 30px;
  }
`

const RightArrow = styled(LeftArrow)`
  transform: rotate(0deg);
  left: auto;
  right: 5%;
`

const TopRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Image = styled(Img)`
  opacity: 0;
  transition: all 1s ease-in, filter 0.5s ease-in;
  ${props =>
    props.fade
      ? "animation: slideFadeIn 1.5s ease-in forwards; opacity: 1;"
      : "animation: slideFadeOut 1.5s ease-in forwards; opacity: 0;"}
  transition: all 1.2s ease;
  object-fit: cover;
  height: 100%;
  @media (max-width: 767px) {
    height: 100%;
  }
`

const Heading = styled.p`
  font-size: 3rem;
  margin-bottom: 15px;
  text-align: center;
  font-family: Futura;
  transition: all 0.5s ease-in;
  opacity: 1;
  ${props =>
    props.blur &&
    `
    opacity: 0;
  `};
  @media (max-width: 767px) {
    font-size: 20px;
  }
`

const Body = styled.p`
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: 300;
  height: 52px;
  filter: blur(0px);
  transition: all 0.5s ease-in;
  opacity: 1;
  ${props =>
    props.blur &&
    `
    opacity: 0;
  `};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
`

const Dot = styled.div<DotProps>`
  height: 11px;
  width: 11px;
  border: 1px solid ${color.brand.blue};
  border-radius: 50%;
  transition: all 0.2s ease-in;
  margin-right: 7px;
  margin-top: 40px;
  cursor: pointer;
  ${props =>
    props.isActive &&
    `
    background-color: ${color.brand.blue};
    border: 1px solid ${color.brand.blue};
  `}
  @media (max-width: 767px) {
    width: 7px;
    height: 7px;
    right: 2px;
    margin-right: 5px;
  }
`

const DotsContainer = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  @media (max-width: 1023px) {
    margin-top: 50px;
  }
  @media (max-width: 767px) {
    margin-top: 119px;
  }
`

const TextContainer = styled.div`
  z-index: 3;
  position: relative;
  text-align: center;
  max-width: 60%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  & h5 {
    font-weight: 300;
    font-size: 5.5rem;
    margin-bottom: 4.7rem;
    font-family: headingFontLight;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const Container = styled.div`
  position: relative;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 22.2rem 0 21.2rem;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 179px 0 59px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.65);
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default ExperienceCarousel

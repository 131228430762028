import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import SmallHeading from "../../shared/SmallHeading/SmallHeading"
import VerticalPadding from "../../helper/VerticalPadding"
import { get } from "../../util/functions"
import StandardSection from "../../helper/StandardSection"
import FullWidthContainer from "../../helper/FullWidthContainer"
import Button from "../../shared/Button/Button"
import { headingFontLight } from "../../style/typography"

interface ICard {
  sectionHeading?: string
  body?: string
  marginRight?: boolean
  marginBottom?: boolean
  increasedWidth?: boolean
  image?: string
  imageMob?: string
  url: string
  link: string
  altDesktop?: string
  altMobile?: string
}
interface IGrid {
  centerColumn?: boolean
  marginBottom?: boolean
}
interface ITreatmentContainer {
  sectionHeading?: string
  sectionBody?: string
  data: Array<ICard>
}

const TreatmentContainer = ({ sectionHeading, data }: ITreatmentContainer) => {
  return (
    <MainContainer data-testid="treatmentContainerTest">
      <StandardSection>
        <VerticalPadding>
          <Inner>
            {sectionHeading && (
              <SmallHeadingTreatment>{sectionHeading}</SmallHeadingTreatment>
            )}

            <Grid
              centerColumn={data.length <= 3}
              marginBottom={data.length >= 4}
            >
              {data &&
                data.map((column, key) => {
                  return (
                    <Card
                      key={key}
                      marginBottom={data.length >= 4}
                      marginRight={(key + 1) % 4 !== 0}
                      increasedWidth={data.length <= 3}
                    >
                      <Heading>{column.sectionHeading}</Heading>
                      <Body>{column.body}</Body>
                      <ButtonContainer>
                        <Button href={column.url} text={column.link} />
                      </ButtonContainer>
                    </Card>
                  )
                })}
            </Grid>
          </Inner>
        </VerticalPadding>
      </StandardSection>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background: ${color.background.lightGrey};
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Grid = styled.div<IGrid>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${props => props.centerColumn && "center"};
  margin-top: 3.5rem;

  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 27px;
  }
`
const Card = styled.div<ICard>`
  width: 100%;
  max-width: ${props => (props.increasedWidth ? "32.6%" : "24.5%")};
  margin-top: ${props => (props.marginBottom ? "100px" : 0)};
  position: relative;
  margin-right: ${props => (props.marginRight ? "0.6%" : 0)};
  background: white;
  transition: all 0.6s ease;
  padding: 6rem 4.3rem 7rem;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0 0 28px 0 rgba(63, 63, 63, 0.1);
  }

  &:last-of-type {
    margin-right: 0;
  }
  &:nth-child(-n + 4) {
    margin-top: 0;
  }
  @media (max-width: 1280px) {
    padding: 5rem 3rem 6rem;
  }

  @media (max-width: 1023px) {
    max-width: 49%;
    margin-bottom: 0.6%;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    max-width: 100%;
    margin-top: 0;
    padding: 38px 33px 50px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`

const Heading = styled.h3`
  color: ${color.text.primary};
  text-align: left;
  font-family: ${headingFontLight};
  font-weight: 300;
  margin-bottom: 17px;
`

const Body = styled.p`
  font-weight: 300;
  margin-bottom: 17px;
`
const ButtonContainer = styled.div`
  width: 100%;
  max-width: 164px;
  margin-top: auto;
`

const SmallHeadingTreatment = styled.p`
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 1px;
  text-transform: uppercase;
  // font-weight: 400;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`
export default TreatmentContainer

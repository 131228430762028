import React from "react"
import styled from "styled-components"
import color from "../../../style/color"
import StepNumber from "./StepNumber"
import Pill from "../../../shared/Pill/Pill"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

interface StepProp {
  heading?: string
  text?: string
  period?: string
  stepNumber?: number
  week?: string
  mobImage?: string
  mobAlt?: string
  desktopImage: string
  desktopAlt: string
  middleTime: string
}
const Step = ({
  heading,
  text,
  period,
  stepNumber,
  week,
  mobImage,
  mobAlt,
  desktopImage,
  desktopAlt,
  middleTime,
}: StepProp) => {
  return (
    <Container data-testid="step">
      <DesktopImage>
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" offset={100}>
          <img src={desktopImage} alt={desktopAlt} />
        </ScrollAnimation>
      </DesktopImage>
      {mobImage && <MobImage src={mobImage} alt={mobAlt} />}
      <StepDetails>
        <PillContainer>
          <SlidePill>{period}</SlidePill>
        </PillContainer>
        {stepNumber && <TopDetails>{week && <Week>{week}</Week>}</TopDetails>}

        {heading && <StepHeading>{heading}</StepHeading>}
        {text && <Text>{text}</Text>}
      </StepDetails>
      {middleTime && (
        <MiddlePillContainer>
          <SlidePill>{middleTime}</SlidePill>
        </MiddlePillContainer>
      )}
    </Container>
  )
}

export default Step

const MiddlePillContainer = styled.div`
  display: flex;
  align-self: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: 1199px) {
    bottom: 11%;
  }
`

const DesktopImage = styled.div`
  position: absolute;
  left: 0;
  top: 32%;
  margin-left: -550px;

  & img {
    width: 440px;
  }
  @media (max-width: 1199px) {
    display: none;
  }
`

const MobImage = styled.img`
  display: none;
  @media (max-width: 1199px) {
    display: block;
    width: 100%;
    max-width: 50rem;
    background: white;
  }
  @media (max-width: 767px) {
    max-width: 40rem;
  }
`

const SlidePill = styled(Pill)`
  padding: 0 20px;
`

const PillContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 25px;
`

const Container = styled.div`
  height: 50vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  z-index: 7;
  position: relative;
  @media (max-width: 1199px) {
    height: auto;
    padding: 60px 0 80px;
    padding: 140px 0 160px;

    padding-top: 0;
  }
`

const StepDetails = styled.div`
  padding: 20px 0;
  width: 100%;
  background: #f1fdfa;
`
const TopDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0 20px;
`

const Week = styled.div`
  background: rgba(8, 37, 72, 0.6);
  color: #fff;
  border-radius: 50px;
  padding: 0 10px;
  font-weight: 300;
  font-style: italic;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

export const StepHeading = styled.h4`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.4rem;
  color: ${color.brand.blue};
`

export const Text = styled.p`
  margin: 15px 0 20px;
  color: ${color.brand.secondary};
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 1199px) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
  }
`

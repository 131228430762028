import React from "react"
import styled from "styled-components"
import { headingFontLight } from "../../style/typography"
import color from "../../style/color"

interface ISectionHeading {
  heading: string
  leftAlign?: boolean
}
const SectionHeading = ({ heading, leftAlign }: ISectionHeading) => {
  return (
    <Container data-testid="section-heading-test" leftAlign={leftAlign}>
      <Heading>{heading}</Heading>
    </Container>
  )
}

export default SectionHeading

const Container = styled.div<ISectionHeading>`
  text-align: ${props => (props.leftAlign ? "left" : "center")};
  width: 100%;
  max-width: 835px;
  margin-left: ${props => (props.leftAlign ? "0" : "auto")};
  margin-right: auto;
`

const Heading = styled.h2`
  font-weight: 300;
  font-family: ${headingFontLight};
  @media (max-width: 767px) {
  }
`
